import React from 'react';
import i18n from '../../../../../i18n/Message';

const TargetGroupPolicySelect = (props) => {
    return (
        <select defaultValue={props.selectedPolicy || ""} name="select" id="rule-type" className="form-control" style={{width:'20%', margin:'2px'}} onChange={props.onChange}>
            {["ALL_OF", "ANY_OF", "ONE_OF", "NONE_OF"].map(type => <option key={type} value={type}>{i18n(`experiments.target-group.rule.${type}`)}</option>)}
        </select>
    )


}

export default TargetGroupPolicySelect