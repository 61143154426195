import React, { Component } from 'react';
import BasePage from '../base/BasePage';
import i18n from '../../../i18n/Message';
import httpClient from '../../../http/HttpClient';
import env from '../../../util/Env'
import RoundButton from '../../ui/RoundButton';
import { Redirect } from 'react-router-dom';

class AuditLogsTable extends Component {

    groupByMonth() {
        const groupedLogs = {}
        this.props.auditLogs.forEach(audit => {
            const key = audit.timestamp.substring(0, 7)
            const items = groupedLogs[key] || []
            items.push(audit)
            groupedLogs[key] = items
        });
        return groupedLogs
    }

    render() {

        const getItems = (items) => {
            const list = []
            const htmlItems = []

            for (var item in items) {
                
                items[item].forEach(itemDetail => {
                    htmlItems.push(<li>{itemDetail}</li>)
                })

                list.push(
                    <div>
                        <p>{item}</p>
                        <ul style={{paddingLeft:"20px", fontSize:"13px", fontWeight: "normal"}}>
                        { htmlItems }
                        </ul>
                    </div>
                )
            }

            return list
            
        }

        const groupedAudits = this.groupByMonth()

        const monthItems = []
        for (let key in groupedAudits) {
            const year = key.substring(0, 4)
            const month = key.substring(5, 8)
            const audits = groupedAudits[key]

            const AuditLogs = audits.map(audit => {
                
                const items = !audit.items ? "" : getItems(audit.items)

                return ( 
                    <tr>
                        <th scope="row">
                            <h4 style={{marginBottom:"5px"}}><span className="badge badge-info">{ audit.action }</span></h4>
                            <span><strong>{audit.headline}</strong></span>
                            <p>{audit.details}</p>
                            { items }
                        </th>
                    </tr>
                )
            })

            const monthItem = {
                "key": `${year}${month}`,
                "item": (
                    <div className="card-body">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4 className="card-title">{ i18n(`general.months.${month}`) + ` ${year}`}</h4>
                            </div>
                        </div>
                        <table className="table">
                            <tbody>
                                { AuditLogs }
                            </tbody>
                        </table>
                    </div>
                )
            }

            monthItems.push(monthItem)
        }

        const More = (props) => props.hasMore ? <RoundButton onClick={props.onMore} className="btn btn-primary btn-sm">{ i18n("general.more") } </RoundButton> : ""

        return (
            [
                <div key="table" className="row">
                    <div className="col-lg-12 table-responsive">
                        <div className="card">                        
                            { monthItems.sort((a, b) => -1 * (parseInt(a.key) - parseInt(b.key))).map(e => e.item) }
                        </div>
                    </div>
                </div>,
                <More hasMore={this.props.hasMore} onMore={this.props.onMore}/>
            ]
        )
    }

}


class AuditLogs extends Component {

    state = { httpStatus: 0, auditLogs: [], page: 1, hasMore: false }

    componentDidMount() {
        this.loadPage(this.state.page)
    }

    render() {

        if (this.state.httpStatus === 404) return <Redirect to="/not-found"/>

        return ( 
            <BasePage 
                appId={this.props.appId}
                language={this.props.language}
                logout={this.props.logout}
                component={ <AuditLogsTable auditLogs={this.state.auditLogs} hasMore={this.state.hasMore} onMore={() => this.loadPage(this.state.page+1)}/> }
                title={ i18n("general.audit") }
            />
        )
    }

    loadPage(page) {
        let self = this
        const url = this.props.appId ? `${env["api.url"]}/api/apps/${this.props.appId}/audit-logs` : `${env["api.url"]}/api/audit-logs`
        httpClient.get(`${url}?page=${page}&size=20`)
        .then(function (response) {
            self.setState({ 
                auditLogs : self.state.auditLogs.concat(response.data.items),
                page: page,
                hasMore: response.data.totalPages > page
            })
        }).catch(function(error) {
            if (error.response)  {
                self.setState({ httpStatus: error.response.status })
            }
        })
    }
}



export default AuditLogs