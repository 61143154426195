import Applications from "../Applications";
import httpClient from "../../http/HttpClient";
import env from "../../util/Env";
import { Base64 } from 'js-base64'

const getUserData = Symbol('getUserData');

class UserData {

    login(userData) {
        localStorage.setItem("_lang", userData.lang)
        console.log("Base64: ", Base64)
        localStorage.setItem("_ud", Base64.encode(JSON.stringify(userData)))
    }

    setLanguage(lang, onSuccess) {
        httpClient.post({url:`${env["api.url"]}/api/users/me/lang/${lang}`})
        .then(function (response) {
            localStorage.setItem("_lang", lang)
            onSuccess(lang)
        })
    }

    setGeneralLanguage(lang, onSuccess) {
        localStorage.setItem("_lang", lang)
        onSuccess(lang)
    }

    getLanguage() {
        const lang = localStorage.getItem("_lang")
        return lang ? lang : "en"
    }    

    [getUserData] (field) {
        const content = localStorage.getItem("_ud")
        if (content) {
            let userData = JSON.parse(Base64.decode(content))
            if (field) {
                return userData[field]
            }
            return userData
        }
    }

    token() {
        return `Bearer ${this[getUserData]("access_token")}`
    }

    isLoggedIn() {
        const userData = this[getUserData]()
        return userData && userData.expiration_date > new Date().getTime()
    }

    logout() {
        Applications.clearApps()
        localStorage.removeItem("_ud")
        localStorage.removeItem("_lang")
    }

    name() {
        return this[getUserData]("name")
    }

    company() {
        return this[getUserData]("company_name")
    }    

    isAdmin() {
        return this[getUserData]("is_admin")
    }

    isOnPremise() {
        return this[getUserData]("plan") === "ON_PREMISE"
    }

    isTheSame(id) {
        return this[getUserData]("user_name") === id
    }
}

export default new UserData()