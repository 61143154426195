import propertyRuleValidator from '../../target-groups/ConditionsValidator'

const RuleValidator = (rules, properties) => {

    return rules.map(rule => {

        if (rule.versionRule) {
            const validationResult = propertyRuleValidator([rule.versionRule], properties)

            if (validationResult.result !== "VALID") {
                return {ruleIndex: rule.order, result: "VERSION_" + validationResult.result}
            }
        }
        
        switch(rule.type) {
            case "PROPERTY":
                return validatePropertyRule(rule.order, rule.propertyRule, rule.valueSpec, properties)
            case "TARGET_GROUP":
                return validateTargetGroupRule(rule.order, rule.targetGroupRule, rule.valueSpec)
            case "TOGGLE_DEPENDENCY":
                return validateToggleDependencyRule(rule.order, rule.toggleDependencyRule, rule.valueSpec)                
            default:
                return validateValueSpec(rule.order, rule.valueSpec)
        }
    })

}

const validateToggleDependencyRule = (order, toggleDependencyRule, valueSpec) => {
    if (!toggleDependencyRule.toggleId) {
        return {ruleIndex: order, result: "NO_TOGGLE_DEPENDENCY_SELECTED"}
    }

    if (!toggleDependencyRule.value) {
        return {ruleIndex: order, result: "NO_TOGGLE_DEPENDENCY_VALUE_SELECTED"}
    }    
    return validateValueSpec(order, valueSpec)
}

const validateTargetGroupRule = (order, targetGroupRule, valueSpec) => {
    if (!targetGroupRule.targetGroupsIds || targetGroupRule.targetGroupsIds.length === 0) {
        return {ruleIndex: order, result: "NO_TARGET_GROUP_SPECIFIED"}
    }
    return validateValueSpec(order, valueSpec)
}


const validatePropertyRule = (order, propertyRule, valueSpec, properties) => {
    const validationResult = propertyRuleValidator([propertyRule], properties)

    if (validationResult.result !== "VALID") {
        return {ruleIndex: order, result: validationResult.result}
    }

    return validateValueSpec(order, valueSpec)
}

const validateValueSpec = (order, spec) => {

    if (spec.type === "EXACT_VALUE" && !spec.exactValue) {
        return {ruleIndex: order, result: "NO_VALUE_SPECIFIED"}
    } else if (spec.type === "SPLIT" ) {
        if (!spec.split) {
            return {ruleIndex: order, result: "NO_SPLIT_RULES_SPECIFIED"}
        } else {
            let totalPercentage = 0
            for (let val in spec.split) {
                totalPercentage += spec.split[val]
            }

            if (totalPercentage !== 100) {
                return {ruleIndex: order, result: "WRONG_SPLIT_PERCENTAGES"}
            }
        }
    }

    return {ruleIndex: order, result: "VALID"}

}

export default RuleValidator