import React, { Component } from 'react'
import PropertiesSelect from '../../../../general/PropertiesSelect';
import i18n from '../../../../../i18n/Message';
import ExpressionsSelect from '../../../../general/ExpressionsSelect'

class VersionRule extends Component {

    render() {

        return [
            <PropertiesSelect 
                key={`${this.props.ruleIndex}-version-ps`}
                uniqueId={this.props.ruleIndex + "-version-prop"} 
                selectedProperty={this.props.rule.propertyId} 
                onChange={this.props.onPropertySelect} 
                properties={this.props.versionProperties}
                style={{width:'30%', margin:'2px'}}
                defaultOption={i18n("general.select")}/>,
            <ExpressionsSelect 
                key={`${this.props.ruleIndex}-version-es`}
                uniqueId={this.props.ruleIndex + "-version-exp"}  
                selectedValue={this.props.rule.expression} 
                propertyType={this.props.rule.propertyType} 
                onChange={this.props.onExpressionSelect}
                style={{width:'15%', margin:'2px'}}
                defaultOption={i18n("general.select")}/>,               
            <input key={this.props.ruleIndex} type="text" value={this.props.rule.term} className="form-control" style={{width:'30%', margin:'2px'}} onChange={this.props.onChangeTerm}/>
        ]

    }
}

export default VersionRule