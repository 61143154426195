import React, { Component } from 'react'
import i18n from '../../../i18n/Message'
import httpClient from '../../../http/HttpClient';
import env from '../../../util/Env';

const EnvironmentsSelect = (props) => {

    const options = props.environments.filter(env => env.id != props.currentEnvId).map(env => {
        return <option key={env.id} value={env.id}>{env.name}</option>
    })

    return (
        <select className="form-control" onChange={props.onSelectEnvironment}>
            <option value="">{ i18n("experiments.clone.select.env") }</option>
            { options }
        </select>
    )
} 

class Clone extends Component {

    state = { environments: [] }

    componentDidMount() {
        if (this.props.appId) {
            let self = this
            httpClient.get(`${env["api.url"]}/api/apps/${this.props.appId}`)
            .then(function (response) {
                self.setState({ environments: response.data.environments })
            })
        }
    }

    render() {
        return (
            <div className="card">
                <div className="card-header"><strong>{ i18n("experiments.clone.header") }</strong></div>
                <div className="card-body card-block">
                    <div className="form-group">
                        <label htmlFor="toggles" className=" form-control-label">{ i18n("experiments.clone.label") }</label>
                        <EnvironmentsSelect 
                            {...this.props} 
                            environments={this.state.environments} 
                            onSelectEnvironment={this.clone.bind(this)}/>
                    </div>                    
                </div>
            </div>
        )
        
    }

    clone(e) {
        const destinationEnvironment = e.target.value
        if (destinationEnvironment) {
            this.props.onClone(destinationEnvironment)
        }
    }

}

export default Clone