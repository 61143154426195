import React, { Component } from 'react'
import LeftPanel from './LeftPanel'
import RightPanel from './RightPanel';
import Applications from '../../Applications';

class BasePage extends Component {

    state = {
        title: ""
    }

    getEnvironment(app) {
        if (this.props.envId) {
            const env = app.environments.find(env => env.id === this.props.envId)
            return env ? " - " + env.name : ""
        }
        return ""
    }

    componentDidMount() {
        const self = this
        if (this.props.appId && this.props.appId !== "new") {
            Applications.getApp(this.props.appId, (app) => {
                const suffix = " | " + app.name + self.getEnvironment(app)
                const title = (self.props.title ? self.props.title : "Quick Toggle") + suffix
                self.setState({ title: title })
            }, () => self.setState({ title: self.props.title }))
        } else {
            this.setState({ title: this.props.title })
        }
    }

    render() {
        document.title = this.state.title
        return ( [
            <LeftPanel key="0" logout={this.props.logout} />,
            <RightPanel key="1" 
                language={this.props.language} 
                component={this.props.component} 
                title={ this.state.title } 
                />
        ])

    }

}

export default BasePage