import React, { Component } from 'react';
import BasePage from '../base/BasePage';
import i18n from '../../../i18n/Message';
import httpClient from '../../../http/HttpClient';
import env from '../../../util/Env'
import $ from 'jquery'
import { Redirect } from 'react-router-dom';
import Error from '../base/Error'
import FormActions from '../../general/FormActions';

class Values extends Component {

    render() {

        /* Ugly as fuck workaround to capture 
           the "close" event and handle it as 
           the removal of an item from the environments list
        */
       let self = this
       $(document).on(
           "close.bs.alert",
           ".alert.alert-info.alert-dismissible.show",
           function(e) {
               e.preventDefault()
               e.stopImmediatePropagation()
               self.props.remove(e.target.attributes["data-value"].value)
           }
       )        

        const values = this.props.values.map((value) => {

            const defaultValueAction = self.props.defaultValue === value ? 
                <strong>{ i18n("toggles.default.value") }</strong> : 
                <a onClick={(e) => self.setDefaultValue.bind(self)(e, value)} href="#">{ i18n("toggles.default.set.value") }</a> 

            return (
                <div key={value} className={`alert alert-info alert-dismissible show`} data-value={value}>
                    <i className="fa fa-check"></i>{ ` ${value} - ` }{defaultValueAction}
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>
                </div>
            )
        })

        return (
            values && values.length > 0 ?
            <div className="row" style={{margin:'15px'}}>
                <div className="w-100">
                    { values }
                </div>
            </div> : ""
        )
    }

    setDefaultValue(e, value) {
        e.preventDefault()
        this.props.setDefaultValue(value)
    }
}

class ToggleForm  extends Component {

    state = { 
        toggle: { name: "", description: "", values: ["enabled", "disabled"], defaultValue: "disabled" }, 
        status: "", 
        values: []
    };

    render() {

        if (this.state.status === "DONE") return <Redirect to={`/applications/${this.props.appId}/toggles`} />

        return [
            <Error 
                status={this.state.status} 
                conf={ 
                    {
                        "EMPTY_NAME": i18n("toggles.error.empty.name"),
                        "INVALID_NAME": i18n("toggles.error.invalid.name"),
                        "AT_LEAST_TWO_VALUES": i18n("toggles.error.min.values"),
                        "DUPLICATE_NAME": i18n("toggles.error.name.already.in.use"),
                        "DEFAULT_VALUE_REQUIRED": i18n("toggles.error.default.value.required"),
                        "MAX_FEATURE_TOGGLES_LIMIT_REACHED": i18n("toggles.error.max.limit.reached")
                    } 
                }
                />,
            <div key="23kdf" className="row">
                <div className="col-lg-6">
                    <div className="card">
                        <div className="card-header"><strong>{ i18n("toggles.header") }</strong></div>
                        <div className="card-body card-block">
                            <div className="form-group">
                                <label htmlFor="name" className=" form-control-label">{ i18n("toggles.name.label") }</label>
                                <input value={this.state.toggle.name} onChange={this.updateName.bind(this)} type="text" id="name" placeholder={ i18n("toggles.name.placeholder") } className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="description" className="form-control-label">{ i18n("toggles.description.label") }</label>
                                <input maxLength="100" value={this.state.toggle.description} onChange={this.updateDescription.bind(this)} type="text" id="description" placeholder={ i18n("toggles.description.placeholder") } className="form-control" />
                            </div>                            
                        </div>

                    </div>
                </div>

                <div className="col-lg-6">
                    <div className="card">
                        <div className="card-body card-block">
                            { i18n("toggles.edit.description") }
                        </div>
                    </div>
                </div>
            </div>,
            <div key="values" className="row">
                <div className="col-lg-6">
                    <div className="card">
                        <div className="card-header"><strong>{ i18n("toggles.values.header") }</strong></div>
                        <div className="card-body card-block">
                            <div className="form-group">
                                <label htmlFor="toggle-value" className=" form-control-label">{ i18n("toggles.values.label") }</label>
                                <div className="input-group">
                                <input onChange={()=>{}} id="toggle-value" onKeyPress={this.updateValues.bind(this)} type="text" placeholder={ i18n("toggles.values.placeholder") } className="form-control" />
                                    <div className="input-group-btn">
                                        <button className="btn btn-primary" onClick={this.addValue.bind(this)} style={{borderRadius:"0 25px 25px 0"}}>{ i18n("general.add") }</button>
                                    </div>
                                </div>
                                <Values values={this.state.toggle.values} defaultValue={this.state.toggle.defaultValue} setDefaultValue={this.setDefaultValue.bind(this)} remove={(value) => this.removeValue.bind(this)(value)} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6">
                    <div className="card">
                        <div className="card-body card-block">
                            <p>{ i18n("toggles.edit.values.description.first") }</p>
                            <p>{ i18n("toggles.edit.values.description.second") }</p>
                        </div>
                    </div>
                </div>
            </div>,
            <FormActions onSave={this.save.bind(this)} onReset={this.reset.bind(this)}/>
        ]
    }

    setDefaultValue(value) {
        if (this.state.toggle.values.includes(value)) {
            this.setState({ status: "", toggle: {...this.state.toggle, defaultValue: value} })    
        }
    }

    reset() {
        this.setState({ status: "", toggle: { name: "", description: "", values: ["enabled", "disabled"], defaultValue: "disabled" } })
    }

    updateName(e) {
        this.setState({ "toggle": {...this.state.toggle, "name": e.target.value.trim() } })
        if (this.state.toggle.name && this.state.toggle.name.length > 0) {
            this.setState({ "status": ""})
        }
    }

    updateDescription(e) {
        this.setState({ "status": "", "toggle": {...this.state.toggle, "description": e.target.value } })
    }    

    addValue() {
        const input = $("#toggle-value")[0]
        this.updateValues({
            target: input,
            key: "Enter" // Simulating enter key press
        })
    }

    updateValues(e) {
        const rawValue = e.target.value
        if (rawValue) {
            if (e.key === 'Enter') {
                var value = rawValue.trim()
            }
            if (value) {
                const currentValues = this.state.toggle.values
                const found = currentValues.find(val => { return val === value })
                if (!found) {
                    currentValues.push(value)
                    this.setState({status: "", toggle: {...this.state.toggle, "values": currentValues } })
                }
                e.target.value = ""
            }
        } 
    }
    
    removeValue(value) {
        const values = this.state.toggle.values
        const index = values.findIndex(val => { return val === value })
        const defaultValue = this.state.toggle.defaultValue === value ? "" : this.state.toggle.defaultValue
        if (index > -1) {
            values.splice(index, 1)
            this.setState({ "toggle": {...this.state.toggle, "values": values, "defaultValue": defaultValue } })
        }
    }

    save() {
        let self = this
        if (this.state.toggle.name) {
            if (/^([0-9a-zA-Z\.\-_]*)$/.test(this.state.toggle.name)) {
                if (this.state.toggle.values.length >= 2) { //At least, two different values
                    if (this.state.toggle.defaultValue) {
                        httpClient.post({
                            url: `${env["api.url"]}/api/apps/${this.props.appId}/toggles`,
                            data: {...this.state.toggle, appId: this.props.appId} 
                        }).then(function(response) {
                            self.setState({ "status": "DONE" })
                        }).catch(function(error) {
                            if (error.response && error.response.data && error.response.data.error_code)  {
                                self.setState({ status : error.response.data.error_code })
                            } else {
                            self.setState({ status : "ERROR" })
                            }
                        })
                    } else {
                        this.setState({ "status": "DEFAULT_VALUE_REQUIRED"})    
                    }
                } else {
                    this.setState({ "status": "AT_LEAST_TWO_VALUES"})
                }
            } else {
                this.setState({ "status": "INVALID_NAME"})
            }
        } else {
            this.setState({ "status": "EMPTY_NAME"})
        }
    }


}

class EditToggle extends Component {

    render() {
        return ( 
            <BasePage appId={this.props.appId} language={this.props.language} logout={this.props.logout} component={ <ToggleForm appId={this.props.appId}/> } title={ i18n("toggles.title") + " > "+ i18n("general.edit") }/>
        )
    }
}



export default EditToggle