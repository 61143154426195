import React, { Component } from 'react';
import i18n from '../../../i18n/Message'
import env from '../../../util/Env'
import user from '../../user/UserData'
import { Redirect, Link } from 'react-router-dom';
import httpClient from '../../../http/HttpClient';
import quicktoggleblack from '../../../assets/images/logomarca-black.svg'
import LanguageSwitch from '../../general/LanguageSwitch';

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

class Signup extends Component {

    state = { 
        signup: {
            userName: "",
            userEmail: "",
            companyName: "",
            acceptTermsAndConditions: false
        },
        status: ""
    };

    render() {

        if (user.isLoggedIn()) return <Redirect to="/dashboard" />

        switch(this.state.status) {
            case "ERROR":
                var Error = <span style={{"color": "#f00"}}>{ i18n("general.error.message") }</span> 
                break
            case "INCOMPLETE_FORM":
                var Error = <span style={{"color": "#f00"}}>{ i18n("account-creation.error.missing.fields") }</span> 
                break
            case "ACCEPT_TC":
                var Error = <span style={{"color": "#f00"}}>{ i18n("account-creation.error.accept.tc") }</span> 
                break
            case "INVALID_EMAIL":
                var Error = <span style={{"color": "#f00"}}>{ i18n("users.error.invalid.email") }</span> 
                break
            case "EMAIL_ALREADY_IN_USE": 
                var Error = <span style={{"color": "#f00"}}>{ i18n("users.error.email.already.in.use") }</span> 
                break
        }

        if (this.state.status === "ACCOUNT_CREATED") {
            return (
                <div className="sufee-login d-flex align-content-center flex-wrap h-100">
                    <div className="container">
                        <div className="login-content">
                            <div className="login-logo">
                                <a href="https://quicktoggle.com">
                                    <img style={{"width":"110px"}} className="align-content" src={quicktoggleblack} alt=""/>
                                </a>
                            </div>
                            <div className="login-form">
                                <h4>
                                { i18n("general.thank.you") }, {this.state.signup.userName}.<br/> { i18n("account-creation.success.explanation") }
                                </h4>
                                <Link className="btn btn-primary btn-flat m-b-30 m-t-30" to="/login" >{ i18n("forgot.password.back.to.login") }</Link>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        document.title = `Quick Toggle - ${i18n("account-creation.create")}`
        return (
            <div className="sufee-login d-flex align-content-center flex-wrap h-100">
                <div className="container">
                    <div className="login-content">
                        <div className="login-logo">
                            <a href="https://quicktoggle.com">
                                <img style={{"width":"110px"}} className="align-content" src={quicktoggleblack} alt=""/> 
                            </a>
                        </div>
                        <div className="login-form">
                            { Error }
                            <div className="form-group">
                                <label>{ i18n("account-creation.name") }</label>
                                <input value={this.state.signup.userName} onChange={(e) => this.changeValue("userName", e.target.value)} type="text" className="form-control" placeholder={ i18n("account-creation.name") }/>
                            </div>
                            <div className="form-group">
                                <label>{ i18n("account-creation.email") }</label>
                                <input value={this.state.signup.userEmail} onChange={(e) => this.changeValue("userEmail", e.target.value)} type="text" className="form-control" placeholder={ i18n("account-creation.email") }/>
                            </div>
                            <div className="form-group">
                                <label>{ i18n("account-creation.company") }</label>
                                <input value={this.state.signup.companyName} onChange={(e) => this.changeValue("companyName", e.target.value)} type="text" className="form-control" placeholder={ i18n("account-creation.company.name") }/>
                            </div>                                
                            <div className="checkbox">
                                <label>
                                <input checked={this.state.signup.acceptTermsAndConditions} onChange={(e) => this.changeValue("acceptTermsAndConditions", e.target.checked)} type="checkbox"/> { i18n("account-creation.i.accept") } <a href="https://quicktoggle.com/terms-and-conditions/" style={{"color": "#f00"}}>{ i18n("account-creation.terms.and.conditions") }</a>
                                </label>
                            </div>
                            <button onClick={this.send.bind(this)} className="btn btn-primary btn-flat m-b-30 m-t-30" style={{borderRadius:"25px"}}>{ i18n("account-creation.create") }</button>
                            <div className="register-link m-t-15 text-center">
                                <p>{ i18n("account-creation.already.have.account") }&nbsp;<Link className=".link" to="/login" >{ i18n("general.login") }</Link></p>
                            </div>
                            <LanguageSwitch setLang={this.props.language}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    changeValue(field, value) {
        const signup = {...this.state.signup}
        signup[field] = value
        this.setState({ "signup": signup, status: "" })
    }

    send(){
        if (this.isValid()) {
            const self = this
            httpClient.post({
                url: `${env["api.url"]}/api/accounts`,
                data: this.state.signup
            }, false).then(function(response) {
                self.setState({ "status": "ACCOUNT_CREATED" })
            }).catch(function(error) {
                if (error.response && error.response.data && error.response.data.error_code)  {
                    self.setState({ status : error.response.data.error_code, errorMetadata: error.response.data.meta_data })
                } else {
                    self.setState({ status : "ERROR" })
                }
            })
        }
    }

    isValid() {
        const signup = this.state.signup
        if (!signup.userName.trim() || !signup.userEmail.trim() || !signup.companyName.trim()) {
            this.setState( { status: "INCOMPLETE_FORM" } )
            return false
        } else if (!EMAIL_REGEX.test(signup.userEmail.trim())) {
            this.setState( { status: "INVALID_EMAIL" } )
            return false
        } else if (!signup.acceptTermsAndConditions) {
            this.setState( { status: "ACCEPT_TC" } )
            return false
        } 
        return true
    }
}

export default Signup;