import React, { Component } from 'react';
import i18n from '../../../i18n/Message'
import env from '../../../util/Env'
import { Link } from 'react-router-dom';
import axios from 'axios';
import user from '../../user/UserData'
import { Redirect } from 'react-router-dom';
import quicktoggle from '../../../assets/images/logomarca-white.svg'
import LanguageSwitch from '../../general/LanguageSwitch';

class Login extends Component {

    state = { error: "NONE" };

    render() {

        if (user.isLoggedIn()) return <Redirect to="/dashboard" />
        // If user not logged in, the logout() method will remove all the data from the local storage
        user.logout()

        let error;
        switch(this.state.error) {
            case "NONE": 
                error = ""
                break
            case "WRONG_CREDENTIALS":
                error = <p className="text-danger">{ i18n("login.wrong.credentials") }</p>
                break
            case "ERROR":
                error = <p className="text-danger">{ i18n("general.error.message") }</p>
                break                
        }

        document.title = `Quick Toggle - ${i18n("general.login")}`

        return (

            <div className="sufee-login d-flex align-content-center flex-wrap h-100" style={{backgroundColor:"#31257E"}}>
                <div className="container">
                    <div className="login-content">
                        <div className="login-logo">
                            <a href="https://quicktoggle.com">
                                <img style={{"width":"110px"}} className="align-content" src={quicktoggle} alt=""/>
                            </a>
                        </div>
                        <div className="login-form">
                            <p>{ i18n("login.message") }</p>
                            <form onSubmit={ this.login.bind(this) }>
                                <div className="form-group">
                                    <input className="form-control" type="text" id="inputEmail" placeholder={ i18n("login.email") } name="email" autoFocus/>
                                </div>
                                <div className="form-group">
                                    <input className="form-control" type="password" id="inputPassword" placeholder={ i18n("login.password") } name="password" />
                                </div>
                                {error}
                                <div className="checkbox">
                                    <label>
                                        <Link to="/forgot-password" >{ i18n("login.forgot.password") }</Link>
                                    </label>
                                </div>
                                <button type="submit" className="btn btn-primary btn-flat m-b-30 m-t-30" style={{borderRadius:"25px"}}>{ i18n("general.login") }</button>
                                <div className="register-link m-t-15 text-center">
                                    <p>{ i18n("account-creation.dont.have.account") } <Link to="/signup" >{ i18n("account-creation.create") }</Link></p>
                                </div>
                            </form>
                            <LanguageSwitch setLang={this.props.language}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    login(e) {
        const self = this
        e.preventDefault()
        let form = new FormData()
        form.append("grant_type", "password")
        form.append("username", e.target.elements.email.value)
        form.append("password", e.target.elements.password.value)
        axios.post(
            `${env["auth.url"]}/oauth/token`, 
            form, 
            { "headers": { "Authorization" : "Basic ZmVhdHVyZS10b2dnbGUtYmU6Y3VydXJ1" } }
        ).then(function (response) {
            try {
                // handle success
                self.props.login({...response.data, expiration_date: Date.now() + response.data.expires_in * 1000})
                self.setState({ "error" : "NONE" })
            } catch(error) {
                console.error(error);
                // expected output: ReferenceError: nonExistentFunction is not defined
                // Note - error messages will vary depending on browser
                throw error
              }
          })
          .catch(function(error) {
              if (error.response && error.response.status == 400)  {
                  self.setState({ "error" : "WRONG_CREDENTIALS" })
              } else {
                self.setState({ "error" : "ERROR" })
              }
          })
    }
}

export default Login;