import React, { Component } from 'react'
import user from '../user/UserData'

class OnlyOnPremAdmin extends Component {
    render() {
        if (user.isAdmin() && user.isOnPremise()) {
            return this.props.children
        } else return ""
    }
}

export default OnlyOnPremAdmin