import React, { Component } from 'react';
import Login from './components/pages/login/Login';
import ForgotPassword from './components/pages/login/ForgotPassword';
import Dashboard from './components/pages/dashboard/Dashboard';
import EditApplication from './components/pages/applications/EditApplication';
import ApplicationConfig from './components/pages/applications/ApplicationConfig';
import ResetEnvironmentKey from './components/pages/applications/ResetEnvironmentKey';
import { LangProvider } from './contexts/LangContext';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import user from './components/user/UserData'
import httpClient from './http/HttpClient'
import Properties from './components/pages/properties/Properties'
import EditProperty from './components/pages/properties/EditProperty';
import TargetGroups from './components/pages/target-groups/TargetGroups'
import EditTargetGroup from './components/pages/target-groups/EditTargetGroup';
import Toggles from './components/pages/toggles/Toggles';
import EditToggle from './components/pages/toggles/EditToggle';
import Experiments from './components/pages/experiments/Experiments';
import EditExperiment from './components/pages/experiments/EditExperiment';
import Forbidden from './i18n/Forbidden';
import AuditLogs from './components/pages/auditogs/AuditLogs';
import Users from './components/pages/users/Users';
import EditUser from './components/pages/users/EditUser';
import Password from './components/pages/password/Password';
import ChangePassword from './components/pages/users/ChangePassword';
import NotFound from './components/pages/NotFound';
import Signup from './components/pages/login/Signup';
import AccountKeys from './components/pages/account/AccountKeys';
import NewAccountKey from './components/pages/account/NewAccountKey';

const FORBIDDEN = "FORBIDDEN"

const RestrictRoute = ({ component: Component, ...rest }) => {
  return <Route {...rest} render={ props => {
        return user.isLoggedIn() ? <Component {...props} /> : <Redirect to="/login" />
      }
    } />
}

class App extends Component {

  constructor(props) {
    super(props);
    this.state = { isLoggedIn: false, lang: user.getLanguage(), status: "" };

    // Binding the httpClient to the global app (ugly as fuck)
    httpClient.setApp(this)
  }

  changeLang(lang) {
    user.setLanguage(lang, (lang) => this.setState({lang: lang}))
  }

  changeGeneralLang(lang) {
    user.setGeneralLanguage(lang, (lang) => this.setState({lang: lang}))
  }
  
  login(data) {
    user.login(data)
    this.setState({ isLoggedIn: true, lang: data.lang})
  }

  logout() {
    user.logout()
    this.setState({ isLoggedIn: false})
  }

  forbiden() {
    this.setState({ status: FORBIDDEN })
  }

  render() {

    if (this.state.status === FORBIDDEN) {
      this.setState({status : ""})
      return (
        <BrowserRouter>
          <LangProvider value={this.state.lang}>
            <Redirect to="/forbidden" />
          </LangProvider>
        </BrowserRouter>
      ) 
    }

    return (
      <BrowserRouter>
        <LangProvider value={this.state.lang}>  
          <Switch>
            <Route path="/password/:id" component={ (props) => <Password requestId={props.match.params.id} language={this.changeGeneralLang.bind(this)} /> } exact />
            <Route path="/signup" component={ () => <Signup login={this.login.bind(this)} language={this.changeGeneralLang.bind(this)} /> } exact />
            <Route path="/login" component={ () => <Login login={this.login.bind(this)} language={this.changeGeneralLang.bind(this)} /> } exact />
            <Route path="/forgot-password" component={ () => <ForgotPassword language={this.changeGeneralLang.bind(this)} /> } exact />
            <RestrictRoute path="/" component={ () => <Redirect to="/dashboard" /> } exact />
            <RestrictRoute path="/forbidden" component={ () => <Forbidden language={this.changeLang.bind(this)} logout={this.logout.bind(this)}/> } exact />
            <RestrictRoute path="/dashboard" component={ () => <Dashboard language={this.changeLang.bind(this)} logout={this.logout.bind(this)}/> } exact />
            <RestrictRoute path="/applications/:id" component={ (props) => <EditApplication appId={props.match.params.id} language={this.changeLang.bind(this)} logout={this.logout.bind(this)}/> } exact />
            <RestrictRoute path="/applications/:id/config" component={ (props) => <ApplicationConfig appId={props.match.params.id} language={this.changeLang.bind(this)} logout={this.logout.bind(this)}/> } exact />
            <RestrictRoute path="/applications/:id/env/:envId/reset" component={ (props) => <ResetEnvironmentKey envId={props.match.params.envId} appId={props.match.params.id} language={this.changeLang.bind(this)} logout={this.logout.bind(this)}/> } exact />
            <RestrictRoute path="/applications/:id/properties" component={ (props) => <Properties appId={props.match.params.id} language={this.changeLang.bind(this)} logout={this.logout.bind(this)}/> } exact />
            <RestrictRoute path="/applications/:appId/properties/:id" component={ (props) => <EditProperty appId={props.match.params.appId} propertyId={props.match.params.id} language={this.changeLang.bind(this)} logout={this.logout.bind(this)}/> } exact />
            <RestrictRoute path="/applications/:id/target-groups" component={ (props) => <TargetGroups appId={props.match.params.id} language={this.changeLang.bind(this)} logout={this.logout.bind(this)}/> } exact />
            <RestrictRoute path="/applications/:appId/target-groups/:id" component={ (props) => <EditTargetGroup appId={props.match.params.appId} targetGroupId={props.match.params.id} language={this.changeLang.bind(this)} logout={this.logout.bind(this)}/> } exact />          
            <RestrictRoute path="/applications/:id/toggles" component={ (props) => <Toggles appId={props.match.params.id} language={this.changeLang.bind(this)} logout={this.logout.bind(this)}/> } exact />
            <RestrictRoute path="/applications/:appId/toggles/new" component={ (props) => <EditToggle appId={props.match.params.appId} language={this.changeLang.bind(this)} logout={this.logout.bind(this)}/> } exact />
            <RestrictRoute path="/applications/:appId/environments/:envId/experiments" component={ (props) => <Experiments appId={props.match.params.appId} envId={props.match.params.envId} language={this.changeLang.bind(this)} logout={this.logout.bind(this)}/> } exact />
            <RestrictRoute path="/applications/:appId/environments/:envId/experiments/:id" component={ (props) => <EditExperiment appId={props.match.params.appId} envId={props.match.params.envId} experimentId={props.match.params.id} language={this.changeLang.bind(this)} logout={this.logout.bind(this)}/> } exact />
            <RestrictRoute path="/applications/:appId/audit-logs" component={ (props) => <AuditLogs appId={props.match.params.appId} language={this.changeLang.bind(this)} logout={this.logout.bind(this)}/> } exact />
            <RestrictRoute path="/account/keys" component={ (props) => <AccountKeys language={this.changeLang.bind(this)} logout={this.logout.bind(this)}/> } exact />
            <RestrictRoute path="/account/keys/new" component={ (props) => <NewAccountKey language={this.changeLang.bind(this)} logout={this.logout.bind(this)}/> } exact />
            <RestrictRoute path="/users" component={ () => <Users language={this.changeLang.bind(this)} logout={this.logout.bind(this)}/> } exact />
            <RestrictRoute path="/users/:id" component={ (props) => <EditUser userId={props.match.params.id} language={this.changeLang.bind(this)} logout={this.logout.bind(this)}/> } exact />
            <RestrictRoute path="/change-password" component={ () => <ChangePassword language={this.changeLang.bind(this)} logout={this.logout.bind(this)}/> } exact />
            <RestrictRoute path="/audit-logs" component={ () => <AuditLogs language={this.changeLang.bind(this)} logout={this.logout.bind(this)}/> } exact />
            <RestrictRoute path="*" component={ () => <NotFound language={this.changeLang.bind(this)} logout={this.logout.bind(this)}/> } exact />
          </Switch>
        </LangProvider>
      </BrowserRouter>
    );
  }
}

export default App;
