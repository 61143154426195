import React from 'react';
import i18n from '../../../../../i18n/Message';

const TargetGroupsSelect = (props) => {


    const TargetGroups = props.targetGroups.map(tg => { 
        const disabledOption = props.selectedTargetGroups.includes(tg.id)
        return <option key={tg.id} value={tg.id} disabled={disabledOption || null}>{tg.name}</option> 
    })
    

    return (
        <select name="select" id="tg" className="form-control" style={{width:'35%', margin:'2px'}} onChange={props.onSelect}>
            <option value="" readOnly>{ i18n("general.select") }</option>
            { TargetGroups }
        </select>
    )


}

export default TargetGroupsSelect