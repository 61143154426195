import React, { Component } from 'react'
import i18n from '../../i18n/Message'

class DataTypeSelect extends Component {

    render() {
        
        const options = ["STRING", "NUMERIC", "DATE", "BOOLEAN", "SEMANTIC_VERSION"].map(type => {
            const isSelected = this.props.property.type === type
            return <option value={type} selected={isSelected}>{ i18n(`properties.type.${type}`) }</option>
        })

        return (
            <select disabled={this.props.property.id && this.props.property.id.length > 0} name="select" id="type" className="form-control" onChange={this.props.onChange}>
                <option value="">{ i18n("properties.type.placeholder") }</option>
                {  options }
            </select>
        )
    }
}

export default DataTypeSelect