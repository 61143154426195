import React, { Component } from 'react'
import i18n from '../../i18n/Message';
import RoundButton from '../ui/RoundButton';

class ConfirmationModal extends Component {

    render() {
        
        return ([
            <div key={this.props.id} className="modal" id={this.props.id} tabIndex="-1" role="dialog" aria-labelledby="smallmodalLabel" style={{display:"none"}} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="smallmodalLabel">{this.props.title}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label={ i18n("general.close") }>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>{this.props.message}</p>
                        </div>
                        <div className="modal-footer">
                            <RoundButton onClick={this.props.onCancel} type="button" className="btn btn-secondary" data-dismiss="modal">{ i18n("general.cancel") }</RoundButton>
                            <RoundButton onClick={this.props.onConfirm} type="button" className="btn btn-primary">{ this.props.label }</RoundButton>
                        </div>
                    </div>
                </div>
            </div>
        ])

    }
}

export default ConfirmationModal