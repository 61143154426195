import React, { Component } from 'react'
import i18n from './Message';
import BasePage from '../components/pages/base/BasePage';

class ForbiddenResource extends Component {
    render() {
        return (
            <div className="alert alert-danger" role="alert">
                <h1><i className="fa fa-warning"></i>&nbsp;{ i18n("general.forbiden.resource") }</h1>
            </div>
        )
    } 
}

class Forbidden extends Component {
    
    render() {
        return <BasePage 
                    language={this.props.language} 
                    logout={this.props.logout} 
                    component={ <ForbiddenResource/> }
                    title={ i18n("general.forbiden") } />
    }
}

export default Forbidden