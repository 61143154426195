import user from '../components/user/UserData';
import axios from 'axios';

const HttpClient = {

    "app": null,
    
    "setApp": function(anApp) {
        this.app = anApp 
    },

    "request" : function(config, authenticated = true) {
        if (authenticated) {
            let authHeader = { "Authorization" : user.token() }
            if (config.headers) {
                config.headers = { ...authHeader, ...config.headers }
            } else {
                config.headers = authHeader
            }
        }
        let promise = axios.request(config)
        const self = this
        promise.catch(function(error) {
            if (error.response) {
                switch(error.response.status) {
                    case 401:
                        self.app.logout()
                        break
                    case 403:
                        self.app.forbiden()
                        break
                }
            }
        })
        return promise
    },

    "get": function(url, params) {
        return this.request({"params": params, "url": url, "method": "GET" })
    },

    "post": function(config, authenticated = true) {
        return this.request({...config, "method": "POST" }, authenticated)
    },

    "put": function(config) {
        return this.request({...config, "method": "PUT" })
    },

    "delete": function(config) {
        return this.request({...config, "method": "DELETE" })
    }

}

export default HttpClient;