import React from 'react';
import ReactDOM from 'react-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'
import './assets/css/style.css'
import './assets/css/normalize.css'
import './assets/css/font-awesome.min.css'
import './assets/css/themify-icons.css'
import './assets/css/flag-icon.min.css'
import './assets/css/cs-skin-elastic.css'
import './assets/css/lib/vector-map/jqvmap.min.css'
import './assets/css/scss/style.css'

import App from './App';

import HttpsRedirect from 'react-https-redirect'

ReactDOM.render(<HttpsRedirect><App /></HttpsRedirect>, document.getElementById('root'));