import React, { Component } from 'react'
import RuleTypeSelect from '../RuleTypeSelect';
import ToggleDependencySelect from './ToggleDependencySelect';
import ToggleDependencyValues from './ToggleDependencyValues';

class ToggleDependencyRule extends Component {

    render() {

        return [
            <RuleTypeSelect key={`${this.props.ruleIndex}rts`} selectedRuleType="TOGGLE_DEPENDENCY" onChange={this.props.onChangeRuleType} />,
            <ToggleDependencySelect 
                key={`${this.props.ruleIndex}tds`}
                rule={this.props.rule}
                toggles={this.props.toggles}
                experimentToggleId={this.props.experimentToggleId}
                onChange={this.props.onToggleSelect}
            />,
            <ToggleDependencyValues
                key={`${this.props.ruleIndex}tdv`}
                rule={this.props.rule}
                toggles={this.props.toggles}
                onChange={this.props.onValueSelect}
            />
        ]
    }
}

export default ToggleDependencyRule