import React, { Component } from 'react'
import user from '../../user/UserData'
import i18n from '../../../i18n/Message'

class BaseHeader extends Component {

    languageFlags = {
        pt: "br",
        en: "gb",
        es: "es",
        de: "de"
    }

    lang(e, lang) {
        e.preventDefault()
        this.props.language(lang)
    }

    render() {
        return (
            <div className="breadcrumbs">
                <div className="w-100">
                    <div className="page-header float-left">
                        <div className="page-title">
                            <h1>{this.props.title}</h1>
                        </div>
                    </div>
                    <div className="float-right">
                        <div className="page-title">
                            <div className="user-section text-right">
                                <div className="nav-item dropdown" style={{"margin":"5px 5px 5px 5px"}}>
                                    <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                        <i className={`flag-icon flag-icon-${this.languageFlags[user.getLanguage()]}`}></i>
                                    </a>
                                    <div className="dropdown-menu" style={{ minWidth:'0px', marginLeft: "-1.5rem"}}>
                                        <a className="dropdown-item" href="#" onClick={(e) => this.lang(e, "en")}>
                                            <i className="flag-icon flag-icon-gb"></i>
                                        </a>
                                        <a className="dropdown-item" href="#" onClick={(e) => this.lang(e, "pt")}>
                                            <i className="flag-icon flag-icon-br"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
                <div className="col-sm-6">
                    
                </div>
            </div>
        )
    }

}

export default BaseHeader