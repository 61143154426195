import React from 'react';
import i18n from '../../../../i18n/Message';

const RuleTypeSelect = (props) => {
    return (
        <select disabled={props.selectedRuleType === "ALL_USERS" || null } defaultValue={props.selectedRuleType ? props.selectedRuleType : ""} name="select" id="rule-type" className="form-control" style={{width:'20%', margin:'2px'}} onChange={props.onChange}>
            <option value="ALL_USERS" disabled={true}>{ i18n("experiments.rules.all.users") }</option>
            <option value="PROPERTY">{ i18n("properties.header") }</option>
            <option value="TARGET_GROUP">{ i18n("target-groups.header") }</option>
            <option value="TOGGLE_DEPENDENCY">{ i18n("toggles.header") }</option>
        </select>
    )
}

export default RuleTypeSelect