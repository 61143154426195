import React, { Component }  from 'react';


class LanguageSwitch extends Component {

    render() {
        return (
            <div className="w-100">
                <a href="#" onClick={(e) => { e.preventDefault(); this.props.setLang("en") }}><i className="flag-icon flag-icon-gb"></i></a>&nbsp;
                <a href="#" onClick={(e) => { e.preventDefault(); this.props.setLang("pt") }}><i className="flag-icon flag-icon-br"></i></a>
            </div>
        )
    }

}

export default LanguageSwitch