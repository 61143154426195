import React from 'react'
import i18n from '../../../i18n/Message'

function isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

const Error = (props) => {

    let error = ""
    if (props.conf && props.conf[props.status]) {
        const message = isFunction(props.conf[props.status]) ? props.conf[props.status]() : props.conf[props.status] 
        error = <div className="alert alert-danger" role="alert">{ message }</div>
    } else if (props.status === "ERROR") {
        error = <div className="alert alert-danger" role="alert">{ i18n("general.error.message") }</div>
    }

    return error
}

export default Error