import React, { Component } from 'react'
import i18n from '../../i18n/Message'

class PropertiesSelect extends Component {

    render() {

        const defaultOption = this.props.defaultOption || i18n("properties.select.placeholder")

        const style = this.props.style || {}
        
        const options = this.props.properties.map(property => {
            return <option key={property.id} value={property.id}>{ property.key }</option>
        })

        return (
            <select data-unique-id={this.props.uniqueId} value={this.props.selectedProperty ? this.props.selectedProperty : ""} name="select" id="property" className="form-control" onChange={this.props.onChange} style={style}>
                <option value="">{ defaultOption }</option>
                {  options }
            </select>
        )
    }
}

export default PropertiesSelect