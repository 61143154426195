import React, { Component } from 'react'
import BaseHeader from './BaseHeader'

class RightPanel extends Component {

    render() {
        return (
        
        <div key="right-panel" id="right-panel" className="right-panel">
    
            <BaseHeader language={this.props.language} title={this.props.title} />
    
            <div className="content mt-3">
                { this.props.component ? this.props.component : "" }
            </div>
           
        </div>
         // <!-- /#right-panel --> 

        )
    }

}

export default RightPanel