import React, { Component } from 'react'
import i18n from '../../../../i18n/Message'
import Slider, { createSliderWithTooltip } from 'rc-slider'
import 'rc-slider/assets/index.css'

const CustomSlider = createSliderWithTooltip(Slider)

const ExactValueSpec = props => {

    const value = props.value || props.toggle.defaultValue

    return (
        <select value={value} className="form-control" style={{width:"20%"}} onChange={props.onValueChange}>
            { props.toggle.values.map(val => <option key={val} value={val}>{val}</option>) }
        </select>
    ) 
}

class Percentage extends Component {
    state = { intermediateValue: 0 }

    componentDidMount() {
        this.setState({intermediateValue: this.props.defaultValue})
    }

    render() {
        const formatter = (value) => `${value}%`
        return (
            <div className="col-6">
                <CustomSlider
                    tipFormatter={formatter}
                    defaultValue={this.props.defaultValue}
                    trackStyle={{ backgroundColor: '#287AF6', height: 5 }} 
                    railStyle={{ height: 5 }} 
                    onChange={(value) => this.setState({intermediateValue: value})}
                    onAfterChange={(value) => this.props.onChange(value)}
                    max={99}
                    />
                    <span>{this.state.intermediateValue}%</span>
            </div>
        )
    }
}

const SplitRow = props => {
    return (
        <div className="row align-items-center">
            <div className="col-6">
                <input type="text" readOnly className="form-control" style={{margin:"2px"}} value={props.value}/>
            </div>
            <Percentage defaultValue={props.percentage} onChange={(newPercentage) => props.onPercentageChange({value: props.value, percentage: newPercentage})} />
        </div>
        
    )
}

const SplitSpec = props => {

    const percentages = []
    for (let i = 0;  i < 100; i++) percentages.push(<option value={i}>{`${i}%`}</option>)

    const valuesPercentagesPairs = []

    for (let value in props.spec.split) {
        valuesPercentagesPairs.push([value, props.spec.split[value]])
    }

    return (
        <div className="col-6">
            { valuesPercentagesPairs.map(pair => <SplitRow key={`${pair[0]}${pair[1]}`} percentages={percentages} value={pair[0]} percentage={pair[1]} onPercentageChange={props.onPercentageChange} />) }
        </div>
    )
}


class ValueSpecification extends Component {


    render() {

        const Spec = this.props.spec.type === "EXACT_VALUE" ?
            <ExactValueSpec key={`${new Date().getTime()}evs`} value={this.props.spec.exactValue} toggle={this.props.toggle} onValueChange={this.props.onValueChange}/> : 
            <SplitSpec key={`${new Date().getTime()}ss`} spec={this.props.spec} onPercentageChange={this.props.onPercentageChange} />

        return [
            <select key={new Date().getTime()} id="type" value={this.props.spec.type} className="form-control" style={{width:"20%", margin:"2px"}} onChange={this.props.onChangeSpecType}>
                <option value="EXACT_VALUE">{ i18n("experiments.then.result.is") }</option>
                <option value="SPLIT">{ i18n("experiments.then.split") }</option>
            </select>,
            Spec
        ]

    }
    


}

export default ValueSpecification