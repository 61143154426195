import React, { Component } from 'react';
import BasePage from '../base/BasePage';
import i18n from '../../../i18n/Message';
import httpClient from '../../../http/HttpClient';
import env from '../../../util/Env'
import RoundButton from '../../ui/RoundButton';
import ConfirmationModal from '../../general/ConfirmationModal';
import InformationModal from '../../general/InformationModal';
import $ from 'jquery'
import { Redirect } from 'react-router-dom';

const INFO_MODAL = "info-modal"

class TargetGroupsTable extends Component {

    state = {
        status: "",
        infoTitle: "",
        infoMessage: ""
    }

    componentDidUpdate() {
        if (this.state.infoTitle && this.state.infoMessage && this.state.status === "SHOW_INFO") {
            $(`#${INFO_MODAL}`).modal()
            this.state.status = ""
        }
    }

    render() {

        const Groups = this.props.targetGroups.map(group => {
            return (
                <tr>
                    <th scope="row"><a href={`/applications/${this.props.appId}/target-groups/${group.id}`} className="edit-link">{group.name}</a></th>
                    <td className="hide-on-small-screen">{ group.description }</td>
                    <td>
                        {/* <RoundButton type="anchor" href={`/applications/${this.props.appId}/target-groups/${group.id}`} className="btn btn-primary btn-sm"><i className="fa fa-edit"></i>&nbsp;{ i18n("general.edit") }</RoundButton>&nbsp; */}
                        <RoundButton data-toggle="modal" data-target={`#modal-${group.id}`} type="anchor" href="#" className="btn btn-danger btn-sm"><i className="fa fa-warning"></i>&nbsp;{ i18n("general.delete") }</RoundButton>&nbsp;
                        <ConfirmationModal id={`modal-${group.id}`} title={`${i18n("general.delete")} ${i18n("target-groups.header")} '${group.name}'`} message={ i18n("target-groups.delete.confirm") } label={ i18n("general.delete")}  onConfirm={(e) => this.delete.bind(this)(e, `modal-${group.id}`, group.id)} />
                    </td>
                </tr>
            )
        })

        return (
            [
                <InformationModal key={INFO_MODAL} id={INFO_MODAL} titleKey={this.state.infoTitle} messageKey={this.state.infoMessage}/>,
                <div key="table" className="row">
                    <div className="col-lg-12 table-responsive">
                        <div className="card">                        
                            <div className="card-body">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">{ i18n("target-groups.name.label") }</th>
                                            <th className="hide-on-small-screen" scope="col">{ i18n("target-groups.description.label") }</th>
                                            <th scope="col">{ i18n("general.more") }</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { Groups }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>,
                <RoundButton type="anchor" href={`/applications/${this.props.appId}/target-groups/new`} className="btn btn-primary btn-sm"><i className="fa fa-plus"></i>&nbsp;{ i18n("target-groups.new") }</RoundButton>
            ]
        )
    }

    delete(e, modalId, targetGroupId) {
        e.preventDefault()
        $(`#${modalId}`).modal("hide")

        let self = this
        httpClient.delete({
            url: `${env["api.url"]}/api/apps/${this.props.appId}/target-groups/${targetGroupId}`
        }).then(function (response) {
            self.setState({ 
                status: "SHOW_INFO",
                infoTitle: "general.success",
                infoMessage: "target-groups.delete.success"
            })
            self.props.reload()
        }).catch(function(error) {
            let errorTitle = "general.error"
            let errorMessage = "general.error.message"
            if (error.response && error.response.data && error.response.data.error_code)  {
                switch(error.response.data.error_code) {
                    case "TARGET_GROUP_USED_IN_EXPERIMENT":
                        errorMessage = "target-groups.error.delete.used.in.experiments"
                        break
                    case "NOT_FOUND":
                        errorMessage = "target-groups.error.not.found"
                        break
                }
            } 
            self.setState({ 
                status: "SHOW_INFO",
                infoTitle: errorTitle,
                infoMessage: errorMessage,
            })
            
        })
        
    }

}


class TargetGroups extends Component {

    state = { httpStatus: 0, targetGroups: [] }

    componentDidMount() {
        let self = this
        httpClient.get(`${env["api.url"]}/api/apps/${this.props.appId}/target-groups`)
        .then(function (response) {
            self.setState({ 
                targetGroups : response.data
            })
        }).catch(function(error) {
            if (error.response)  {
                self.setState({ httpStatus: error.response.status })
            }
        })
    }

    render() {
        if (this.state.httpStatus === 404) return <Redirect to="/not-found"/>
        return ( 
            <BasePage 
                appId={this.props.appId}            
                language={this.props.language} 
                logout={this.props.logout} 
                component={ <TargetGroupsTable appId={this.props.appId} targetGroups={this.state.targetGroups} reload={this.componentDidMount.bind(this)}/> } 
                title={ i18n("target-groups.title") }
            />
        )
    }
}



export default TargetGroups