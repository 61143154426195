import React, { Component } from 'react';
import BasePage from '../base/BasePage';
import i18n from '../../../i18n/Message';
import httpClient from '../../../http/HttpClient';
import env from '../../../util/Env'
import $ from 'jquery'
import { Redirect } from 'react-router-dom';
import Error from '../base/Error'
import Applications from '../../Applications';
import FormActions from '../../general/FormActions';

// const SLACK_WEBHOOK_REGEX = /https:\/\/hooks.slack.com\/services\/T[a-zA-Z0-9_]{8}\/B[a-zA-Z0-9_]{8}\/[a-zA-Z0-9_]{24}"/

class Environments extends Component {

    render() {

        /* Ugly as fuck workaround to capture 
           the "close" event and handle it as 
           the removal of an item from the environments list
        */
        let self = this
        $(document).on(
            "close.bs.alert",
            ".alert.alert-success.alert-dismissible.show",
            function(e) {
                e.preventDefault()
                e.stopImmediatePropagation()
                self.props.remove(e.target.attributes["data-name"].value)
            }
        )

        const envItems = this.props.envs.map((env) => {

            const editingThis = self.props.status === "EDIT_ENV" && env.id === self.props.currentEnv.id
            const status = editingThis ? "secondary" : "success"
            const name = editingThis ? self.props.currentEnv.name : env.name
            const editOrCancel = editingThis ? i18n("general.cancel") : i18n("general.edit")
            const ActionButton = env.id ? <a href="#" onClick={ () => self.props.editEnv(env.id) }> - { editOrCancel }</a> : ""
            const Close = self.props.status === "EDIT_ENV" ? "" : <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>

            return (
                <div key={env.name} className={`alert alert-${status} alert-dismissible show`}data-name={env.name}>
                    <i className="fa fa-server"></i>{ ` ${name}` }
                    { ActionButton }
                    { Close }
                </div>  
            )
        })

        return (
            envItems && envItems.length > 0 ?
            <div className="row" style={{margin:'15px'}}>
                <div className="w-100">
                    { envItems }
                </div>
            </div> : ""
        )
    }
}

class AppForm  extends Component {

    state = { 
        originalApp: { name: "", environments: [], notificationWebhook: "", notificationLanguage: "" }, 
        app: { name: "", environments: [], notificationWebhook: "", notificationLanguage: "" }, 
        status: "", 
        envs: [],
        editEnv: { id: "", name: "" }
    };

    componentDidMount() {

        if (this.props.appId && this.props.appId.length > 0 && this.props.appId !== "new") {
            let self = this
            httpClient.get(`${env["api.url"]}/api/apps/${this.props.appId}`)
            .then(function (response) {
                self.setState({ 
                    originalApp: response.data, 
                    app : {...response.data, environments: [].concat(response.data.environments.map(e => { return {...e} }))} 
                })
            })
        }
    }

    render() {

        if (this.state.status === "DONE") {
            if (this.state.originalApp.id) {
                return <Redirect to={`/applications/${this.state.originalApp.id}/config`} />
            }
            return <Redirect to={`/applications/${this.state.savedApp.id}/toggles/new`} />
        }
        const self = this
        return [
            <Error 
                status={this.state.status} 
                conf={ 
                    {
                        "EMPTY_NAME": i18n("applications.error.empty.name"),
                        "NO_ENVS": i18n("applications.error.no.environment"),
                        "DUPLICATE_NAME": i18n("applications.error.name.already.in.use"),
                        "MAX_APP_LIMIT_REACHED": i18n("applications.error.max.app.limit.reached"),
                        "MAX_ENVIRONMENTS_LIMIT_REACHED": () => {
                            return `${i18n("applications.error.max.env.limit.reached")} (${self.state.metaData["max-environments"]}).` 
                        },
                        "INVALID_WEBHOOK": i18n("applications.error.invalid.webhook"),
                        "INVALID_WEBHOOK_LANGUAGE": i18n("applications.error.invalid.webhook.lang")
                    } 
                }
                />,
            <div key="23kdf" className="row">
                <div className="col-lg-6">
                    <div className="card">
                        <div className="card-header"><strong>{ i18n("applications.header") }</strong></div>
                        <div className="card-body card-block">
                            <div className="form-group">
                                <label htmlFor="company" className=" form-control-label">{ i18n("applications.name.label") }</label>
                                <input value={this.state.app.name} onChange={this.updateName.bind(this)} type="text" id="company" placeholder={ i18n("applications.name.placeholder") } className="form-control" />
                            </div>
                        </div>

                    </div>
                </div>

                <div className="col-lg-6">
                    <div className="card">
                        <div className="card-body card-block">
                            { i18n("applications.edit.description") }
                        </div>
                    </div>
                </div>
            </div>,
            <div key="asdm" className="row">
                <div className="col-lg-6">
                    <div className="card">
                        <div className="card-header"><strong>{ i18n("applications.environments.header") }</strong></div>
                        <div className="card-body card-block">
                            <div className="form-group">
                                <label htmlFor="env-name" className=" form-control-label">{ i18n("applications.environments.label") }</label>
                                <div className="input-group">
                                    <input value={this.state.editEnv.name} onChange={this.updateEnvName.bind(this)} id="env-name" onKeyPress={this.updateEnvironment.bind(this)} type="text" placeholder={ i18n("applications.environments.placeholder") } className="form-control" />
                                    <div className="input-group-btn">
                                        <button className="btn btn-primary" onClick={this.addEnv.bind(this)} style={{borderRadius:"0 25px 25px 0"}}>{ i18n("general.add") }</button>
                                    </div>
                                </div>
                                <Environments currentEnv={this.state.editEnv} status={this.state.status} editEnv={this.editEnv.bind(this)} envs={this.state.app.environments} remove={(env) => this.removeEnvironment.bind(this)(env)} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6">
                    <div className="card">
                        <div className="card-body card-block">
                            { i18n("applications.environments.description") }
                        </div>
                    </div>
                </div>
            </div>,
            <div key="slack-webhook" className="row">
                <div className="col-lg-6">
                    <div className="card">
                        <div className="card-header"><strong>Slack</strong></div>
                        <div className="card-body card-block">
                            <div className="form-group">
                                <label htmlFor="env-name" className=" form-control-label">{ i18n("applications.webhook.url") }</label>
                                <input value={this.state.app.notificationWebhook} onChange={(e) => { this.setState({app: {...this.state.app, notificationWebhook:e.target.value}}) }} id="webhook-url" type="text" placeholder={`${i18n("general.example")} https://hooks.slack.com/services/FOO/BAR/BAZ` } className="form-control" />
                                <label htmlFor="slack-lang" className=" form-control-label">{ i18n("general.language") }</label>
                                <select className="form-control" value={this.state.app.notificationLanguage} onChange={(e) => { this.setState({app: {...this.state.app, notificationLanguage:e.target.value}}) }}>
                                    <option>{ i18n("general.select") }</option>
                                    <option value="en">{ i18n("general.language.en") }</option>
                                    <option value="pt">{ i18n("general.language.pt") }</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6">
                    <div className="card">
                        <div className="card-body card-block">
                            { i18n("applications.webhook.slack.explanation") }
                        </div>
                    </div>
                </div>
            </div>,
            <FormActions onSave={this.save.bind(this)} onReset={this.reset.bind(this)}/>

        ]
    }

    updateEnvName(e) {
        this.setState({ "editEnv": {...this.state.editEnv, "name": e.target.value } })
    }

    // Only already saved environments can be edited
    editEnv(id) {
        const env = this.state.app.environments.find(env => env.id === id)
        if (env && env.id !== this.state.editEnv.id) {
            $("#env-name").val(env.name)
            this.setState({ editEnv: env, status: "EDIT_ENV" })
        } else if(this.state.status === "EDIT_ENV" && env.id === this.state.editEnv.id) {
            // Cancel button clicked
            this.setState({ editEnv: { id: "", name: "" }, status: "" })
        }
    }

    reset() {
        this.setState({ status: "", editEnv: { id: "", name: "" }, app: {...this.state.originalApp, environments: [].concat(this.state.originalApp.environments.map(e => {return {...e}}))}  })
    }

    updateName(e) {
        this.setState({ "app": {...this.state.app, "name": e.target.value } })
        if (this.state.app.name && this.state.app.name.trim().length > 0) {
            this.setState({ "status": ""})
        }
    }

    addEnv() {
        const input = $("#env-name")[0]
        this.updateEnvironment({
            target: input,
            key: "Enter" // Simulating enter key press
        })
    }

    updateEnvironment(e) {
        const value = e.target.value
        if (value) {
            if (e.key === 'Enter') {
                var environmentName = value.trim()
            }
            if (environmentName) {
                const envs = this.state.app.environments
                const found = envs.find(e => { return e.name === environmentName })
                if (!found) {
                    if (this.state.editEnv.id) {
                        envs.find(e => { return e.id === this.state.editEnv.id }).name = environmentName
                    } else {
                        envs.push({ "name": environmentName})
                    }
                    this.setState({ editEnv: { id: "", name: "" }, "status": "", "app": {...this.state.app, "environments": envs } })
                }
                e.target.value = ""
            }
        } 
    }
    
    removeEnvironment(env) {
        const envs = this.state.app.environments
        const index = envs.findIndex(e => { return e.name === env })
        if (index > -1) {
            envs.splice(index, 1)
            this.setState({ "app": {...this.state.app, "environments": envs } })
        }
    }

    save() {
        let self = this
        if (this.state.app.name) {
            if (this.state.app.environments.length > 0) {

                let action
                if (this.state.app.id) { // Old application -> just editing
                    action = httpClient.put({
                        url: `${env["api.url"]}/api/apps/${this.state.app.id}`,
                        data: this.state.app
                    })
                } else { // New application -> just save it 
                    action = httpClient.post({
                        url: `${env["api.url"]}/api/apps`,
                        data: this.state.app
                    })
                }
                action.then(function(response) {
                    Applications.clearApps()
                    self.setState({ "status": "DONE", "savedApp": response.data })
                }).catch(function(error) {
                    if (error.response && error.response.data && error.response.data.error_code)  {
                        self.setState({ status : error.response.data.error_code, metaData: error.response.data.meta_data })
                    } else {
                      self.setState({ status : "ERROR" })
                    }
                })
            } else {
                this.setState({ "status": "NO_ENVS"})
            }
        } else {
            this.setState({ "status": "EMPTY_NAME"})
        }
    }


}

class EditApplication extends Component {

    render() {
        return ( 
            <BasePage key="base-edit-app" appId={this.props.appId} language={this.props.language} logout={this.props.logout} component={ <AppForm appId={this.props.appId}/> } title={ i18n("applications.title") + " > "+ i18n("general.edit") }/>
        )
    }
}



export default EditApplication