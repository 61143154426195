import expressions from '../../general/Expressions'

const StringValidator = (condition) => {
    return validate(condition, () => {
        switch(condition.expression) {
            case "IN": 
                const terms = condition.term.split(",")
                const termResults = terms.map(term => { 
                    if (term && term.trim().length > 0) {
                        return "VALID"
                    } else { 
                        return "INVALID_TERM"
                    }
                 })
    
                if (termResults.some(result => result !== "VALID")) {
                    return "INVALID_TERM_IN"    
                }
            case "REGEX":
                try {
                    new RegExp(condition.term)
                } catch(e) {
                    return "INVALID_REGEX"
                }
                break
        }
    })
}

const validate = (condition, block) => {
    const term = condition.term


    // Chained ifs - ugly as fuck, but good enough for now
    if (condition.propertyId && condition.propertyId.trim().length > 0) {

        if (condition.expression && condition.expression.trim().length > 0) {

            if (expressions[condition.propertyType].some(exp => exp === condition.expression)) {

                if (term && term.trim().length > 0) {

                    const validationResult = block()
                    return validationResult ? validationResult : "VALID"
                } else {
                    return "EMPTY_TERM"
                }
            } else {
                return "INVALID_EXPRESSION"
            }
        } else {
           return "NO_EXPRESSION"
        }
    } else {
        return "NO_PROPERTY"
    }
}

const NumericValidator = (condition) => {
    return validate(condition, () => {
        if (condition.expression === "IN") {
            const terms = condition.term.split(",")
            const termResults = terms.map(term => { 
                if (term && term.trim().length > 0) {
                    if (isNaN(term) || term.toLowerCase() === "true" || term.toLowerCase() === "false") return "INVALID_TERM"
                    return "VALID"
                } else { 
                    return "INVALID_TERM"
                }
             })

            if (termResults.some(result => result !== "VALID")) {
                return "NOT_A_NUMBER_IN"
            }
        } else {
            const term = condition.term.trim()
            if (isNaN(term) || term.toLowerCase() === "true" || term.toLowerCase() === "false") return "NOT_A_NUMBER"
            return "VALID"
        }
    })
}

const DateValidator = (condition) => {
    return validate(condition, () => {
        if (condition.expression === "IN") {
            const terms = condition.term.split(",")
            const termResults = terms.map(t => { 
                const term = t.trim()
                if (term && term.length > 0) {
                    if (!new RegExp("^([0-9]{4}-[0-9]{2}-[0-9]{2})$").test(term)) {
                        return "INVALID_DATE_FORMAT_IN"
                    } else {
                        const result = isValidDate(term)
                        return result === "VALID" ? "VALID" : "INVALID_DATE_IN"
                    }
                } else { 
                    return "INVALID_DATE_FORMAT_IN"
                }
             })

            if (termResults.some(result => result !== "VALID")) {
                return termResults.find(result => result !== "VALID")
            }
        } else {
            const term = condition.term.trim()
            if (term && term.trim().length > 0) {
                if (!new RegExp("^([0-9]{4}-[0-9]{2}-[0-9]{2})$").test(term)) {
                    return "INVALID_DATE_FORMAT"
                } else {
                    return isValidDate(term)
                }
            } else { 
                return "INVALID_DATE_FORMAT"
            }
        }
    })
}

// Format YYYY-MM-DD
const isValidDate = (dateString) => {
    const dateItems = dateString.split("-")
    const year = dateItems[0]
    const month = dateItems[1]
    const day = dateItems[2]

    try {
        const date = new Date(Date.UTC(year, month-1, day, 0, 0, 0))
        if (day != date.getUTCDate() || month != (date.getUTCMonth()+1) || year != date.getUTCFullYear()) {
            return "INVALID_DATE"
        } 
    } catch(e) {
        return "INVALID_DATE"
    }

    return "VALID"
}

const BooleanValidator = (condition) => {
    return validate(condition, () => {
        const term = condition.term.trim()
        if (term.toLowerCase() !== "true" && term.toLowerCase() !== "false") return "INVALID_BOOLEAN_VALUE"
    })
}

const SemVerValidator = (condition) => {
    const semverRegex = /^([0-9]+\.[0-9]+\.[0-9]+)$/
    return validate(condition, () => {
        if (condition.expression === "IN") {
            const terms = condition.term.split(",")
            const termResults = terms.map(term => { 
                if (term && term.trim().length > 0) {
                    if (!semverRegex.test(term)) {
                        return "INVALID_SEMVER"
                    }
                    return "VALID"
                } else { 
                    return "INVALID_TERM"
                }
             })

            if (termResults.some(result => result !== "VALID")) {
                return "INVALID_SEMVER_IN"
            }
        } else {
            const term = condition.term.trim()
            if (!semverRegex.test(term)) {
                return "INVALID_SEMVER"
            }
            return "VALID"
        }
    })
}

const Validators = {
    "STRING": StringValidator,
    "NUMERIC": NumericValidator,
    "DATE": DateValidator,
    "BOOLEAN": BooleanValidator,
    "SEMANTIC_VERSION": SemVerValidator
}


const validateConditions = (conditions, properties) => {
    const validationResults = conditions.map(condition => { 
        const prop = properties.find(prop => prop.id === condition.propertyId)
        if (!prop) return {id: condition.id, result: "NO_PROPERTY"}
        return {id: condition.id, result: Validators[prop.type]({...condition, propertyType: prop.type}) }
    })
    const firstError = validationResults.find(item => item.result !== "VALID")
    if (firstError) return firstError
    return {result:"VALID"}
}

export default validateConditions