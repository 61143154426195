const langs = {
    "pt" : {
        "quick.toggle": "Quick Toggle",
        "general": "Geral",
        "general.ok": "Ok",
        "general.error": "Erro",
        "general.error.message": "Ocorreu um erro inesperado. Por favor, tente novamente.",
        "general.logout": "Sair",
        "general.change.password": "Mudar senha",
        "general.edit": "Editar",
        "general.save": "Salvar",
        "general.reset": "Redefinir",
        "general.settings": "Configurações",
        "general.cancel": "Cancelar",
        "general.mandatory.password": "Senha é obrigatória.",
        "general.wrong.password": "Senha incorreta!",
        "general.delete": "Excluir",
        "general.more": "Mais...",
        "general.or": "Ou",
        "general.and": "E",
        "general.if": "se",
        "general.else": "senão",
        "general.then": "então",
        "general.select": "Selecione",
        "general.forbiden": "Acesso proibido",
        "general.forbiden.resource": "Você não possui acesso a este recurso!",
        "general.audit": "Auditoria",
        "general.months.01": "Janeiro",
        "general.months.02": "Fevereiro",
        "general.months.03": "Março",
        "general.months.04": "Abril",
        "general.months.05": "Maio",
        "general.months.06": "Junho",
        "general.months.07": "Julho",
        "general.months.08": "Agosto",
        "general.months.09": "Setembro",
        "general.months.10": "Outubro",
        "general.months.11": "Novembro",
        "general.months.12": "Dezembro",
        "general.equal.to": "Igual a",
        "general.pause": "Pausar",
        "general.restart": "Reiniciar",
        "general.docs": "Documentação",
        "general.docs.url": "https://quicktoggle.com/pt/docs/",
        "general.active": "Ativo",
        "general.inactive": "Inativo",
        "general.send": "Enviar",
        "general.close": "Fechar",
        "general.success": "Sucesso",
        "general.page.not.found": "404 - Página não encontrada",
        "general.login": "Login",
        "general.thank.you": "Obrigado",
        "general.limit": "Limite",
        "general.unlimited": "Ilimitado",
        "general.add": "Adicionar",
        "general.example": "Ex.",
        "general.language": "Idioma",
        "general.language.en": "Inglês",
        "general.language.pt": "Português",
        "general.generate.new.key": "Gerar nova chave",
        "login.message": "Entre com seu usuário e senha",
        "login.password.message": "Senha deve possuir 6 digitos, sendo ao menos um numérico",
        "login.email": "E-mail",
        "login.password": "Senha",
        "login.confirm.password": "Confirmação de senha",
        "login.forgot.password": "Esqueci a senha",
        "login.wrong.credentials": "E-mail e/ou senha inválidos",
        "forgot.password.text": "Informe o e-mail da conta",
        "forgot.password.back.to.login": "Voltar à tela de login",
        "forgot.password.unknown.user": "Usuário desconhecido",
        "forgot.password.done": "Pronto. Te enviamos um e-mail com instruções de como redefinir sua senha!",
        "account.title": "Conta",
        "account.keys.title": "Chaves da conta",
        "account.keys.header": "Chaves",
        "account.keys.new": "Nova chave",
        "account.keys.key": "Chave",
        "account.keys.delete.confirm": "Você tem certeza que deseja excluir esta chave? Esta operação não pode ser desfeita!",
        "account.keys.delete.success": "Chave excluída com sucesso.",
        "account.new.key.first.explanation": "Solicitamos que você digite sua senha para confirmarmos sua intenção e identidade.",
        "account.new.key.second.explanation": "Copie e salve esta chave em um local seguro. Esta será a única vez que ela será exibida. Caso você a perca, terá que gerar uma nova chave.",
        "account.keys.error.not.found": "Chave não encontrada!",
        "account.keys.error.max.key.limit.reached": "Você atingiu o limite máximo de chaves para esta conta.",
        "account-creation.create": "Criar uma conta",
        "account-creation.name": "Seu nome",
        "account-creation.email": "E-mail",
        "account-creation.company": "Empresa",
        "account-creation.company.name": "Nome da empresa",
        "account-creation.i.accept": "Eu li e aceito os ",
        "account-creation.terms.and.conditions": "Termos e Condições",
        "account-creation.already.have.account": "Já possui uma conta?",
        "account-creation.dont.have.account": "Não possui uma conta?",
        "account-creation.error.missing.fields": "Por favor, preencha todos os campos!",
        "account-creation.error.accept.tc": "Você deve aceitar nossos Termos e Condições!",
        "account-creation.success.explanation": "Nós lhe enviamos a confirmação por e-mail. Assegure-se de verificar a caixa de Spam caso não encontre nenhum e-mail nosso.",
        "dashboard": "Painel de Controle",
        "applications.title": "Aplicações",
        "applications.new": "Nova aplicação",
        "applications.header": "Aplicação",
        "applications.environments.header": "Ambientes",
        "applications.name.label": "Nome",
        "applications.name.placeholder": "Nome da aplicação",
        "applications.edit.description": "Aqui é onde você define os detalhes de sua aplicação. A única informação necessária é o nome dela.",        
        "applications.environments.label": "Informe o ambiente e tecle ENTER para adicioná-lo à lista.",
        "applications.environments.placeholder": "Exemplos: prod, dev, staging, RC, etc...",
        "applications.environments.description": "Ambientes são utilizados para isolar experimentos. Assim, um experimento rodando no ambiente de desenvolvimento não afeta outros experimentos rodando no ambiente de produção, por exemplo.",
        "applications.environments.name": "Nome",
        "applications.environments.new.key": "Nova chave",
        "applications.environments.new.key.description": "Informe sua senha para gerar uma nova chave",
        "applications.environments.new.key.danger": "Ao gerar uma nova chave, a anterior será automaticamente invalidada. Como isso pode não ser desejado, solicitamos que você digite sua senha para confirmarmos sua intenção e identidade.",
        "applications.environments.new.key.explanation": "Copie e salve esta chave em um local seguro. Esta será a única vez que ela será exibida. Caso você a perca, terá que gerar uma nova chave para conseguir acessar o ambiente em questão.",
        "applications.toggles": "Toggles",
        "applications.delete.confirm": "Você tem certeza que deseja excluir esta aplicação? Toggles, experimentos, propriedades e públicos alvos desta aplicação também serão excluídos. Esta operação não pode ser desfeita!",
        "applications.delete.success": "Aplicação excluída com sucesso.",
        "applications.webhook.url": "URL do Webhook",
        "applications.webhook.slack.explanation": "Nesta seção você pode especificar o webhook do canal do Slack para onde enviaremos os logs de auditoria desta aplicação.",        
        "applications.error.empty.name": "Nome não pode ser vazio ou branco!",
        "applications.error.no.environment": "Ao menos um ambiente deve ser especificado!",
        "applications.error.name.already.in.use": "Já existe uma aplicação com o mesmo nome!",
        "applications.error.not.found": "Aplicação não encontrada!",
        "applications.error.max.app.limit.reached": "Você atingiu o limite máximo de aplicações para esta conta.",
        "applications.error.max.env.limit.reached": "Você atingiu o limite máximo de ambientes.",
        "applications.error.invalid.webhook": "URL do webhook é inválida!",
        "applications.error.invalid.webhook.lang": "Idioma do webhook é inválido!",
        "properties.title": "Propriedades",
        "properties.new": "Nova propriedade",
        "properties.header": "Propriedade",
        "properties.key.label": "Chave",
        "properties.key.placeholder": "Ex: pais_origem, idade, sexo etc",
        "properties.description.label": "Descrição (opcional)",
        "properties.description.placeholder": "Descreva o significado desta propriedade",
        "properties.type.label": "Tipo",
        "properties.type.placeholder": "Selecione o tipo desta propriedade",
        "properties.type.STRING": "String/Texto",
        "properties.type.NUMERIC": "Valor numérico ",
        "properties.type.DATE": "Data (AAAA-MM-DD)",
        "properties.type.BOOLEAN": "Valor booleano (true/false)",
        "properties.type.SEMANTIC_VERSION": "Versão semântica",
        "properties.edit.description.first": "Propriedades são utilizadas para definir os públicos alvos.",
        "properties.edit.description.second": "As chaves são enviadas nas requisições à API, e seus valores devem ser do tipo especificado para a referida propriedade. IMPORTANTE: a chave 'uid' é reservada, não podendo ser utilizada!",
        "properties.delete.confirm": "Você tem certeza que deseja excluir esta propriedade?",
        "properties.delete.success": "Propriedade excluída com sucesso.",
        "properties.error.empty.key": "Defina uma chave!",
        "properties.error.invalid.key": "Apenas caracteres ASCII, algarismos, hífen, underscore e ponto final (.) são permitidos na chave.",
        "properties.error.no.type": "Selecione o tipo desta propriedade!",
        "properties.error.key.already.in.use": "Já existe uma propriedade com a mesma chave!",
        "properties.error.not.found": "Propriedade não encontrada!",
        "properties.error.delete.used.in.experiments": "Propriedade está sendo utilizada em ao menos um experimento.",
        "properties.error.delete.used.in.target.groups": "Propriedade está sendo utilizada em ao menos um público alvo.",
        "properties.erros.uid.reserved": "Chave 'uid' não pode ser utilizada!",
        "properties.select.placeholder": "Selecione uma propriedade...",
        "target-groups.title": "Públicos alvos",
        "target-groups.properties.used": "Propriedades usadas",
        "target-groups.new": "Novo público alvo",
        "target-groups.header": "Público alvo",
        "target-groups.edit.description": "Públicos alvos são utilizados para segmentar os experimentos. Feature toggles podem ser habilitados/desabilitados de acordo com o públicos alvos nos quais os usuários estão inseridos.",
        "target-groups.edit.description.condition-policy": "Você pode definir se deseja que, para ser considerado parte deste público alvo, o usuário deve satisfazer ao menos uma ou todas as condições descritas.",
        "target-groups.edit.conditions.description": "Condições são regras utilizadas para determinar se um determindo usuário/entidade pertence ao público alvo em questão. Pode-se combinar multiplas condições, utilizando o operador lógico 'OU' ('ao menos uma condição'), ou o operador lógico 'E' ('todas as condições').",
        "target-groups.name.label": "Nome",
        "target-groups.name.placeholder": "Ex: 'Novos usuários', 'Usuários da região Norte' etc",
        "target-groups.description.label": "Descrição (opcional)",
        "target-groups.description.placeholder": "Uma breve descrição deste público alvo.",
        "target-groups.conditions-policy.label": "Será considerado parte deste público alvo se...",
        "target-groups.conditions-policy.at-least": "Satisfizer ao menos uma condição",
        "target-groups.conditions-policy.all": "Satisfizer todas as condições",
        "target-groups.conditions": "Condições",
        "target-groups.conditions.select.expression": "Selecione uma expressão",
        "target-groups.conditions.expressions.IN": "Dentre",
        "target-groups.conditions.expressions.EQUALS": "Igual a",
        "target-groups.conditions.expressions.NOT_EQUALS": "Diferente de",
        "target-groups.conditions.expressions.REGEX": "Expressão Regular",
        "target-groups.conditions.expressions.STARTS_WITH": "Começa com",
        "target-groups.conditions.expressions.ENDS_WITH": "Termina com",
        "target-groups.conditions.expressions.CONTAINS": "Contém",
        "target-groups.conditions.expressions.DOES_NOT_CONTAIN": "Não contém",        
        "target-groups.conditions.expressions.GREATER_THAN": "Maior que",
        "target-groups.conditions.expressions.GREATER_THAN_OR_EQUALS": "Maior que ou igual a",
        "target-groups.conditions.expressions.LESS_THAN": "Menor que",
        "target-groups.conditions.expressions.LESS_THAN_OR_EQUALS": "Menor que ou igual a",
        "target-groups.conditions.expressions.boolean.is.TRUE":  "É Verdadeiro",
        "target-groups.conditions.expressions.boolean.is.FALSE":  "É Falso",
        "target-groups.conditions.term.placeholder.STRING":  "Informe o valor. Ex.: foo",
        "target-groups.conditions.term.placeholder.STRING.in":  "Valores separados por vírgulas. Ex.: foo, bar",
        "target-groups.conditions.term.placeholder.NUMERIC":  "Informe o valor. Ex.: 53, 0.5 etc",
        "target-groups.conditions.term.placeholder.NUMERIC.in":  "Valores separados por vírgulas. Ex.: 43, 5, 7.45",
        "target-groups.conditions.term.placeholder.DATE":  "Formato AAAA-MM-DD. Ex.: 2019-01-01",
        "target-groups.conditions.term.placeholder.DATE.in":  "Valores separados por vírgulas. Ex.: 2005-04-01, 2007-04-01",
        "target-groups.conditions.term.placeholder.SEMANTIC_VERSION":  "Ex.: 1.0.3",
        "target-groups.conditions.term.placeholder.SEMANTIC_VERSION.in":  "Valores separados por vírgulas. Ex.: 1.1.2, 2.4.3, 3.0.0",
        "target-groups.conditions.add": "+ Adicionar nova condição",
        "target-groups.delete.confirm": "Você tem certeza que deseja excluir este público alvo?",
        "target-groups.delete.success": "Público alvo excluído com sucesso.",
        "target-groups.error.empty.name": "Nome não pode ser vazio ou branco!",
        "target-groups.error.empty.conditions": "Ao menos uma condição deve ser especificada!",
        "target-groups.error.name.already.in.use": "Já existe um público alvo com o mesmo nome!",
        "target-groups.error.not.found": "Público alvo não encontrado!",
        "target-groups.error.delete.used.in.experiments": "Não foi possível excluir. Público alvo está sendo utilizado em ao menos um experimento!",
        "toggles.title": "Toggles",
        "toggles.new": "Novo toggle",
        "toggles.header": "Toggle",
        "toggles.edit.description": "Aqui você pode registrar seus toggles (também conhecidos como \"feature flags\"). Apenas caracteres ASCII, algarismos, hífen, underscore e ponto final (.) são permitidos no nome.",
        "toggles.edit.values.description.first": "Informe os possíveis valores deste toggle.",
        "toggles.edit.values.description.second": "Você deve fornecer ao menos dois valores.",
        "toggles.name.label": "Nome",
        "toggles.name.placeholder": "Ex.: 'cta_color', 'send-greetings-email' etc",
        "toggles.description.label": "Descrição (opcional)",
        "toggles.description.placeholder": "Uma breve descrição deste toggle.",
        "toggles.values.header": "Valores",
        "toggles.values.label": "Informe o valor e tecle ENTER para adicioná-lo à lista.",
        "toggles.values.placeholder": "Ex.: true, false, on, off, v1, v2, v3 etc.",
        "toggles.default.value": "Valor padrão",
        "toggles.default.set.value": "Definir como padrão",
        "toggles.delete.confirm": "Você tem certeza que deseja excluir este toggle?",
        "toggles.delete.success": "Toggle excluído com sucesso.",
        "toggles.error.empty.name": "Nome não pode ser vazio ou branco!",
        "toggles.error.min.values": "Ao menos 2 (dois) valores devem ser fornecidos!",
        "toggles.error.name.already.in.use": "Já existe um toggle com o mesmo nome!",
        "toggles.error.invalid.name": "Apenas caracteres ASCII, algarismos, hífen, underscore e ponto final (.) são permitidos no nome.",
        "toggles.error.default.value.required": "Valor padrão não foi especificado!",
        "toggles.error.not.found": "Toggle não encontrado!",
        "toggles.error.delete.has.experiments": "Não foi possível excluir. Toggle possui ao menos um experimento cadastrado!",
        "toggles.error.delete.is.dependency": "Não foi possível excluir. Toggle é dependência em ao menos um experimento!",
        "toggles.error.max.limit.reached": "Você atingiu o limite máximo de toggles para esta conta!",
        "experiments.title": "Experimentos",
        "experiments.table.toggle.label": "Toggle",
        "experiments.table.status.label": "Status",
        "experiments.table.total-rules.label":"Regras",
        "experiments.new": "Novo experimento",
        "experiments.header": "Experimento",
        "experiments.edit.description": "Um experimento é o conjunto de regras utilizadas para se definir os valores de um toggle. Um toggle pode ter apenas um experimento por ambiente.",
        "experiments.clone.header": "Clonar",
        "experiments.clone.label": "Clonar este experimento para...",
        "experiments.clone.select.env": "Selecione um ambiente",
        "experiments.toggle": "Toggle",
        "experiments.toggle.select.placeholder": "Selecione um toggle",
        "experiments.rules.header": "Regras",
        "experiments.rules.number": "Regra #",
        "experiments.rules.version.filter": "Filtro de versão",
        "experiments.rules.version.filter.add": "Filtrar por versão",
        "experiments.rules.version.filter.remove": "Remover filtro de versão",
        "experiments.rules.all.users": "Todos usuários",
        "experiments.target-group.rule.ALL_OF": "todos",
        "experiments.target-group.rule.ANY_OF": "algum(s) dos",
        "experiments.target-group.rule.ONE_OF": "apenas um dos",
        "experiments.target-group.rule.NONE_OF": "nenhum dos",
        "experiments.then.result.is": "Resultado é",
        "experiments.then.split": "Split",
        "experiments.rules.add": "+ Adicionar nova regra",
        "experiments.status.ACTIVE": "Active",
        "experiments.status.PAUSED": "Paused",
        "experiments.delete.confirm": "Você tem certeza que deseja excluir este experimento?",
        "experiments.delete.success": "Experimento excluído com sucesso.",
        "experiments.error.no.toggle": "Um toggle deve ser selecionado!",
        "experiments.error.no.target.groups": "Nenhum público alvo foi especificado!",
        "experiments.error.invalid.split.rule": "A soma das porcentagens deve totalizar 100%!",
        "experiments.error.toggle.has.experiment": "Toggle já possui experimento neste ambiente!",
        "experiments.error.clone.destination.already.has.experiment": "Ambiente destino já possui um experimento para este toggle!",
        "experiments.error.circular.dependency": "Dependência circular detectada no toggle",
        "experiments.error.toggle.dependency.no.toggle": "Nenhum toggle selecionado!",
        "experiments.error.toggle.dependency.no.value": "Nenhum valor selecionado!",
        "experiments.error.clone.toggle.dependency.no.active.experiment": "Nenhum experimento ativo para um ou mais regras do tipo 'Toggle' no ambiente selecionado!",
        "experiments.error.duplicate.rule": "Regra(s) duplicada(s)!",
        "experiments.error.not.found": "Experimento não encontrado!",
        "property-rules.error.no.property": "Nenhuma propriedade selecionada!",
        "property-rules.error.no.expression": "Nenhuma expressão selecionada!",
        "property-rules.error.invalid.expression": "Expressão inválida!",
        "property-rules.error.empty.term": "Nenhum valor fornecido!",
        "property-rules.error.invalid.term.in": "Um dos valores fornecidos é inválido!",
        "property-rules.error.invalid.term.regex": "Expressão Regular inválida!",
        "property-rules.error.nan": "Valor fornecido não é um valor numérico!",
        "property-rules.error.nan.in": "Um dos valores fornecidos não é um valor numérico!",
        "property-rules.error.invalid.boolean": "Valor deve ser 'true' ou 'false'!",
        "property-rules.error.invalid.date.format": "Formato de data inválido! Utilize AAAA-MM-DD.",
        "property-rules.error.invalid.date.format.in": "Formato de data inválido dentre os valores fornecidos! Utilize AAAA-MM-DD.",
        "property-rules.error.invalid.date": "Data inválida!",
        "property-rules.error.invalid.date.in": "Data inválida dentre os valores fornecidos!",
        "property-rules.error.invalid.semver": "Versão semântica inválida. Utilize o formato MAJOR.MINOR.FIX",
        "property-rules.error.invalid.semver.in": "Versão semântica inválida dentre os valores fornecidos. Utilize o formato MAJOR.MINOR.FIX",
        "audit.action.CREATED": "Criado(a)",
        "audit.action.EDITED": "Editado(a)",
        "audit.action.DELETED": "Excluído(a)",
        "audit.action.PAUSED": "Pausado",
        "audit.action.RESTARTED": "Reiniciado",
        "users": "Usuários",
        "users.new": "Novo usuário",
        "users.name.label": "Nome",
        "users.name.placeholder": "Ex.: Maria da Silva",
        "users.email.label": "E-mail",
        "users.email.placeholder": "Ex.: maria.silva@exemplo.com",
        "users.status": "Status",
        "users.header": "Usuário",
        "users.basic.info": "Informações básicas do usuário (nome & e-mail). Ao cadastrar um usuário, o mesmo receberá um e-mail com instruções para definição de senha.",
        "users.basic.info.2": "Caso o usuário não seja um(a) administrador(a), você pode selecionar quais aplicações ele ou ela terá acesso.",
        "users.applications.explanation": "Aqui você pode selecionar as aplicações às quais este usuário terá acesso. Após selecionar as aplicações, clique no botão 'Salvar'.",
        "users.type": "Tipo",
        "users.admin": "Admin",
        "users.current.password": "Senha atual",
        "users.new.password": "Nova senha",
        "users.delete.confirm": "Você tem certeza que deseja excluir este(a) usuário(a)?",
        "users.delete.success": "Usuário(a) excluído(a) com sucesso.",
        "users.error.empty.name": "Nome não pode ser vazio ou branco!",
        "users.error.empty.email": "E-mail não pode ser vazio ou branco!",
        "users.error.invalid.email": "E-mail inválido!",
        "users.error.email.already.in.use": "Já existe um usuário cadastrado com este e-mail!",
        "users.error.current.password.mandatory": "Senha atual é obrigatória!",
        "users.error.new.password.mandatory": "Nova senha é obrigatória!",
        "users.error.max.limit.reached": "Você atingiu o limite máximo de usuários para esta conta!",
        "users.error.self.deletion.forbidden": "Você não pode excluir sua própria conta!",
        "passwords.error.password.mandatory": "Senha é obrigatória!",
        "passwords.error.password.confirmation.mandatory": "Confirmação de senha é obrigatória!",
        "passwords.error.invalid.password": "Senha inválida!",
        "passwords.error.passwords.dont.match": "Senha e confirmação de senha não são iguais!",
        "passwords.error.invalid.token": "Token inválido!",
        "api.explanation.header": "APIs Disponíveis"
    },
    "en": {
        "quick.toggle": "Quick Toggle",
        "general": "General",
        "general.ok": "Ok",
        "general.error": "Error",
        "general.error.message": "An unexpected error occurred. Please try again.",
        "general.logout": "Logout",
        "general.change.password": "Change password",
        "general.edit": "Edit",
        "general.save": "Save",
        "general.reset": "Reset",
        "general.settings": "Settings",
        "general.cancel": "Cancel",
        "general.mandatory.password": "Password is mandatory.",
        "general.wrong.password": "Wrong password!",
        "general.delete": "Delete",
        "general.more": "More...",
        "general.or": "Or",
        "general.and": "And",
        "general.if": "if",
        "general.else": "else",
        "general.then": "then",
        "general.select": "Select",
        "general.forbiden": "Forbiden",
        "general.forbiden.resource": "You cannot access this resource!",
        "general.audit": "Audit",
        "general.months.01": "January",
        "general.months.02": "February",
        "general.months.03": "March",
        "general.months.04": "April",
        "general.months.05": "May",
        "general.months.06": "June",
        "general.months.07": "July",
        "general.months.08": "August",
        "general.months.09": "September",
        "general.months.10": "October",
        "general.months.11": "November",
        "general.months.12": "December",
        "general.equal.to": "Equal to",
        "general.pause": "Pause",
        "general.restart": "Restart",
        "general.docs": "Documentation",
        "general.docs.url": "https://quicktoggle.com/docs/",
        "general.active": "Active",
        "general.inactive": "Inactive",
        "general.send": "Send",
        "general.close": "Close",
        "general.success": "Success",
        "general.page.not.found": "404 - Page not found!",
        "general.login": "Login",
        "general.thank.you": "Thank you",
        "general.limit": "Limit",
        "general.unlimited": "Unlimited",
        "general.add": "Add",
        "general.example": "E.g.",
        "general.language": "Language",
        "general.language.en": "English",
        "general.language.pt": "Portuguese",
        "general.generate.new.key": "Generate new key",
        "login.message": "Provide your e-mail & password",
        "login.password.message": "Password must be at least 6 digits long, with at least one number",
        "login.email": "E-mail",
        "login.password": "Password",
        "login.confirm.password": "Confirm password",
        "login.forgot.password": "Forgot password",
        "login.wrong.credentials": "Wrong e-mail and/or password",
        "forgot.password.text": "Account e-mail:",
        "forgot.password.back.to.login": "Back to login page",
        "forgot.password.unknown.user": "Unknown user",
        "forgot.password.done": "All good. We sent you an e-mail with instructions on how to reset your password!",
        "account.title": "Account",
        "account.keys.title": "Account keys",
        "account.keys.header": "Keys",
        "account.keys.new": "New key",
        "account.keys.key": "Key",
        "account.keys.delete.confirm": "Are you sure you want to delete this key? This operation cannot be undone!",
        "account.keys.delete.success": "Key successfully deleted.",
        "account.new.key.first.explanation": "We request you to provide your password in order to confirm your intention and identity.",
        "account.new.key.second.explanation": "Copy & store this key in a safe place. This is the only place you'll see it. In case you lose it, there's no way to recover it. If that happens, a new key will have to be generated.",
        "account.keys.error.not.found": "Key not found!",
        "account.keys.error.max.key.limit.reached": "You have reached your account's max keys limit.",
        "account-creation.create": "Create an account",
        "account-creation.name": "Your name",
        "account-creation.email": "E-mail",
        "account-creation.company": "Company",
        "account-creation.company.name": "Company's name",
        "account-creation.i.accept": "I read and accept the ",
        "account-creation.terms.and.conditions": "Terms and Conditions",
        "account-creation.already.have.account": "Already have an account?",
        "account-creation.dont.have.account": "Don't have an account?",
        "account-creation.error.missing.fields": "Please, fill all the fields",
        "account-creation.error.accept.tc": "You must accept our Terms and Conditions!",
        "account-creation.success.explanation": "We sent you a confirmation e-mail. Make sure to also check the Spam box in case you don't get any message from us.",
        "dashboard": "Dashboard",
        "applications.title": "Applications",
        "applications.new": "New application",
        "applications.header": "Application",
        "applications.environments.header": "Environment",
        "applications.name.label": "Name",
        "applications.name.placeholder": "Application's name",
        "applications.edit.description": "This is where you specify the details of your application. The only information required is the name of it.",
        "applications.environments.label": "Provide the environment name then hit ENTER to add it to the list.",
        "applications.environments.placeholder": "E.g. prod, dev, staging, RC, etc...",
        "applications.environments.description": "Environments are used to isolate experiments. That way, an experiment running in the 'dev' environment cannot affect other experiments running in 'production', for instance.",
        "applications.environments.name": "Name",
        "applications.environments.new.key": "New key",
        "applications.environments.new.key.description": "Password is required in order to generate a new key",
        "applications.environments.new.key.danger": "By generating a new key, the previous one will be invalidated. As this might not be desired, we request you to provide your password in order to confirm your intention and identity.",
        "applications.environments.new.key.explanation": "Copy & store this key in a safe place. This is the only place you'll see it. In case you lose it, there's no way to recover it. If that happens, a new key will have to be generated.",
        "applications.toggles": "Toggles",
        "applications.delete.confirm": "Are you sure you want to delete this application? This app's toggles, experiments, properties and target groups will also be deleted. This operation cannot be undone!",
        "applications.delete.success": "Application successfully deleted.",
        "applications.webhook.url": "Webhook URL",
        "applications.webhook.slack.explanation": "In this section you can specify the webhook of the Slack channel to where the audit logs of this application will be sent to.",
        "applications.error.empty.name": "Name cannot be empty nor blank!",
        "applications.error.no.environment": "At least one environment has to be provided!",
        "applications.error.name.already.in.use": "There's already an application with this name!",
        "applications.error.not.found": "Application not found!",
        "applications.error.max.app.limit.reached": "You have reached your account's max applications limit.",
        "applications.error.max.env.limit.reached": "You have reached your account's max environments limit.",
        "applications.error.invalid.webhook": "Invalid webhook URL!",
        "applications.error.invalid.webhook.lang": "Invalid webhook language!",
        "properties.title": "Properties",
        "properties.new": "New property",
        "properties.header": "Property",
        "properties.key.label": "Key",
        "properties.key.placeholder": "E.g. country_code, age, sex etc",
        "properties.description.label": "Description (optional)",
        "properties.description.placeholder": "Describe the meaning of this property",
        "properties.type.label": "Type",
        "properties.type.placeholder": "Select the type of this property",
        "properties.type.STRING": "String",
        "properties.type.NUMERIC": "Numeric",
        "properties.type.DATE": "Date (YYYY-MM-DD)",
        "properties.type.BOOLEAN": "Boolean (true/false)",
        "properties.type.SEMANTIC_VERSION": "Semantic Version",
        "properties.edit.description.first": "Properties are used to define the target groups.",
        "properties.edit.description.second": "The keys are sent in the requests to the system, and their values have to be of the type specified for the given property. IMPORTANT: the key 'uid' is reserved and cannot be used.",
        "properties.delete.confirm": "Are you sure you want to delete this property?",
        "properties.delete.success": "Property successfully deleted.",
        "properties.error.empty.key": "Define a key!",
        "properties.error.invalid.key": "Only ASCII characters, numbers, dashes, underscores and periods (.) are allowed in the key.",
        "properties.error.no.type": "Property type must be selected!",
        "properties.error.key.already.in.use": "There's already a property with this key!",
        "properties.error.not.found": "Property not found!",
        "properties.error.delete.used.in.experiments": "Property is being used in at least one experiment.",
        "properties.error.delete.used.in.target.groups": "Property is being used in at least one target group.",
        "properties.erros.uid.reserved": "Key 'uid' cannot be used!",
        "properties.select.placeholder": "Select a property...",
        "target-groups.title": "Target groups",
        "target-groups.properties.used": "Properties used",
        "target-groups.new": "New target group",
        "target-groups.header": "Target group",
        "target-groups.edit.description": "Target groups are used to segment your experiments. Feature toggles can be enabled/disabled according to which target group the users belong to.",
        "target-groups.edit.description.condition-policy": "You can define if, to be considered part of this target group, users must comply with at least one or with all of the conditions described.",
        "target-groups.edit.conditions.description": "Conditions are the rules used to determine if a given user belongs to a specific target group. You can combine multiple conditions, using either the 'or' logic ('at least one'), or the 'and' logic ('all conditions').",
        "target-groups.name.label": "Name",
        "target-groups.name.placeholder": "E.g. 'German speaking users', 'Returning users' etc",
        "target-groups.description.label": "Description (optional)",
        "target-groups.description.placeholder": "A short description of this target group.",
        "target-groups.conditions-policy.label": "Will be part of this target group if...",
        "target-groups.conditions-policy.at-least": "Satisfies at least one condition",
        "target-groups.conditions-policy.all": "Satisfies all conditions",
        "target-groups.conditions": "Conditions",
        "target-groups.conditions.select.expression": "Select an expression",
        "target-groups.conditions.expressions.IN": "In",
        "target-groups.conditions.expressions.EQUALS": "Equal",
        "target-groups.conditions.expressions.NOT_EQUALS": "Not equal",
        "target-groups.conditions.expressions.REGEX": "Regular Expression",
        "target-groups.conditions.expressions.STARTS_WITH": "Starts with",
        "target-groups.conditions.expressions.ENDS_WITH": "Ends with",
        "target-groups.conditions.expressions.CONTAINS": "Contains",
        "target-groups.conditions.expressions.DOES_NOT_CONTAIN": "Does not contain",
        "target-groups.conditions.expressions.GREATER_THAN": "Greater than",
        "target-groups.conditions.expressions.GREATER_THAN_OR_EQUALS": "Greater than or equal to",
        "target-groups.conditions.expressions.LESS_THAN": "Less than",
        "target-groups.conditions.expressions.LESS_THAN_OR_EQUALS": "Less than or equal to",
        "target-groups.conditions.expressions.boolean.is.TRUE":  "Is True",
        "target-groups.conditions.expressions.boolean.is.FALSE":  "Is False",
        "target-groups.conditions.term.placeholder.STRING":  "Provide a value. E.g. foo",
        "target-groups.conditions.term.placeholder.STRING.in":  "Comma separated values. E.g. foo, bar",
        "target-groups.conditions.term.placeholder.NUMERIC":  "Provide a value. E.g. 53, 0.5 etc",
        "target-groups.conditions.term.placeholder.NUMERIC.in":  "Comma separated values. E.g. 42, 3.14159, 6015",
        "target-groups.conditions.term.placeholder.DATE":  "YYYY-MM-DD format. E.g. 2019-01-01",
        "target-groups.conditions.term.placeholder.DATE.in":  "Comma separated values. E.g. 2014-05-23, 2017-05-23",
        "target-groups.conditions.term.placeholder.SEMANTIC_VERSION":  "E.g. 1.0.3",
        "target-groups.conditions.term.placeholder.SEMANTIC_VERSION.in":  "Comma separated values. E.g. 1.1.2, 2.4.3, 3.0.0",
        "target-groups.conditions.add": "+ Add new condition",
        "target-groups.delete.confirm": "Are you sure you want to delete this target group?",
        "target-groups.delete.success": "Target group successfully deleted.",
        "target-groups.error.empty.name": "Name cannot be empty nor blank!",
        "target-groups.error.empty.conditions": "At least one condition must be specified!",
        "target-groups.error.name.already.in.use": "There's already a target group with this name!",
        "target-groups.error.not.found": "Target group not found!",
        "target-groups.error.delete.used.in.experiments": "Cannot delete. Target group is being used in at least one experiment!",
        "toggles.title": "Toggles",
        "toggles.new": "New toggle",
        "toggles.header": "Toggle",
        "toggles.edit.description": "Here you can register your toggles (A.K.A. \"feature flags\"). Only ASCII characters, numbers, dash, underscore and period (.) are allowed in the name.",
        "toggles.edit.values.description.first": "Provide the possible values for that toggle.",
        "toggles.edit.values.description.second": "There has to be at least two possible values.",
        "toggles.name.label": "Name",
        "toggles.name.placeholder": "E.g. 'cta_color', 'send-greetings-email' etc",
        "toggles.description.label": "Description (optional)",
        "toggles.description.placeholder": "A short description of this feature toggle.",
        "toggles.values.header": "Values",
        "toggles.values.label": "Provide the value then hit ENTER to add it to the list.",
        "toggles.values.placeholder": "E.g. true, false, on, off, v1, v2, v3 etc.",
        "toggles.default.value": "Default value",
        "toggles.default.set.value": "Set as default",
        "toggles.delete.confirm": "Are you sure you want to delete this toggle?",
        "toggles.delete.success": "Toggle successfully deleted.",
        "toggles.error.empty.name": "Name cannot be empty nor blank!",
        "toggles.error.min.values": "At least 2 (two) values must be provided!",
        "toggles.error.name.already.in.use": "There's already a feature toggle with this name!",
        "toggles.error.invalid.name": "Only ASCII characters, numbers, dash, underscore and period (.) are allowed in the name.",
        "toggles.error.default.value.required": "Default value was not set!",
        "toggles.error.not.found": "Toggle not found!",
        "toggles.error.delete.has.experiments": "Cannot delete. Toggle has at least one experiment registered!",
        "toggles.error.delete.is.dependency": "Cannot delete. Toggle is a dependency of at least one experiment!",
        "toggles.error.max.limit.reached": "You have reached your account's max feature toggles limit.",
        "experiments.title": "Experiments",
        "experiments.table.toggle.label": "Toggle",
        "experiments.table.status.label": "Status",
        "experiments.table.total-rules.label":"Total rules",
        "experiments.new": "New experiment",
        "experiments.header": "Experiment",
        "experiments.edit.description": "An experiment is a set of rules used to define the values of a toggle. There can be only one experiment per toggle and environment.",
        "experiments.clone.header": "Clone",
        "experiments.clone.label": "Clone this experiment to...",
        "experiments.clone.select.env": "Select an environment",
        "experiments.toggle": "Toggle",
        "experiments.toggle.select.placeholder": "Select a toggle",
        "experiments.rules.header": "Rules",
        "experiments.rules.number": "Rule #",
        "experiments.rules.version.filter": "Version filter",
        "experiments.rules.version.filter.add": "Add version filter",
        "experiments.rules.version.filter.remove": "Remove version filter",
        "experiments.rules.all.users": "All users",
        "experiments.target-group.rule.ALL_OF": "is all of",
        "experiments.target-group.rule.ANY_OF": "is any of",
        "experiments.target-group.rule.ONE_OF": "is only one of",
        "experiments.target-group.rule.NONE_OF": "is none of",
        "experiments.then.result.is": "Result is",
        "experiments.then.split": "Split",
        "experiments.rules.add": "+ Add new rule",
        "experiments.status.ACTIVE": "Active",
        "experiments.status.PAUSED": "Paused",
        "experiments.delete.confirm": "Are you sure you want to delete this experiment?",
        "experiments.delete.success": "Experiment successfully deleted.",
        "experiments.error.no.toggle": "A toggle must be specified!",
        "experiments.error.no.target.groups": "No target group was specified!",
        "experiments.error.invalid.split.rule": "The sum of the % must be equal to 100%!",
        "experiments.error.toggle.has.experiment": "Toggle already has an experiment running in this environment!",
        "experiments.error.clone.destination.already.has.experiment": "Destination environment already has experiment for the same toggle!",
        "experiments.error.circular.dependency": "Circular dependency detected in toggle",
        "experiments.error.toggle.dependency.no.toggle": "No toggle selected!",
        "experiments.error.toggle.dependency.no.value": "No value selected!",
        "experiments.error.clone.toggle.dependency.no.active.experiment": "No active experiment in destination environment for one or more toggles dependencies!",
        "experiments.error.duplicate.rule": "Duplicate rule(s) detected!",
        "experiments.error.not.found": "Experiment not found!",
        "property-rules.error.no.property": "No property selected!",
        "property-rules.error.no.expression": "No expression selected!",
        "property-rules.error.invalid.expression": "Invalid expression!",
        "property-rules.error.empty.term": "Value not provided!",
        "property-rules.error.invalid.term.in": "One of the provided values is invalid!",
        "property-rules.error.invalid.term.regex": "Invalid Regular Expression!",
        "property-rules.error.nan": "Value is not a number!",
        "property-rules.error.nan.in": "One of the values is not a number!",
        "property-rules.error.invalid.boolean": "Value must be 'true' or 'false'!",
        "property-rules.error.invalid.date.format": "Invalid date format! Use YYYY-MM-DD.",
        "property-rules.error.invalid.date.format.in": "Invalid date format in provided values! Use YYYY-MM-DD.",
        "property-rules.error.invalid.date": "Invalid date!",
        "property-rules.error.invalid.date.in": "Invalid date in provided values!",
        "property-rules.error.invalid.semver": "Invalid semantic version. Use the format MAJOR.MINOR.FIX",
        "property-rules.error.invalid.semver.in": "Invalid semantic version in provided values. Use the format MAJOR.MINOR.FIX",
        "audit.action.CREATED": "Created",
        "audit.action.EDITED": "Edited",
        "audit.action.DELETED": "Deleted",
        "audit.action.PAUSED": "Paused",
        "audit.action.RESTARTED": "Restarted",
        "users": "Users",
        "users.new": "New user",
        "users.name.label": "Name",
        "users.name.placeholder": "Ex.: Jane Doe",
        "users.email.label": "E-mail",
        "users.email.placeholder": "E.g. jane.doe@example.com",
        "users.status": "Status",
        "users.header": "User",
        "users.basic.info": "User's basic info (name & e-mail). When registering a user, she/he will recieve an e-mail with the password setup instructions.",
        "users.basic.info.2": "If the user is not an admin, you can select which apps he or she will have access to.",
        "users.applications.explanation": "Here you can select the applications which this user will be granted access to. After selecting the applications, don't forget to click on the 'Save' button.",
        "users.type": "Type",
        "users.admin": "Admin",
        "users.current.password": "Current password",
        "users.new.password": "New password",
        "users.delete.confirm": "Are you sure you want to delete this user?",
        "users.delete.success": "User successfully deleted.",
        "users.error.empty.name": "Name cannot be empty nor blank!",
        "users.error.empty.email": "E-mail cannot be empty nor blank!",
        "users.error.invalid.email": "Invalid e-mail!",
        "users.error.email.already.in.use": "E-mail already in use!",
        "users.error.current.password.mandatory": "Current password is mandatory!",
        "users.error.new.password.mandatory": "New password is mandatory!",
        "users.error.max.limit.reached": "You have reached your account's max users limit.",
        "users.error.self.deletion.forbidden": "You cannot delete your own account!",
        "passwords.error.password.mandatory": "Password is mandatory!",
        "passwords.error.password.confirmation.mandatory": "Password confirmation is mandatory!",
        "passwords.error.invalid.password": "Invalid password!",
        "passwords.error.passwords.dont.match": "Password and password confirmation don't match!",
        "passwords.error.invalid.token": "Invalid token!",
        "api.explanation.header": "Available APIs"
    }
};

export default langs;
        