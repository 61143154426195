import React, { Component } from 'react'
import RuleTypeSelect from '../RuleTypeSelect';
import PropertiesSelect from '../../../../general/PropertiesSelect';
import i18n from '../../../../../i18n/Message';
import ExpressionsSelect from '../../../../general/ExpressionsSelect'

class PropertyRule extends Component {

    render() {

        const termInput = this.props.rule.propertyType !== "BOOLEAN" && this.props.rule.expression ?
            <input key={this.props.ruleIndex} type="text" value={this.props.rule.term} className="form-control" style={{width:'30%', margin:'2px'}} onChange={this.props.onChangeTerm}/> : ""

        return [
            <RuleTypeSelect key={`${this.props.ruleIndex}rts`} selectedRuleType="PROPERTY" onChange={this.props.onChangeRuleType} />,
            <PropertiesSelect 
                key={`${this.props.ruleIndex}ps`}
                uniqueId={this.props.ruleIndex} 
                selectedProperty={this.props.rule.propertyId} 
                onChange={this.props.onPropertySelect} 
                properties={this.props.properties}
                style={{width:'30%', margin:'2px'}}
                defaultOption={i18n("general.select")}/>,
            <ExpressionsSelect 
                key={`${this.props.ruleIndex}es`}
                booleanExpressionHandler={this.props.handleBooleanExpression} 
                uniqueId={this.props.ruleIndex} 
                selectedValue={this.isBooleanSelected() ? this.props.rule.term : this.props.rule.expression} 
                propertyType={this.props.rule.propertyType} 
                onChange={this.props.onExpressionSelect}
                style={{width:'15%', margin:'2px'}}
                defaultOption={i18n("general.select")}/>,               
            termInput
        ]

    }

    isBooleanSelected() {
        return this.props.rule.propertyType === "BOOLEAN" && this.props.rule.expression === "EQUALS"
    }

}

export default PropertyRule