import React, { Component } from 'react'
import i18n from '../../i18n/Message'
import expressions from '../general/Expressions'

class ExpressionsSelect extends Component {

    render() {
        if (this.props.propertyType) { 

            if (this.props.propertyType === "BOOLEAN") {
                var onChange = this.props.booleanExpressionHandler
                var options = ["true", "false"].map(val => {
                    return <option key={val} value={val}>{ i18n(`target-groups.conditions.expressions.boolean.is.${val.toUpperCase()}`) }</option>
                })
            } else {
                var onChange = this.props.onChange
                var options = expressions[this.props.propertyType].map(expression => {
                    return <option key={expression} value={expression}>{ i18n(`target-groups.conditions.expressions.${expression}`) }</option>
                })
            }
        }

        const defaultOption = this.props.defaultOption || i18n("target-groups.conditions.select.expression")
        const style = this.props.style || {}        

        const defaultValue = this.props.propertyType === "BOOLEAN" ? "" : ""

        return (
            <select value={this.props.selectedValue || ""} data-unique-id={this.props.uniqueId} name="select" id="expression" className="form-control" onChange={onChange} style={style}>
                <option value="">{ defaultOption }</option>
                {  options }
            </select>
        )
    }
}

export default ExpressionsSelect