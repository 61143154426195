import React, { Component } from 'react'


const Anchor = (props) => {
    return (
        <a {...props}>
            {props.children}
        </a>
    )
}

const Button = (props) => {
    return (
        <button {...props}>
            {props.children}
        </button>
    )
}

class RoundButton extends Component {

    render() {
        
        var style = this.props.style || {}
        if (!style.borderRadius)
            style["borderRadius"] = "25px"
        
        if (this.props.type === "anchor") {
            return <Anchor {...this.props} style={style} />
        } 
        return <Button {...this.props} style={style} />
    }

}

export default RoundButton