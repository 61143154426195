import React, { Component } from 'react'
import PropertiesSelect from '../../general/PropertiesSelect'
import i18n from '../../../i18n/Message'
import ExpressionsSelect from '../../general/ExpressionsSelect'

const TermInput = (props) => {
    if (props.expression && props.propertyType && props.propertyType !== "BOOLEAN") {
        const placeholder = props.expression !== "IN" ? 
            i18n(`target-groups.conditions.term.placeholder.${props.propertyType}`) :
            i18n(`target-groups.conditions.term.placeholder.${props.propertyType}.in`)
        return <input onChange={props.onTermChange} data-unique-id={props.uniqueId} type="text" id="description" placeholder={placeholder} className="form-control" value={props.term}/>
    } else {
        return ""
    }
}

class TargetGroupCondition extends Component {

    isBooleanSelected() {
        return this.props.condition.propertyType === "BOOLEAN" && this.props.condition.expression === "EQUALS"
    }

    render() {

        const invalidStyle = this.props.isInvalid ? {borderStyle: "solid", borderWidth: "1px", borderColor:"#f5c6cb"} : {}

        return (
            <div className="card-body card-block" style={invalidStyle}>
                <div className="form-group row">
                    <div className="col col-md-4">
                        <PropertiesSelect uniqueId={this.props.condition.id} selectedProperty={this.props.condition.propertyId} onChange={this.onPropertySelect.bind(this)} properties={this.props.properties}/>
                    </div>
                    <div className="col w-75">
                        <ExpressionsSelect 
                            booleanExpressionHandler={this.handleBooleanExpression.bind(this)} 
                            uniqueId={this.props.condition.id} 
                            selectedValue={this.isBooleanSelected() ? this.props.condition.term.trim() : this.props.condition.expression} 
                            propertyType={this.props.condition.propertyType} 
                            onChange={this.onExpressionSelect.bind(this)}/>
                    </div>
                </div>
                <TermInput 
                    expression={this.props.condition.expression}
                    term={this.props.condition.term} 
                    onTermChange={this.onTermChange.bind(this)} 
                    uniqueId={this.props.condition.id} 
                    propertyType={this.props.condition.propertyType} />
                
                <div className="nav-item float-right">
                    <a href="#" data-unique-id={this.props.condition.id} onClick={this.removeCondition.bind(this)}><i data-unique-id={this.props.condition.id} className="menu-icon fa fa-trash"></i></a>
                </div>
            </div>
        )
    }

    removeCondition(e) {
        e.preventDefault()
        const id = this.getId(e.target)
        this.props.removeCondition(id)
    }    

    getId(el) {
        return el.attributes["data-unique-id"].value
    }

    onPropertySelect(e) {
        const value = e.target.value
        if (value && value.length > 0) {

            const prop = this.props.properties.find(prop => prop.id === value)

            if (prop) {
                const id = this.getId(e.target)
                this.props.onPropertySelect(id, value, prop.type)
                this.props.onExpressionSelect(id, "")
                this.props.onTermChange(id, "")
                this.setState({
                    condition: {...this.props.condition, "propertyId": value, "propertyType": prop.type, "expression": "", "term": "" }
                })
            }

            
        } else {
            this.reset()
        }
    }

    handleBooleanExpression(e) {
        const value = e.target.value.toLowerCase() // 'true' or 'false'

        const expression = "EQUALS"
        const id = this.getId(e.target)
        
        if (value === "true" || value === "false") {    
            this.props.onExpressionSelect(id, expression)
            this.props.onTermChange(id, value)
            this.setState({
                condition: {...this.props.condition, "expression": "EQUALS", "term": value }
            })
        } else {
            this.props.onExpressionSelect(id, "")
            this.props.onTermChange(id, "")
            this.setState({
                condition: {...this.props.condition, "expression": "", "term": "" }
            })
        }
    }

    onExpressionSelect(e) {
        const value = e.target.value
        const id = this.getId(e.target)
        this.props.onExpressionSelect(id, value)
        this.setState({
            condition: {...this.props.condition, "expression": value }
        })
    }

    onTermChange(e) {
        const value = e.target.value
        const id = this.getId(e.target)
        this.props.onTermChange(id, value)
        this.setState({
            condition: {...this.props.condition, "term": value }
        })
    }        

    reset() {
        this.setState({
            condition: {
                "id": this.props.condition.id,
                "propertyId": "",
                "propertyType": "",
                "expression": "",
                "term": ""
            }
        })
    }

}

export default TargetGroupCondition