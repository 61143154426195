import React, { Component } from 'react'
import VersionRule from './VersionRule';

class VersionRuleHeader extends Component {

    render() {
        const versionRule = this.props.rule
        if (versionRule) {
            return (
                <div key={"version-header-" + this.props.ruleIndex} className="card-header row">
                    <VersionRule
                        key={`${this.props.ruleIndex}vr`}
                        rule={{...versionRule, propertyType: "SEMANTIC_VERSION"}}
                        onPropertySelect={(e) => this.props.onSelectVersionProperty(e)}
                        onExpressionSelect={(e) => this.props.onUpdateVersionExpression(e)}
                        onChangeTerm={(e) => this.props.onUpdateVersionTerm(e)}
                        versionProperties={this.props.versionProperties}
                        ruleIndex={this.props.ruleIndex}
                    />
                </div>
            ) 
        }
        return null

    }
}

export default VersionRuleHeader