import React, { Component } from 'react';
import i18n from '../../../i18n/Message'
import env from '../../../util/Env'
import user from '../../user/UserData'
import { Redirect, Link } from 'react-router-dom';
import httpClient from '../../../http/HttpClient';
import quicktoggleblack from '../../../assets/images/logomarca-black.png'
import LanguageSwitch from '../../general/LanguageSwitch';

class Password extends Component {

    state = { 
        password: {
            value: "",
            confirmation: ""
        },
        status: "" 
    };

    render() {

        if (user.isLoggedIn()) return <Redirect to="/dashboard" />

        let error;
        switch(this.state.status) {
            case "MANDATORY_PASSWORD":
                error = <p className="text-danger">{ i18n("passwords.error.password.mandatory") }</p>
                break
            case "MANDATORY_PASSWORD_CONFIRMATION":
                error = <p className="text-danger">{ i18n("passwords.error.password.confirmation.mandatory") }</p>
                break                
            case "INVALID_PASSWORD":
                error = <p className="text-danger">{ i18n("passwords.error.invalid.password") }</p>
                break
            case "PASSWORDS_DONT_MATCH":
                error = <p className="text-danger">{ i18n("passwords.error.passwords.dont.match") }</p>
                break
            case "INVALID_TOKEN":
                return (
                    <div className="sufee-login d-flex align-content-center flex-wrap h-100">
                        <div className="container">
                            <div className="login-content">
                                <div className="login-logo">
                                    <a href="https://quicktoggle.com">
                                        <img className="align-content" src={quicktoggleblack} alt=""/> 
                                    </a>
                                </div>
                                <div className="login-form">
                                    <p>{ i18n("passwords.error.invalid.token") }</p>
                                    <Link to="/login" className="btn btn-primary btn-flat m-b-30 m-t-30" style={{borderRadius:"25px"}}>Login</Link>
                                </div>
                            </div>
                        </div>
                    </div>  
                )
            case "ERROR":
                error = <p className="text-danger">{ i18n("general.error.message") }</p>
                break
            case "DONE": 
                return <Redirect to="/login" />
            default: 
                error = ""
                break
        }

        document.title = `Quick Toggle - ${i18n("login.password")}`
        return (
            <div className="sufee-login d-flex align-content-center flex-wrap h-100">
                <div className="container">
                    <div className="login-content">
                        <div className="login-logo">
                            <a href="https://quicktoggle.com">
                                <img className="align-content" src={quicktoggleblack} alt=""/> 
                            </a>
                        </div>
                        <div className="login-form">
                            
                            <p>{ i18n("login.password.message") }</p>
                            <div className="form-group">
                                <input className="form-control" onChange={(e) => this.changePassword.bind(this)(e, "value")} type="password" id="inputPassword" placeholder={ i18n("login.password") } name="password" autoFocus/>
                            </div>
                            <div className="form-group">
                                <input className="form-control" onChange={(e) => this.changePassword.bind(this)(e, "confirmation")} type="password" id="inputConfirmPassword" placeholder={ i18n("login.confirm.password") } name="confirm-password" />
                            </div>
                            { error }
                            <button onClick={this.save.bind(this)} className="btn btn-primary btn-flat m-b-30 m-t-30" style={{borderRadius:"25px"}}>{ i18n("general.save") }</button>
                            <br/>&nbsp;
                            <LanguageSwitch setLang={this.props.language}/>
                        </div>
                        
                    </div>
                </div>
            </div>
        );
    }

    changePassword(e, field) {
        e.preventDefault()
        const newPassword = this.state.password
        newPassword[field] = e.target.value
        this.setState({ password: newPassword })
    }

    save(e) {
        e.preventDefault()

        if (this.state.password.value) {
            if (this.state.password.confirmation) {
                if (this.state.password.value.length >= 6 &&
                    /(?=.*?[0-9])(?=.*?[A-Za-z]).+/.test(this.state.password.value)) {
                    if (this.state.password.value === this.state.password.confirmation) {
                        const self = this
                        httpClient.post({
                            url: `${env["api.url"]}/api/users/passwords/${this.props.requestId}`,
                            data: this.state.password
                        }, false).then(function(response) {
                            self.setState({ "status": "DONE" })
                        }).catch(function(error) {
                            if (error.response && error.response.data && error.response.data.error_code)  {
                                if (["EXPIRED_TOKEN", "INVALID_TOKEN"].includes(error.response.data.error_code)) {
                                    self.setState({ status: "INVALID_TOKEN" })
                                } else {
                                    self.setState({ status : error.response.data.error_code, errorMetadata: error.response.data.meta_data })
                                }

                            } else {
                              self.setState({ status : "ERROR" })
                            }
                        })
                    } else {
                        this.setState({ status: "PASSWORDS_DONT_MATCH" })
                    }
                } else {
                    this.setState({ status: "INVALID_PASSWORD" })
                }
            } else {
                this.setState({ status: "MANDATORY_PASSWORD_CONFIRMATION" })
            }
        } else {
            this.setState({ status: "MANDATORY_PASSWORD" })
        }

        
    }
}

export default Password;