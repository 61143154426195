import React, { Component } from 'react'
import user from '../user/UserData'

class OnlyAdmin extends Component {
    render() {
        if (user.isAdmin()) {
            return this.props.children
        } else return ""
    }
}

export default OnlyAdmin