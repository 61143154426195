import React, { Component } from 'react';
import BasePage from '../base/BasePage';
import i18n from '../../../i18n/Message';
import httpClient from '../../../http/HttpClient';
import env from '../../../util/Env'
import Error from '../base/Error'
import RoundButton from '../../ui/RoundButton';

class NewKey extends Component {

    state = { status: "", password: "", newKey: "", metaData: {} }

    render() {


        const Form = () => (
            <div className="form-group">
                <label htmlFor="password" className=" form-control-label">{ i18n("applications.environments.new.key.description") }</label>
                <input value={this.state.password} onChange={this.changePassword.bind(this)} type="password" id="password" placeholder={ i18n("login.password") } className="form-control" autoFocus/>
            </div>
        )

        const NewKey = () => (
            <div>
                { i18n("account.keys.new") }: <h1>{this.state.newKey}</h1>
            </div>
        )

        const DivContent = this.state.status === "SHOW_KEY" ? NewKey : Form
        const FooterAction = this.state.status === "SHOW_KEY" ? "" : <RoundButton onClick={this.generateNewKey.bind(this)} className="btn btn-primary btn-sm">{ i18n("general.generate.new.key") }</RoundButton>
     
        return (
            [
                <Error 
                status={this.state.status} 
                conf={ 
                    {
                        "MANDATORY_PASSWORD": i18n("general.mandatory.password"),
                        "WRONG_PASSWORD": i18n("general.wrong.password"),
                        "MAX_ACCOUNT_KEYS_REACHED": `${i18n("account.keys.error.max.key.limit.reached")} (${this.state.metaData["max-keys"]})`
                    } 
                }
                />,
                <div key="new-env-key" className="row">
                    <div className="col-lg-6">
                        <div className="card">
                            <div className="card-header"><strong className="card-title">{ i18n("general.generate.new.key") }</strong></div>
                            <div className="card-body card-block">
                                <DivContent />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 table-responsive">
                        <div className="card">
                            <div className="card-body card-block">
                                { this.state.status === "SHOW_KEY" ? i18n("account.new.key.second.explanation") : i18n("account.new.key.first.explanation") }
                            </div>
                        </div>
                    </div>

                </div>,
                FooterAction
            ]
        )
    }

    changePassword(e) {
        this.setState({ "password": e.target.value })
        if (this.state.password) {
            this.setState({ status: "" })
        } 
    }

    generateNewKey(e) {
        e.preventDefault()
        const password = this.state.password.trim()
        if (password.length > 0) {
            let self = this
            httpClient.post({
                url: `${env["api.url"]}/api/accounts/keys/new`,
                data: { "password": password }
            }).then(function (response) {
                self.setState({
                    newKey: response.data.key,
                    status: "SHOW_KEY",
                    password: ""
                })
            }).catch(function(error) {
                if (error.response && error.response.data && error.response.data.error_code)  {
                    self.setState({ status : error.response.data.error_code, metaData: error.response.data.meta_data || {} })
                } else {
                  self.setState({ status : "ERROR" })
                }
            })
        } else {
            this.setState({ status: "MANDATORY_PASSWORD" })
        }
    }

}


class NewAccountKey extends Component {

    render() {
        return ( 
            <BasePage         
                language={this.props.language} 
                logout={this.props.logout} 
                component={ <NewKey /> } 
                title={ i18n("account.keys.new") }
            />
        )
    }
}



export default NewAccountKey