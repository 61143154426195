import React, { Component } from 'react'
import BasePage from '../base/BasePage'
import i18n from '../../../i18n/Message'
import httpClient from '../../../http/HttpClient';
import env from '../../../util/Env';

class Dashboard extends Component {

    state = {
        limits: {},
        apiTags: []
    }

    componentDidMount() {
        this.getLimits()
        this.getApiConfig()
    }

    getLimits() {
        let self = this
        httpClient.get(`${env["api.url"]}/api/accounts/limits`)
        .then(function (response) {
            self.setState({ limits: response.data })
        }).catch(function(error) {
            if (error.response)  {
                self.setState({ status: error.response.status })
            }
        })
    }

    getApiConfig() {
        let self = this
        httpClient.get(`${env["api.url"]}/api/accounts/api-config`)
        .then(function (response) {
            self.setState({ apiTags: response.data.tags })
        }).catch(function(error) {
            if (error.response)  {
                self.setState({ status: error.response.status })
            }
        })
    }    

    getCurrentCountAndLimit(config) {
        const limit = this.state.limits[config]
        if (limit) {
            if (limit.type === "UNLIMITED") {
                return `${limit.currentCount}/${i18n("general.unlimited")}`
            }
            return `${limit.currentCount}/${limit.maxLimit}`
        }
    }

    render() {

        const apis = {
            "eu": "https://eu-api.quicktoggle.com/api/toggles?secret_key=<ENVIRONMENT_KEY>",
            "sa": "https://sa-api.quicktoggle.com/api/toggles?secret_key=<ENVIRONMENT_KEY>",
            "trial": "https://trial-api.quicktoggle.com/api/toggles?secret_key=<ENVIRONMENT_KEY>",
        }

        const apiItems = this.state.apiTags.map(tag => {
            return <li className="list-group-item"><strong>{ apis[tag] }</strong></li>
        })

        const apiSetup = apiItems.length > 0 ? <ul className="list-group">{ apiItems }</ul> : ""

        const content = [

            <div className="row">
                <div className="col-xl-4 col-lg-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="stat-widget-one">
                                <div className="stat-icon dib"><i className="fa fa-laptop text-success border-success"></i></div>
                                <div className="stat-content dib">
                                    <div className="stat-text">{ i18n("applications.title") }</div>
                                    <div className="stat-digit">{ this.getCurrentCountAndLimit("MAX_APPS") }</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-4 col-lg-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="stat-widget-one">
                                <div className="stat-icon dib"><i className="fa fa-toggle-on text-info border-info"></i></div>
                                <div className="stat-content dib">
                                <div className="stat-text">{ i18n("toggles.title") }</div>
                                    <div className="stat-digit">{ this.getCurrentCountAndLimit("MAX_FEATURE_TOGGLES") }</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-4 col-lg-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="stat-widget-one">
                                <div className="stat-icon dib"><i className="fa fa-users text-primary border-primary"></i></div>
                                <div className="stat-content dib">
                                <div className="stat-text">{ i18n("users") }</div>
                                    <div className="stat-digit">{ this.getCurrentCountAndLimit("MAX_USERS") }</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>,
            <div className="row">
                <div class="col-lg-9">
                    
                </div>
            </div>
        ]

        return (
            <BasePage language={this.props.language} logout={this.props.logout} component={ content } title={ i18n("dashboard") }/>
        )


    }
}

export default Dashboard