import React, { Component } from 'react';
import BasePage from '../base/BasePage';
import i18n from '../../../i18n/Message';
import httpClient from '../../../http/HttpClient';
import env from '../../../util/Env'
import { Redirect } from 'react-router-dom';
import Error from '../base/Error'
import FormActions from '../../general/FormActions';

class ChangePasswordForm  extends Component {

    state = { 
        changePassword: { currentPassword: "", newPassword: "", confirmation: "" }, 
        status: ""
    };

    render() {

        if (this.state.status === "DONE") return <Redirect to={`/dashboard`} />

        return [
            <Error 
                status={this.state.status} 
                conf={ 
                    {
                        "EMPTY_CURRENT_PASSWORD": i18n("users.error.current.password.mandatory"),
                        "EMPTY_NEW_PASSWORD": i18n("users.error.new.password.mandatory"),
                        "EMPTY_PASSWORD_CONFIRMATION": i18n("passwords.error.password.confirmation.mandatory"),
                        "INVALID_PASSWORD": i18n("passwords.error.invalid.password"),
                        "PASSWORDS_DONT_MATCH": i18n("passwords.error.passwords.dont.match"),
                        "WRONG_PASSWORD": i18n("general.wrong.password")
                    } 
                }
                />,
            <div key="23kdsf" className="row">
                <div className="col-lg-6">
                    <div className="card">
                        <div className="card-header"><strong>{ i18n("login.password") }</strong></div>
                        <div className="card-body card-block">
                            <div className="form-group">
                                <label htmlFor="current-password" className="form-control-label">{ i18n("users.current.password") }</label>
                                <input size="50" value={this.state.changePassword.currentPassword} onChange={this.updateCurrentPassword.bind(this)} type="password" id="current-password" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="new-password" className="form-control-label">{ i18n("users.new.password") }</label>
                                <input value={this.state.changePassword.newPassword} onChange={this.updateNewPassword.bind(this)} type="password" id="new-password" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="confirmation" className="form-control-label">{ i18n("login.confirm.password") }</label>
                                <input value={this.state.changePassword.confirmation} onChange={this.updatePasswordConfirmation.bind(this)} type="password" id="confirmation" className="form-control" />
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="card">
                        <div className="card-body card-block">
                            <p>{ i18n("login.password.message") }</p>
                        </div>
                    </div>
                </div>
            </div>,
            <FormActions onSave={this.save.bind(this)} onReset={this.reset.bind(this)}/>
        ]
    }

    reset() {
        this.setState({ status: "", changePassword: { currentPassword: "", newPassword: "", confirmation: "" } })
    }

    updateCurrentPassword(e) {
        this.updateValue("currentPassword", e.target.value)
    }

    updateNewPassword(e) {
        this.updateValue("newPassword", e.target.value)
    }

    updatePasswordConfirmation(e) {
        this.updateValue("confirmation", e.target.value)
    }    

    updateValue(field, value) {
        let change = this.state.changePassword
        change[field] = value
        this.setState({ changePassword: change })
        if (this.state.changePassword[field]) {
            this.setState({ "status": ""})
        }
    }

    save() {
        let self = this
        if (this.state.changePassword.currentPassword) {
            if (this.state.changePassword.newPassword) {
                if (this.state.changePassword.confirmation) {
                    if (/(?=.*?[0-9])(?=.*?[A-Za-z]).+/.test(this.state.changePassword.newPassword)) {
                        if (this.state.changePassword.newPassword === this.state.changePassword.confirmation) {
                            httpClient.post({
                                url: `${env["api.url"]}/api/users/change-password`,
                                data: this.state.changePassword
                            }).then(function(response) {
                                self.setState({ "status": "DONE" })
                            }).catch(function(error) {
                                if (error.response && error.response.data && error.response.data.error_code)  {
                                    self.setState({ status : error.response.data.error_code })
                                } else {
                                self.setState({ status : "ERROR" })
                                }
                            })
                        } else {
                            this.setState({ "status": "PASSWORDS_DONT_MATCH"})        
                        }
                    } else {
                        this.setState({ "status": "INVALID_PASSWORD"})    
                    }
                } else {
                    this.setState({ "status": "EMPTY_PASSWORD_CONFIRMATION"})
                }
            } else {
                this.setState({ "status": "EMPTY_NEW_PASSWORD"})
            }
        } else {
            this.setState({ "status": "EMPTY_CURRENT_PASSWORD"})
        }
    }


}

class ChangePassword extends Component {

    render() {
        return ( 
            <BasePage language={this.props.language} logout={this.props.logout} component={ <ChangePasswordForm /> } title={ i18n("users.new.password") }/>
        )
    }
}



export default ChangePassword