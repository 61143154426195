import React from 'react'
import i18n from '../../i18n/Message'
import RoundButton from '../ui/RoundButton';

const FormActions = (props) => {
    return (
        <div key={props.key} className="form-actions form-group">
            <RoundButton onClick={props.onSave} type="submit" className="btn btn-primary btn-sm"><i className="fa fa-save"></i>&nbsp;{ i18n("general.save") }</RoundButton>&nbsp;
            <RoundButton onClick={props.onReset} type="reset" className="btn btn-secondary btn-sm"><i className="fa fa-undo"></i>&nbsp;{ i18n("general.reset") }</RoundButton>
        </div>
    )
}

export default FormActions