import React, { Component } from 'react';
import BasePage from '../base/BasePage';
import i18n from '../../../i18n/Message';
import httpClient from '../../../http/HttpClient';
import env from '../../../util/Env'
import RoundButton from '../../ui/RoundButton';
import ConfirmationModal from '../../general/ConfirmationModal';
import $ from 'jquery'
import InformationModal from '../../general/InformationModal';
import { Redirect } from 'react-router-dom';

const INFO_MODAL = "info-modal"

class TogglesTable extends Component {
    state = {
        status: "",
        infoTitle: "",
        infoMessage: ""
    }

    componentDidUpdate() {
        if (this.state.infoTitle && this.state.infoMessage && this.state.status === "SHOW_INFO") {
            $(`#${INFO_MODAL}`).modal()
            this.state.status = ""
        }
    }

    render() {

        const Toggles = this.props.toggles.map(toggle => {
            return (
                <tr key={toggle.id}>
                    <th scope="row">{toggle.name}</th>
                    <td className="hide-on-small-screen">{ toggle.description }</td>
                    <td className="hide-on-small-screen">{ toggle.values.map(val => { 
                        const defaultValue = toggle.defaultValue === val ? ` (${i18n("toggles.default.value")})` : ""
                        return <h4 key={`${toggle.id}${val}`} style={{margin:"2px"}}><span className="badge badge-secondary">{val}{defaultValue}</span></h4> } ) } </td>
                    <td>
                        <RoundButton data-toggle="modal" data-target={`#modal-${toggle.id}`} type="anchor" href="#" data-toggle-id={toggle.id} className="btn btn-danger btn-sm"><i className="fa fa-warning"></i>&nbsp;{ i18n("general.delete") }</RoundButton>&nbsp;
                        <ConfirmationModal id={`modal-${toggle.id}`} title={`${i18n("general.delete")} ${toggle.name}`} message={ i18n("toggles.delete.confirm") } label={ i18n("general.delete")}  onConfirm={(e) => this.delete.bind(this)(e, `modal-${toggle.id}`, toggle.id)} />
                    </td>
                </tr>
            )
        })

        return (
            [
                <InformationModal key={INFO_MODAL} id={INFO_MODAL} titleKey={this.state.infoTitle} messageKey={this.state.infoMessage}/>,
                <div key="table" className="row">
                    <div className="col-lg-12 table-responsive">
                        <div className="card">                        
                            <div className="card-body">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">{ i18n("toggles.name.label") }</th>
                                            <th className="hide-on-small-screen" scope="col">{ i18n("toggles.description.label") }</th>
                                            <th className="hide-on-small-screen" scope="col">{ i18n("toggles.values.header") }</th>
                                            <th scope="col">{ i18n("general.more") }</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { Toggles }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>,
                <RoundButton key="new-toggle" type="anchor" href={`/applications/${this.props.appId}/toggles/new`} className="btn btn-primary btn-sm"><i className="fa fa-plus"></i>&nbsp;{ i18n("toggles.new") } </RoundButton>
            ]
        )
    }

    delete(e, modalId, toggleId) {
        e.preventDefault()
        $(`#${modalId}`).modal("hide")

        let self = this
        httpClient.delete({
            url: `${env["api.url"]}/api/apps/${this.props.appId}/toggles/${toggleId}`
        }).then(function (response) {
            self.setState({ 
                status: "SHOW_INFO",
                infoTitle: "general.success",
                infoMessage: "toggles.delete.success"
            })
            self.props.reload()
        }).catch(function(error) {
            let errorTitle = "general.error"
            let errorMessage = "general.error.message"
            if (error.response && error.response.data && error.response.data.error_code)  {
                switch(error.response.data.error_code) {
                    case "EXPERIMENT_EXISTS":
                        errorMessage = "toggles.error.delete.has.experiments"
                        break
                    case "EXPERIMENT_DEPENDENCY_EXISTS":
                        errorMessage = "toggles.error.delete.is.dependency"
                        break
                    case "NOT_FOUND":
                        errorMessage = "toggles.error.not.found"
                        break
                }
            } 
            self.setState({ 
                status: "SHOW_INFO",
                infoTitle: errorTitle,
                infoMessage: errorMessage,
            })
            
        })
        
    }

}


class Toggles extends Component {

    state = { httpStatus: 0, toggles: [] }

    componentDidMount() {
        let self = this
        httpClient.get(`${env["api.url"]}/api/apps/${this.props.appId}/toggles`)
        .then(function (response) {
            self.setState({ 
                toggles : response.data
            })
        }).catch(function(error) {
            if (error.response)  {
                self.setState({ httpStatus: error.response.status })
            }
        })
    }

    render() {
        if (this.state.httpStatus === 404) return <Redirect to="/not-found"/>
        return ( 
            <BasePage 
                appId={this.props.appId}            
                language={this.props.language} 
                logout={this.props.logout} 
                component={ <TogglesTable appId={this.props.appId} toggles={this.state.toggles} reload={this.componentDidMount.bind(this)}/> } 
                title={ i18n("toggles.title") }
            />
        )
    }
}



export default Toggles