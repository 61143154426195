import React, { Component } from 'react';
import BasePage from '../base/BasePage';
import i18n from '../../../i18n/Message';
import httpClient from '../../../http/HttpClient';
import env from '../../../util/Env'
import { Redirect } from 'react-router-dom';
import Error from '../base/Error'
import TargetGroupCondition from './TargetGroupCondition'
import conditionsValidator from './ConditionsValidator'
import FormActions from '../../general/FormActions';

const Conditions = (props) => {
    
    const policyText = <h4><span style={{marginLeft:"15px"}} className="badge badge-secondary">{ i18n(`general.${props.policy.toLowerCase()}`) }</span></h4>

    if (props.conditions && props.conditions.length > 0) {
        const items = [].concat(props.conditions.map(condition => {
            const isInvalid = props.invalidCondition === condition.id
            return [<TargetGroupCondition isInvalid={isInvalid} onTermChange={props.onTermChange} onExpressionSelect={props.onExpressionSelect} onPropertySelect={props.onPropertySelect} key={condition.id} removeCondition={props.removeCondition} condition={condition} properties={props.properties}/>, policyText]
        }))
        items[items.length-1].pop()
        return items
    } else {
        return ""
    }

}

class TargetGroupForm  extends Component {

    state = { 
        appId: "",
        originalTargetGroup: {
            name: "",
            description: "",
            policy: "OR",
            conditions: []
        },
        targetGroup: {
            name: "",
            description: "",
            policy: "OR",
            conditions: []
        },
        properties: [], 
        status: "",
        httpStatus: 0 
    };

    componentDidMount() {

        const self = this

        // Get the properties
        httpClient.get(`${env["api.url"]}/api/apps/${this.props.appId}/properties`)
        .then(function(response) {
            const properties = response.data
          
            if (self.props.appId && self.props.appId.length > 0 && 
                self.props.targetGroupId && self.props.targetGroupId !== "new") {
                httpClient.get(`${env["api.url"]}/api/apps/${self.props.appId}/target-groups/${self.props.targetGroupId}`)
                .then(function (response) {
                    const conditions = response.data.conditions.map(condition => {
                        return {...condition, propertyType: self.findProperty(condition.propertyId, properties).type}    
                    })
                    self.setState({ 
                        originalTargetGroup: {...response.data, conditions: conditions},
                        targetGroup : { ...response.data, appId: self.props.appId, conditions: [].concat(conditions.map(c=>{return {...c}}))},
                        appId: self.props.appId,
                        properties: properties
                    })
                }).catch(function(error) {
                    if (error.response)  {
                        self.setState({ httpStatus: error.response.status })
                    }
                })
            } else {
                self.setState({ appId: self.props.appId, properties: properties })
            }
        }).catch(function(error) {
            if (error.response)  {
                self.setState({ httpStatus: error.response.status })
            }
        })
        
    }

    findProperty(id, properties) {
        return properties.find(p => p.id === id)
    }

    render() {

        if (this.state.httpStatus === 404) return <Redirect to="/not-found"/>
        if (this.state.status === "DONE") return <Redirect to={`/applications/${this.state.appId}/target-groups`} />
        
        return [
            <Error key="errrrrr"
                status={this.state.status} 
                conf={ 
                    {
                        "EMPTY_NAME": i18n("target-groups.error.empty.name"),
                        "EMPTY_CONDITIONS": i18n("target-groups.error.empty.conditions"),
                        "NO_PROPERTY": i18n("property-rules.error.no.property"),
                        "NO_EXPRESSION": i18n("property-rules.error.no.expression"),
                        "INVALID_EXPRESSION": i18n("property-rules.error.invalid.expression"),
                        "EMPTY_TERM": i18n("property-rules.error.empty.term"),
                        "INVALID_TERM_IN": i18n("property-rules.error.invalid.term.in"),
                        "INVALID_REGEX": i18n("property-rules.error.invalid.term.regex"),
                        "NOT_A_NUMBER": i18n("property-rules.error.nan"),
                        "NOT_A_NUMBER_IN": i18n("property-rules.error.nan.in"),
                        "INVALID_BOOLEAN_VALUE": i18n("property-rules.error.invalid.boolean"),
                        "INVALID_DATE_FORMAT": i18n("property-rules.error.invalid.date.format"),
                        "INVALID_DATE_FORMAT_IN": i18n("property-rules.error.invalid.date.format.in"),
                        "INVALID_DATE": i18n("property-rules.error.invalid.date"),
                        "INVALID_DATE_IN": i18n("property-rules.error.invalid.date.in"),
                        "INVALID_SEMVER": i18n("property-rules.error.invalid.semver"),
                        "INVALID_SEMVER_IN": i18n("property-rules.error.invalid.semver.in"),
                        "DUPLICATE_NAME": i18n("target-groups.error.name.already.in.use")
                    } 
                }
                />,
            <div key="target-group" className="row">
                <div className="col-lg-6">
                    <div className="card">
                        <div className="card-header"><strong>{ i18n("target-groups.header") }</strong></div>
                        <div className="card-body card-block">
                            <div className="form-group">
                                <label htmlFor="name" className="form-control-label">{ i18n("target-groups.name.label") }</label>
                                <input maxLength="50" value={this.state.targetGroup.name} onChange={this.updateName.bind(this)} type="text" id="name" placeholder={ i18n("target-groups.name.placeholder") } className="form-control" />
                            </div>                        
                            <div className="form-group">
                                <label htmlFor="description" className="form-control-label">{ i18n("target-groups.description.label") }</label>
                                <input maxLength="100" value={this.state.targetGroup.description} onChange={this.updateDescription.bind(this)} type="text" id="description" placeholder={ i18n("target-groups.description.placeholder") } className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="conditions-policy" className="form-control-label">{ i18n("target-groups.conditions-policy.label") }</label>
                                {/* TODO Check why this doesn't change when user clicks on "Reset"... */}
                                <select id="conditions-policy" value={this.state.targetGroup.policy} className="form-control" onChange={this.updatePolicy.bind(this)}>
                                    <option value="OR">{ i18n("target-groups.conditions-policy.at-least") }</option>
                                    <option value="AND">{ i18n("target-groups.conditions-policy.all") }</option>
                                </select>
                            </div>                            
                        </div>

                    </div>
                </div>

                <div key="fdesc" className="col-lg-6">
                    <div className="card">
                        <div className="card-body card-block">
                            <p>{ i18n("target-groups.edit.description") }</p>
                            <p>{ i18n("target-groups.edit.description.condition-policy") }</p>
                        </div>
                    </div>
                </div>
            </div>,
            <div key="conditions-ad" className="row">
                <div className="col-lg-6">
                    <div className="card-header"><strong>{ i18n("target-groups.conditions") }</strong></div>
                    <div className="card">

                        <Conditions 
                            invalidCondition={this.state.invalidCondition}
                            onTermChange={this.onTermChange.bind(this)}
                            onPropertySelect={this.onPropertySelect.bind(this)}
                            onExpressionSelect={this.onExpressionSelect.bind(this)}
                            removeCondition={this.removeCondition.bind(this)}
                            policy={this.state.targetGroup.policy} 
                            conditions={this.state.targetGroup.conditions} 
                            properties={this.state.properties} />
                        <div className="card-body card-block">
                            <a href="#" onClick={this.addCondition.bind(this)} >{ i18n("target-groups.conditions.add") }</a>
                        </div>
                    </div>
                </div>

                <div key="cond-desc" className="col-lg-6">
                    <div className="card">
                        <div className="card-body card-block">
                            <p>{ i18n("target-groups.edit.conditions.description") }</p>
                        </div>
                    </div>
                </div>
            </div>,
            <FormActions onSave={this.save.bind(this)} onReset={this.reset.bind(this)}/>
        ]
    }

    removeCondition(id) {
        const conditions = this.state.targetGroup.conditions
        this.doIfConditionFound(id, (index) => {
            conditions.splice(index, 1)
            this.setState({invalidCondition: "", status: "", targetGroup: {...this.state.targetGroup, conditions: conditions}})
        })
    }  

    doIfConditionFound(id, block) {
        const index = this.state.targetGroup.conditions.findIndex(condition => condition.id == id)
        if (index > -1) {
            block(index)
        }
    }

    onPropertySelect(id, propertyId, propertyType) {
        this.doIfConditionFound(id, (index) => {
            const conditions = this.state.targetGroup.conditions
            conditions[index].propertyId = propertyId.trim()
            conditions[index].propertyType = propertyType.trim()

            this.setState({invalidCondition: "", status: "", targetGroup: {...this.state.targetGroup, conditions: conditions}})
        })
    }

    onExpressionSelect(id, expression) {
        this.doIfConditionFound(id, (index) => {
            const conditions = this.state.targetGroup.conditions
            conditions[index].expression = expression.trim()

            this.setState({invalidCondition: "", status: "", targetGroup: {...this.state.targetGroup, conditions: conditions}})
        })
    }

    onTermChange(id, term) {
        this.doIfConditionFound(id, (index) => {
            const conditions = this.state.targetGroup.conditions
            conditions[index].term = term.trim()

            this.setState({invalidCondition: "", status: "", targetGroup: {...this.state.targetGroup, conditions: conditions}})
        })
    }    

    addCondition(e) {
        e.preventDefault()

        const conditions = this.state.targetGroup.conditions
        const id = new Date().getTime() 

        conditions.push({
            id: id,
            propertyId: "",
            expression: "",
            propertyType: "",
            term: ""
        })

        this.setState({status: "", targetGroup: {...this.state.targetGroup, conditions: conditions} })

    }

    reset() {
        const conditions = [].concat(this.state.originalTargetGroup.conditions.map(c => {return {...c}}))
        this.setState({
            invalidCondition: "", 
            status: "", 
            targetGroup: {...this.state.originalTargetGroup, conditions: conditions} 
        })
    }

    updateDescription(e) {
        this.updateValue("description", e.target.value)
    }

    updateName(e) {
        this.updateValue("name", e.target.value)
    }

    updatePolicy(e) {
        this.updateValue("policy", e.target.value)
    }    

    updateValue(field, value) {
        let targetGroup = this.state.targetGroup
        targetGroup[field] = value
        this.setState({ "targetGroup": targetGroup })
        if (this.state.targetGroup[field] && this.state.targetGroup[field].trim().length > 0) {
            this.setState({ "status": ""})
        }
    }

    save() {
        let self = this
        if (this.state.targetGroup.name && this.state.targetGroup.name.trim().length > 0) {
            if (this.state.targetGroup.conditions && this.state.targetGroup.conditions.length > 0) {
                const validationResult = conditionsValidator(this.state.targetGroup.conditions, this.state.properties)
                if (validationResult.result === "VALID") {
                    let action
                    if (this.state.targetGroup.id) { // Old target group -> just editing
                        action = httpClient.put({
                            url: `${env["api.url"]}/api/apps/${this.state.appId}/target-groups/${this.state.targetGroup.id}`,
                            data: {...this.state.targetGroup, appId: this.state.appId} 
                        })
                    } else { // New target group -> just save it 
                        action = httpClient.post({
                            url: `${env["api.url"]}/api/apps/${this.state.appId}/target-groups`,
                            data: {...this.state.targetGroup, appId: this.state.appId} 
                        })
                    }
                    action.then(function(response) {
                        self.setState({ "status": "DONE", "originalTargetGroup": response.data })
                    }).catch(function(error) {
                        if (error.response && error.response.data && error.response.data.error_code)  {
                            self.setState({ status : error.response.data.error_code })
                        } else {
                          self.setState({ status : "ERROR" })
                        }
                    })
                } else {
                    this.setState({"invalidCondition": validationResult.id, "status": validationResult.result})        
                }
            } else {
                this.setState({ "status": "EMPTY_CONDITIONS"})    
            }
        } else {
            this.setState({ "status": "EMPTY_NAME"})
        }
    }


}

class EditTargetGroup extends Component {

    render() {
        return ( 
            <BasePage appId={this.props.appId} language={this.props.language} logout={this.props.logout} component={ <TargetGroupForm targetGroupId={this.props.targetGroupId} appId={this.props.appId}/> } title={ i18n("target-groups.title") + " > "+ i18n("general.edit") }/>
        )
    }
}



export default EditTargetGroup