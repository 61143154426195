import React, { Component } from 'react';
import BasePage from '../base/BasePage';
import i18n from '../../../i18n/Message';
import httpClient from '../../../http/HttpClient';
import env from '../../../util/Env'
import RoundButton from '../../ui/RoundButton';
import $ from 'jquery'
import InformationModal from '../../general/InformationModal';
import ConfirmationModal from '../../general/ConfirmationModal';
import OnlyAdmin from '../../general/OnlyAdmin';
import { Redirect } from 'react-router-dom';
import Applications from '../../Applications';

const INFO_MODAL = "info-modal"

class EnvironmentsTable extends Component {

    state = {
        status: "",
        infoTitle: "",
        infoMessage: ""
    }

    componentDidUpdate() {
        if (this.state.infoTitle && this.state.infoMessage && this.state.status === "SHOW_INFO") {
            $(`#${INFO_MODAL}`).modal()
            this.state.status = ""
        }
    }

    render() {

        if (this.state.status === "DELETED") return <Redirect to={`/dashboard`} />

        const Envs = this.props.app.environments.map(env => {
            return (
                <tr>
                    <th scope="row">{env.name}</th>
                    <td><RoundButton type="anchor" href={`/applications/${this.props.app.id}/env/${env.id}/reset`} data-env-id={env.id} className="btn btn-primary btn-sm"><i className="fa fa-exclamation-circle"></i>&nbsp;{ i18n("general.generate.new.key") }</RoundButton></td>
                </tr>
            )
        })

        return (
            [
                <div key="table" className="row">
                    <div className="col-lg-12 table-responsive">
                        <div className="card">
                            <div className="card-header"><strong className="card-title">{ i18n("applications.environments.header") }</strong></div>
                        
                            <div className="card-body">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">{ i18n("applications.environments.name") }</th>
                                            <th scope="col">{ i18n("general.more") }</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { Envs }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>,
                <div key="actions" className="form-actions form-group">
                    <RoundButton type="anchor" href={"/applications/" + this.props.app.id} className="btn btn-primary btn-sm"><i className="fa fa-edit"></i>&nbsp;{ i18n("general.edit") }</RoundButton>&nbsp;
                    <OnlyAdmin>
                        <RoundButton data-toggle="modal" data-target={`#modal-${this.props.app.id}`} type="anchor" href="#" className="btn btn-danger btn-sm"><i className="fa fa-warning"></i>&nbsp;{ i18n("general.delete") }</RoundButton>&nbsp;
                        <ConfirmationModal id={`modal-${this.props.app.id}`} title={`${i18n("general.delete")} ${i18n("applications.header")} ${this.props.app.name}`} message={ i18n("applications.delete.confirm") } label={ i18n("general.delete")}  onConfirm={(e) => this.delete.bind(this)(e)} />
                        <InformationModal key={INFO_MODAL} id={INFO_MODAL} titleKey={this.state.infoTitle} messageKey={this.state.infoMessage} onClose={this.deleted.bind(this)}/>
                    </OnlyAdmin>
                </div>
            ]
        )
    }

    deleted() {
        this.setState({status: "DELETED"})
    }

    delete(e) {
        e.preventDefault()
        $(`#modal-${this.props.app.id}`).modal("hide")

        let self = this
        httpClient.delete({
            url: `${env["api.url"]}/api/apps/${this.props.app.id}`
        }).then(function (response) {
            self.setState({ 
                status: "SHOW_INFO",
                infoTitle: "general.success",
                infoMessage: "applications.delete.success"
            })
            Applications.clearApps()
        }).catch(function(error) {
            let errorTitle = "general.error"
            let errorMessage = "general.error.message"
            if (error.response && error.response.data && error.response.data.error_code === "NOT_FOUND")  {
                errorMessage = "applications.error.not.found"
            } 
            self.setState({ 
                status: "SHOW_INFO",
                infoTitle: errorTitle,
                infoMessage: errorMessage,
            })
            
        })
        
    }

}


class ApplicationConfig extends Component {

    state = { httpStatus: 0, app: { name: "", environments: [] } }

    componentDidMount() {
        let self = this
        httpClient.get(`${env["api.url"]}/api/apps/${this.props.appId}`)
        .then(function (response) {
            self.setState({ 
                app : response.data
            })
        }).catch(function(error) {
            if (error.response)  {
                self.setState({ httpStatus: error.response.status })
            }
        })
    }

    render() {

        if (this.state.httpStatus === 404) return <Redirect to="/not-found"/>

        return ( 
            <BasePage 
                appId={this.props.appId}
                language={this.props.language} 
                logout={this.props.logout} 
                component={ <EnvironmentsTable app={this.state.app} /> } 
                title={ i18n("general.settings") }
            />
        )
    }
}



export default ApplicationConfig