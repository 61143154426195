import React, { Component } from 'react'
import i18n from '../../../i18n/Message'
import user from '../../user/UserData'
import env from '../../../util/Env'
import Applications from '../../Applications';
import RoundButton from '../../ui/RoundButton';
import OnlyAdmin from '../../general/OnlyAdmin';
import OnlyOnPremAdmin from '../../general/OnlyOnPremAdmin';

class LeftPanel extends Component {

    constructor(props) {
        super(props)
        this.state = { apps : [] } 
    }

    componentDidMount() {
        let self = this
        Applications.getApps(function (apps) {
            self.setState({ apps : apps } )
        })
    }

    render() {
        const appItems = this.state.apps.map((app) =>{
            
            const envItems = app.environments.map((env) => {
                return <li key={env.name}><i className="fa fa-server"></i><a href={`/applications/${app.id}/environments/${env.id}/experiments`}>{ env.name }</a></li>      
            })

            const envsList = //envItems && envItems.length > 0 ?
                <ul className="sub-menu children dropdown-menu">
                    { envItems }
                    <li key={env.name + "toggles"}><i className="fa fa-toggle-on"></i><a href={`/applications/${app.id}/toggles`}>{i18n("applications.toggles")}</a></li>
                    <li key={env.name + "props"}><i className="fa fa-check"></i><a href={`/applications/${app.id}/properties`}>{i18n("properties.title")}</a></li>
                    <li key={env.name + "tg"}><i className="fa fa-bullseye"></i><a href={`/applications/${app.id}/target-groups`}>{i18n("target-groups.title")}</a></li>
                    <li key={env.name + "audit"}><i className="fa fa-eye"></i><a href={`/applications/${app.id}/audit-logs`}>{i18n("general.audit")}</a></li>
                    <li key={env.name + "edit"}><i className="fa fa-cog"></i><a href={`/applications/${app.id}/config`}>{i18n("general.settings")}</a></li>
                </ul>// : ""

            return <li key={app.id} className="menu-item-has-children dropdown">
                <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i className="menu-icon fa fa-laptop"></i>{ app.name }</a>
                { envsList }
            </li>

        })

        return (
            /* <!-- /#left-panel --> */
            <aside id="left-panel" className="left-panel">
                <nav className="navbar navbar-expand-sm navbar-default">
        
                    <div className="navbar-header">
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#main-menu" aria-controls="main-menu" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="fa fa-bars"></i>
                        </button>
                        <div className="navbar-brand"><p>{ user.company() }</p></div>
                    </div>
        
                    <div id="main-menu" className="main-menu collapse navbar-collapse w-100">
                        <ul className="nav navbar-nav w-100">
                            <li className="active">
                                <a href="/dashboard"> <i className="menu-icon fa fa-dashboard"></i>{ i18n("dashboard") } </a>
                            </li>
                            <h3 className="menu-title">{ i18n("applications.title") }</h3> { /* <!-- /.menu-title --> */ }
                            { appItems }
                            <OnlyAdmin>
                                <RoundButton type="anchor" href="/applications/new" className="btn btn-primary btn-sm"><i className="fa fa-plus"></i>&nbsp;{ i18n("applications.new") }</RoundButton>
                            </OnlyAdmin>
                            <h3 className="menu-title">{ i18n("general") }</h3> {  /*<!-- /.menu-title -->*/ }
                            <li className="menu-item-has-children dropdown">
                                <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i className="menu-icon fa fa-user"></i>{ user.name() }</a>
                                <ul className="sub-menu children dropdown-menu">
                                    <li key="logout"><i className="fa fa-sign-out"></i><a className="dropdown-item" href="#" onClick={this.props.logout}>{ i18n("general.logout") }</a></li>
                                    <li key="change-pw"><i className="fa fa-key"></i><a href="/change-password">{i18n("general.change.password")}</a></li>
                                </ul>
                            </li>
                            <OnlyAdmin>
                                <li key="menu-users">
                                    <a href="/users"> <i className="menu-icon fa fa-users"></i>{ i18n("users") }</a>
                                </li>
                                <li key="menu-general-audit">
                                    <a href="/audit-logs"> <i className="menu-icon fa fa-eye"></i>{ i18n("general.audit") }</a>
                                </li>
                                <OnlyOnPremAdmin>
                                    <li className="menu-item-has-children dropdown">
                                        <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i className="menu-icon fa fa-info-circle"></i>{ i18n("account.title") }</a>
                                        <ul className="sub-menu children dropdown-menu">
                                            <li key="menu-general-account-keys"><i className="fa fa-key"></i><a href="/account/keys">{ i18n("account.keys.title") }</a></li>
                                        </ul>
                                    </li>
                                </OnlyOnPremAdmin>
                            </OnlyAdmin>
                            <li key="menu-docs">
                                <a href={ i18n("general.docs.url") } target="blank"> <i className="menu-icon fa fa-book"></i>{ i18n("general.docs") }</a>
                            </li>
                        </ul>
                    </div>{ /* <!-- /.navbar-collapse --> */ }
                </nav>
            </aside> 
        /* <!-- /#left-panel --> */ 
        )

    }

}

export default LeftPanel