import React from 'react';
import i18n from '../../../../../i18n/Message';

const ToggleDependencySelect = (props) => {

    const Toggles = props.toggles.filter(t => { return t.id != props.experimentToggleId }).map(toggle => { 
        return <option key={toggle.id} value={toggle.id}>{toggle.name}</option> 
    })

    return (
        <select value={props.rule.toggleId} name="select" id="toggle" className="form-control" style={{width:'35%', margin:'2px'}} onChange={props.onChange}>
            <option value="">{ i18n("general.select") }</option>
            { Toggles }
        </select>
    )
}

export default ToggleDependencySelect