import React from 'react';
import i18n from '../../../../../i18n/Message';

const ToggleDependencyValues = (props) => {

    const Values = props.rule.toggleId ? props.toggles.filter(t => { return t.id == props.rule.toggleId })[0].values.map(value => { 
        return <option key={`${props.rule.toggleId}${value}`}  value={value}>{ `${i18n("general.equal.to")} '${value}'`}</option> 
    }) : []
    

    return (
        <select value={props.rule.value} name="select" id="toggle-values" className="form-control" style={{width:'35%', margin:'2px'}} onChange={props.onChange}>
            <option value="">{ i18n("general.select") }</option>
            { Values }
        </select>
    )
}

export default ToggleDependencyValues