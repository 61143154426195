import React, { Component } from 'react'
import i18n from '../../i18n/Message';
import RoundButton from '../ui/RoundButton';

class InformationModal extends Component {

    render() {
        
        return (
            <div key={this.props.id} className="modal" id={this.props.id} tabIndex="-1" role="dialog" aria-labelledby="smallmodalLabel" style={{display:"none"}} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="smallmodalLabel">{i18n(this.props.titleKey)}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label={ i18n("general.close") }>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>{ i18n(this.props.messageKey) }</p>
                        </div>
                        <div className="modal-footer">
                            <RoundButton onClick={this.props.onClose} type="button" className="btn btn-primary" data-dismiss="modal">{ i18n("general.close") }</RoundButton>
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}

export default InformationModal