import React, { Component } from 'react';
import BasePage from '../base/BasePage';
import i18n from '../../../i18n/Message';
import httpClient from '../../../http/HttpClient';
import env from '../../../util/Env'
import { Redirect } from 'react-router-dom';
import Error from '../base/Error'
import TogglesSelect from './TogglesSelect';
import TargetGroupRule from './rules/target-group/TargetGroupRule';
import PropertyRule from './rules/property/PropertyRule';
import ValueSpecification from './value/ValueSpecification';
import RuleTypeSelect from './rules/RuleTypeSelect';
import RuleValidator from './rules/RuleValidator'
import FormActions from '../../general/FormActions';
import Clone from './Clone';
import ToggleDependencyRule from './rules/toggle-dependency/ToggleDependencyRule';
import VersionRuleHeader from './rules/version/VersionRuleHeader';

const AddRule = props => {
    return (
        props.toggleId ?
            <div className="card-body card-block">
                <a href="#" onClick={props.addNewRule.bind(this)}>{ i18n("experiments.rules.add") }</a>
            </div> :  ""
    )
}

const RightPanel = props => {
    if (props.experimentId === "new") {
        return (
            <div className="card">
                <div className="card-body card-block">
                    { i18n("experiments.edit.description") }
                </div>
            </div>
        ) 
    } else {
        return <Clone appId={props.appId} currentEnvId={props.envId} onClone={props.onClone}/>
    }
}

class ExperimentForm  extends Component {

    state = { 
        originalExperiment: { 
            toggleId: "", 
            rules: []
        }, 
        experiment: { 
            toggleId: "", 
            rules: []
        }, 
        status: "", 
        httpStatus: 0,
        targetGroups: [],
        toggles: [],
        properties: [],
        versionProperties: [],
        availableToggles: []
    };

    componentDidMount() {

        let self = this
        httpClient.get(`${env["api.url"]}/api/apps/${this.props.appId}/envs/${this.props.envId}/experiments`)
        .then(function (experimentsResponse) {
            
            const usedToggleIds = experimentsResponse.data.map(exp => exp.toggleId)

            httpClient.get(`${env["api.url"]}/api/apps/${self.props.appId}/toggles`)
            .then(function (togglesResponse) {

                const toggles = togglesResponse.data
                const filteredToggles = toggles.filter(toggle => !usedToggleIds.includes(toggle.id) )

                if (self.props.appId && self.props.envId && 
                    self.props.experimentId && self.props.experimentId !== "new") {
                    httpClient.get(`${env["api.url"]}/api/apps/${self.props.appId}/envs/${self.props.envId}/experiments/${self.props.experimentId}`)
                    .then(function (response) {
                        const rules = self.copyRules(response.data.rules)
                        self.setState({ 
                            originalExperiment: response.data, 
                            experiment : { ...response.data, appId: self.props.appId, rules: rules },
                            toggles: toggles,
                            availableToggles: filteredToggles
                        })
                    }).catch(function(error) {
                        if (error.response)  {
                            self.setState({ httpStatus: error.response.status })
                        }
                    })
                } else {
                    self.setState({ 
                        experiment: { ...self.state.experiment, appId: self.props.appId }, 
                        toggles: toggles, 
                        availableToggles: filteredToggles
                    })
                }

            }).catch(function(error) {
                if (error.response)  {
                    self.setState({ httpStatus: error.response.status })
                }
            })
        }).catch(function(error) {
            if (error.response)  {
                self.setState({ httpStatus: error.response.status })
            }
        })

        httpClient.get(`${env["api.url"]}/api/apps/${this.props.appId}/target-groups`)
        .then(function (response) {
            self.setState({ targetGroups: response.data })
        }).catch(function(error) {
            if (error.response)  {
                self.setState({ httpStatus: error.response.status })
            }
        })

        httpClient.get(`${env["api.url"]}/api/apps/${this.props.appId}/properties`)
        .then(function (response) {
            const properties = response.data
            const versionProperties = properties.filter(prop => prop.type == "SEMANTIC_VERSION")
            self.setState({ properties: properties, versionProperties: versionProperties })
        }).catch(function(error) {
            if (error.response)  {
                self.setState({ httpStatus: error.response.status })
            }
        })
        
    }

    render() {

        if (this.state.httpStatus === 404) return <Redirect to="/not-found" />
        if (this.state.status === "CLONED") return <Redirect to={`/applications/${this.props.appId}/environments/${this.state.destinationEnvironment}/experiments`} />
        if (this.state.status === "DONE") return <Redirect to={`/applications/${this.props.appId}/environments/${this.props.envId}/experiments`} />

        const errors = []

        if (this.state.status === "INVALID_RULES") {

            this.state.ruleErrors.forEach(error => {
                const messageMap = {
                    "NO_TARGET_GROUP_SPECIFIED":  `${i18n("experiments.error.no.target.groups")} - ${i18n("experiments.rules.number")}${error.ruleIndex+1}`,
                    "INCOMPLETE_PROPERTY_RULE": `${i18n("experiments.error.incomplete.property.rule")} - ${i18n("experiments.rules.number")}${error.ruleIndex+1}`,
                    "WRONG_SPLIT_PERCENTAGES": `${i18n("experiments.error.invalid.split.rule")} - ${i18n("experiments.rules.number")}${error.ruleIndex+1}`,
                    "NO_PROPERTY": `${i18n("property-rules.error.no.property")} - ${i18n("experiments.rules.number")}${error.ruleIndex+1}`,
                    "NO_EXPRESSION": `${i18n("property-rules.error.no.expression")} - ${i18n("experiments.rules.number")}${error.ruleIndex+1}`,
                    "INVALID_EXPRESSION": `${i18n("property-rules.error.invalid.expression")} - ${i18n("experiments.rules.number")}${error.ruleIndex+1}`,
                    "EMPTY_TERM": `${i18n("property-rules.error.empty.term")} - ${i18n("experiments.rules.number")}${error.ruleIndex+1}`,
                    "INVALID_TERM_IN": `${i18n("property-rules.error.invalid.term.in")} - ${i18n("experiments.rules.number")}${error.ruleIndex+1}`,
                    "INVALID_REGEX": `${i18n("property-rules.error.invalid.term.regex")} - ${i18n("experiments.rules.number")}${error.ruleIndex+1}`,
                    "NOT_A_NUMBER": `${i18n("property-rules.error.nan")} - ${i18n("experiments.rules.number")}${error.ruleIndex+1}`,
                    "NOT_A_NUMBER_IN": `${i18n("property-rules.error.nan.in")} - ${i18n("experiments.rules.number")}${error.ruleIndex+1}`,
                    "INVALID_BOOLEAN_VALUE": `${i18n("property-rules.error.invalid.boolean")} - ${i18n("experiments.rules.number")}${error.ruleIndex+1}`,
                    "INVALID_DATE_FORMAT": `${i18n("property-rules.error.invalid.date.format")} - ${i18n("experiments.rules.number")}${error.ruleIndex+1}`,
                    "INVALID_DATE_FORMAT_IN": `${i18n("property-rules.error.invalid.date.format.in")} - ${i18n("experiments.rules.number")}${error.ruleIndex+1}`,
                    "INVALID_DATE": `${i18n("property-rules.error.invalid.date")} - ${i18n("experiments.rules.number")}${error.ruleIndex+1}`,
                    "INVALID_DATE_IN": `${i18n("property-rules.error.invalid.date.in")} - ${i18n("experiments.rules.number")}${error.ruleIndex+1}`,
                    "INVALID_SEMVER": `${i18n("property-rules.error.invalid.semver")} - ${i18n("experiments.rules.number")}${error.ruleIndex+1}`,
                    "INVALID_SEMVER_IN": `${i18n("property-rules.error.invalid.semver.in")} - ${i18n("experiments.rules.number")}${error.ruleIndex+1}`,
                    "VERSION_INVALID_SEMVER": `${i18n("experiments.rules.version.filter")}: ${i18n("property-rules.error.invalid.semver")} - ${i18n("experiments.rules.number")}${error.ruleIndex+1}`,
                    "VERSION_INVALID_SEMVER_IN": `${i18n("experiments.rules.version.filter")}: ${i18n("property-rules.error.invalid.semver.in")} - ${i18n("experiments.rules.number")}${error.ruleIndex+1}`,
                    "VERSION_NO_EXPRESSION": `${i18n("experiments.rules.version.filter")}: ${i18n("property-rules.error.no.expression")} - ${i18n("experiments.rules.number")}${error.ruleIndex+1}`,
                    "VERSION_EMPTY_TERM": `${i18n("experiments.rules.version.filter")}: ${i18n("property-rules.error.empty.term")} - ${i18n("experiments.rules.number")}${error.ruleIndex+1}`,
                    "DUPLICATE_NAME": `${i18n("target-groups.error.name.already.in.use")} - ${i18n("experiments.rules.number")}${error.ruleIndex+1}`,
                    "NO_TOGGLE_DEPENDENCY_SELECTED": `${i18n("experiments.error.toggle.dependency.no.toggle")} - ${i18n("experiments.rules.number")}${error.ruleIndex+1}`,
                    "NO_TOGGLE_DEPENDENCY_VALUE_SELECTED": `${i18n("experiments.error.toggle.dependency.no.value")} - ${i18n("experiments.rules.number")}${error.ruleIndex+1}`
                }
                return errors.push(<Error status={error.result} conf={ messageMap }/>)
            })

            this.state.status = ""
            delete this.state.ruleErrors
        } else {
            const circularDependencyMessage = () => {
                const toggle = this.state.toggles.find(t => this.state.errorMetadata.toggle_id == t.id)
                return `${i18n("experiments.error.circular.dependency")} ${toggle.name}` 
            }
            const duplicateRuleMessage = () => {
                const rules = this.state.errorMetadata.rule_order.map(order => `#${order+1}`).join(" ")
                return `${i18n("experiments.error.duplicate.rule")} ${rules}` 
            }
            errors.push(<Error key={"error"+errors.length} status={this.state.status}  conf={ { 
                    "NO_TOGGLE": i18n("experiments.error.no.toggle"),
                    "TOGGLE_HAS_EXPERIMENT": i18n("experiments.error.toggle.has.experiment"),
                    "UNKNOWN_ENVIRONMENT": i18n("experiments.error.clone.destination.already.has.experiment"),
                    "TOGGLE_DEPENDENCY_NON_ACTIVE_EXPERIMENT": i18n("experiments.error.clone.toggle.dependency.no.active.experiment"),
                    "CIRCULAR_TOGGLE_DEPENDENCY": circularDependencyMessage,
                    "DUPLICATED_RULE": duplicateRuleMessage
                } } />
            )
        }

        return [
            errors,
            <div key="23kdf" className="row">
                <div className="col-lg-6">
                    <div className="card">
                        <div className="card-header"><strong>{ i18n("experiments.header") }</strong></div>
                        <div className="card-body card-block">
                            <div className="form-group">
                                <label htmlFor="toggles" className=" form-control-label">{ i18n("experiments.toggle") }</label>
                                <TogglesSelect onChange={this.updateToggle.bind(this)} experiment={this.state.experiment} toggles={this.state.availableToggles} selectedToggle={this.state.experiment.toggleId} />
                            </div>                    
                        </div>
                    </div>
                </div>

                <div className="col-lg-6">
                    <RightPanel {...this.props} onClone={this.clone.bind(this)}/>
                </div>
            </div>,
            <div key="rules" className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header"><strong>{ i18n("experiments.rules.header") }</strong></div>
                        <AddRule toggleId={this.state.experiment.toggleId} addNewRule={this.addNewRule.bind(this)}/>
                        { this.assembleRules() }
                    </div>
                </div>

            </div>,
            <FormActions key="form-actions" onSave={this.save.bind(this)} onReset={this.reset.bind(this)}/>
        ]
    }

    clone(destinationEnvironment) {
        const self = this
        httpClient.post({url:`${env["api.url"]}/api/apps/${this.props.appId}/envs/${this.props.envId}/experiments/${this.props.experimentId}/to/${destinationEnvironment}`})
        .then(function (response) {
            self.setState({ status: "CLONED", destinationEnvironment: destinationEnvironment })
        }).catch(function(error) {
            if (error.response && error.response.data && error.response.data.error_code)  {
                self.setState({ status : error.response.data.error_code, errorMetadata: error.response.data.meta_data })
            } else {
              self.setState({ status : "ERROR" })
            }
        })
    }

    assembleRules() {

        const rules = []
        for (let i = 0; i < this.state.experiment.rules.length; i++ ) {
            const rule = this.state.experiment.rules[i]
            rule.order = i

            const position = this.state.experiment.rules.length === 1 ? "ONLY" :
                i === 0 ? "FIRST" : i === this.state.experiment.rules.length-2 ? "BEFORE_LAST" : 
                i === this.state.experiment.rules.length-1 ? "LAST" : "MIDDLE" 

            const self = this
            const Rule = ((type) => {

                switch(type) {
                    case "TARGET_GROUP":
                        return <TargetGroupRule 
                                    key={`${i}tr`}
                                    rule={rule.targetGroupRule}
                                    onChangeRuleType={(e) => self.changeRuleType.bind(this)(i, e)}
                                    onChangeTargetGroupPolicy={(e) => self.updatePolicy.bind(this)(i, e)}
                                    onSelectTargetGroup={(e) => self.addTargetGroup.bind(this)(i, e)}
                                    onRemoval={(a, b) => self.removeTargetGroup.bind(this)(a, b)}
                                    targetGroups={this.state.targetGroups}
                                    ruleIndex={i}
                                    />
                    case "PROPERTY":
                        const property = this.state.properties.find(p => p.id === rule.propertyRule.propertyId)
                        const propertyType = property ? property.type : ""
                        return <PropertyRule
                                    key={`${i}pr`}
                                    rule={{...rule.propertyRule, propertyType: propertyType}}
                                    onChangeRuleType={(e) => self.changeRuleType.bind(this)(i, e)}
                                    onPropertySelect={(e) => self.selectProperty.bind(this)(i, e)}
                                    onExpressionSelect={(e) => self.updateExpression.bind(this)(i, e)}
                                    handleBooleanExpression={(e) => self.updateBoolean.bind(this)(i, e)}
                                    onChangeTerm={(e) => self.updateTerm.bind(this)(i, e)}
                                    properties={this.state.properties}
                                    ruleIndex={i}
                                />
                    case "TOGGLE_DEPENDENCY": 
                        return <ToggleDependencyRule 
                                    key={`${i}tr`}
                                    rule={{...rule.toggleDependencyRule}}
                                    experimentToggleId={this.state.experiment.toggleId}
                                    onToggleSelect={(e) => self.selectToggleDependency.bind(this)(i, e)}
                                    onValueSelect={(e) => self.selectToggleDependencyValue.bind(this)(i, e)}
                                    onChangeRuleType={(e) => self.changeRuleType.bind(this)(i, e)}
                                    toggles={this.state.toggles}
                                    ruleIndex={i}
                                />
                    default: 
                        return <RuleTypeSelect ruleIndex={i} onChange={(e) => this.changeRuleType.bind(this)(i, e)} selectedRuleType="ALL_USERS"/>
                }
            })(rule.type)

            const toggle = this.state.toggles.find(t => t.id === this.state.experiment.toggleId)

            const AddOrRemoveVersionFilter = props => {
                if (this.state.versionProperties.length > 0 && position !== "LAST" && position !== "ONLY") {
                    const text = rule.versionRule ? i18n("experiments.rules.version.filter.remove") : i18n("experiments.rules.version.filter.add")
                    return (
                        <div>
                            <a href="#" onClick={props.onClick} className="float-right">{ text }</a>
                        </div>
                    )
                }
                return null
            }

            rules.push(
                <div key={"rule-block-" + i} className="card-body card-block">
                    <div className="form-group">
                        <span className="badge badge-secondary float-right">{`#${i+1}`}</span>
                        <div className="row" style={{margin:"15px"}}>
                            <div className="col-lg-1 ">
                                { position === "FIRST" ? <strong>{ i18n("general.if") }</strong> : ""}
                                { position === "ONLY" ? <strong>{ i18n("general.if") }</strong> : ""}
                                { position === "MIDDLE" || position === "BEFORE_LAST" ? <strong>{ i18n("general.else") }, { i18n("general.if") }</strong> : ""}
                                { position === "LAST" ? <strong>{ i18n("general.else") }</strong> : ""}
                            </div>
                            <div key={"rule-container-" + i} className="card col-lg-10" style={{marginBottom:'10px'}}>
                                <VersionRuleHeader 
                                    key={"version-rule" + i} 
                                    rule={rule.versionRule} 
                                    ruleIndex={i} 
                                    onSelectVersionProperty={(e) => this.selectVersionProperty.bind(this)(i, e)}
                                    onUpdateVersionExpression={(e) => this.updateVersionExpression.bind(this)(i, e)}
                                    onUpdateVersionTerm={(e) => this.updateVersionTerm.bind(this)(i, e)}
                                    versionProperties={this.state.versionProperties}
                                    />
                                <div className="card-header row">
                                    { Rule }
                                </div>
                                <div className="card-body card-block">
                                    <div className="form-group">
                                        <div className="row align-items-center">
                                            <strong style={{marginRight:"5px"}}>{ i18n("general.then") }</strong>
                                            <ValueSpecification 
                                                spec={rule.valueSpec} 
                                                toggle={toggle} 
                                                onValueChange={(e) => this.selectValue.bind(this)(i, e)} // For type EXACT_VALUE
                                                onPercentageChange={val => this.changePercentage(i, val.value, val.percentage)} // For type SPLIT
                                                onChangeSpecType={(e) => this.changeValueSpecType.bind(this)(i, e)}/>
                                        </div>
                                    </div>
                                </div>
                                <AddOrRemoveVersionFilter onClick={(e) => {this.addOrRemoveVersionRule.bind(this)(i, e)}} />
                            </div>
                            <div className="col-lg-1">
                                { position !== "ONLY" && position !== "LAST" ?  [<a href="#" data-order={i} onClick={this.removeRule.bind(this)}><i data-order={i} className="menu-icon fa fa-trash"></i></a>, <br/>] : ""}
                                { position === "FIRST" && this.state.experiment.rules.length > 2 ?  <a href="#" onClick={e => this.swapPosition.bind(this)(e, i, "DOWN")}><i className="menu-icon fa fa-arrow-down"></i></a> : ""}
                                { position === "MIDDLE" ? [<a href="#" onClick={e => this.swapPosition.bind(this)(e, i, "UP")}><i className="menu-icon fa fa-arrow-up"></i></a>, <br/>, <a href="#" onClick={e => this.swapPosition.bind(this)(e, i, "DOWN")}><i className="menu-icon fa fa-arrow-down"></i></a>] : ""}
                                { position === "BEFORE_LAST" ? <a href="#" onClick={e => this.swapPosition.bind(this)(e, i, "UP")}><i className="menu-icon fa fa-arrow-up"></i></a>: ""}
                            </div>
                        </div>
                    </div>
                </div>
            )


        }

        return rules
    }    

    removeRule(e) {
        e.preventDefault()
        const order = e.target.attributes["data-order"].value
        const rules = this.state.experiment.rules
        rules.splice(order, 1)
        this.setState({status: "", experiment: {...this.state.experiment, rules: rules}})
    }

    addOrRemoveVersionRule(index, e) {
        e.preventDefault()
        const rule = this.state.experiment.rules[index]

        if (rule.versionRule) {
            delete rule.versionRule
            this.setState({experiment: {...this.state.experiment, rules: this.state.experiment.rules}})
        } else {
            if (this.state.versionProperties.length > 0) {
                const versionProperty = this.state.versionProperties[0]
                rule.versionRule = { propertyId: versionProperty.id, expression: "", term: "" }
                this.setState({experiment: {...this.state.experiment, rules: this.state.experiment.rules}})
            }
        }
        
        
    }

    addNewRule(e) {
        e.preventDefault()
        
        const toggle = this.state.toggles.find(t => t.id === this.state.experiment.toggleId)

        // By default, adds "EXACT_VALUE" specification
        const rule = {
            order: 0,
            type: "PROPERTY",
            propertyRule: { propertyId: "", expression: "", term: "" },
            valueSpec: {
                type: "EXACT_VALUE",
                exactValue: toggle.defaultValue
            }
        }

        const rules = [rule].concat(this.state.experiment.rules)

        this.setState({experiment: {...this.state.experiment, rules: rules}})
    }

    changeRuleType(index, e) {
        e.preventDefault()
        const rule = this.state.experiment.rules[index]
        const type = e.target.value.trim()

        rule.type = type

        switch(type) {
            case "ALL_USERS":
                delete rule.targetGroupRule
                delete rule.propertyRule
                delete rule.toggleDependencyRule
                break
            case "TARGET_GROUP": 
                delete rule.propertyRule
                delete rule.toggleDependencyRule
                rule.targetGroupRule = { policy: "ANY_OF", targetGroupsIds: [] }
                break
            case "PROPERTY":
                delete rule.targetGroupRule
                delete rule.toggleDependencyRule
                rule.propertyRule = { propertyId: "", expression: "", term: "" }
                break
            case "TOGGLE_DEPENDENCY":
                delete rule.propertyRule
                delete rule.targetGroupRule
                rule.toggleDependencyRule = { toggleId: "", value: "" }
                break
        }

        this.setState({status: ""})
    }

    // TARGET GROUPS RULES METHODS

    updatePolicy(index, e) {
        e.preventDefault()
        this.updateTargetGroupRule(index, {"policy": e.target.value.trim()})
    }

    updateTargetGroupRule(index, values) {
        this.updateRuleProperty(index, "targetGroupRule", values)
    }

    addTargetGroup(index, e) {
        e.preventDefault()
        const targetGroupId = e.target.value.trim()
        if (targetGroupId) {
            if (!this.state.experiment.rules[index].targetGroupRule.targetGroupsIds.includes(targetGroupId)) {
                this.state.experiment.rules[index].targetGroupRule.targetGroupsIds.push(e.target.value.trim())
            }
            e.target.value = ""
        }
        this.setState({status: ""})
    }

    removeTargetGroup(index, targetGroupId) {
        if (targetGroupId) {
            const targetGroupsIds = this.state.experiment.rules[index].targetGroupRule.targetGroupsIds
            if (targetGroupsIds.includes(targetGroupId)) {

                const targetGroupIndex = targetGroupsIds.findIndex(id => id === targetGroupId)
                if (targetGroupIndex > -1) {
                    targetGroupsIds.splice(targetGroupIndex, 1)
                }
            }
        }
        this.setState({status: ""})
    }    

    // END OF TARGET GROUP RULES METHODS

    // TOGGLE DEPENDENCY RULES METHODS    
    selectToggleDependency(index, e) {
        e.preventDefault()
        const values = {"toggleId": e.target.value.trim()}
        if (!values.toggleId) values["value"] = ""
        this.updateToggleDependencyRule(index, values)
    }

    selectToggleDependencyValue(index, e) {
        e.preventDefault()
        this.updateToggleDependencyRule(index, {"value": e.target.value.trim()})
    }    

    updateToggleDependencyRule(index, values) {
        this.updateRuleProperty(index, "toggleDependencyRule", values)
    }

    // END OF TOGGLE DEPENDENCY RULES METHODS  

    // PROPERTY RULES METHODS

    selectProperty(index, e) {
        e.preventDefault()
        this.updatePropertyRule(index, {"propertyId": e.target.value.trim()})
    }

    updateExpression(index, e) {
        e.preventDefault()
        this.updatePropertyRule(index, {"expression": e.target.value.trim()})
    }

    updateTerm(index, e) {
        e.preventDefault()
        this.updatePropertyRule(index, {"term": e.target.value.trim()})
    }

    updateBoolean(index, e) {
        e.preventDefault()
        this.updatePropertyRule(index, { "expression": "EQUALS" ,"term": e.target.value.trim().toLowerCase()})
    }

    updatePropertyRule(index, values) {
        this.updateRuleProperty(index, "propertyRule", values)
    }

    // END OF PROPERTY RULES METHODS

    // VERSION RULES METHODS

    selectVersionProperty(index, e) {
        e.preventDefault()
        if (e.target.value.trim()) {
            this.updateVersionRule(index, {"propertyId": e.target.value.trim()})
        }
    }

    updateVersionExpression(index, e) {
        e.preventDefault()
        this.updateVersionRule(index, {"expression": e.target.value.trim()})
    }

    updateVersionTerm(index, e) {
        e.preventDefault()
        this.updateVersionRule(index, {"term": e.target.value.trim()})
    }

    updateVersionRule(index, values) {
        this.updateRuleProperty(index, "versionRule", values)
    }

    // END OF VERSION RULES METHODS    


    changeValueSpecType(index, e) {
        e.preventDefault()

        const type = e.target.value.trim()

        this.state.experiment.rules[index].valueSpec.type = type
        const toggle = this.state.toggles.find(t => t.id === this.state.experiment.toggleId)
        switch(type) {
            case "EXACT_VALUE":
                delete this.state.experiment.rules[index].valueSpec.split
                this.state.experiment.rules[index].valueSpec.exactValue = toggle.defaultValue
                break
            case "SPLIT":
                delete this.state.experiment.rules[index].valueSpec.exactValue
                this.state.experiment.rules[index].valueSpec.split = this.getDefaultSplit(toggle)
                break
        }
        this.setState({status: ""})
    }

    getDefaultSplit(toggle) {
        
        const minValue = Math.floor(100/toggle.values.length)
        let remainder = 100 % toggle.values.length

        const split = {}
        toggle.values.forEach(val => { 
            let finalValue = minValue
            if (remainder > 0) {
                finalValue++
                remainder--
            }
            split[val] = finalValue
        })

        return split

    }

    selectValue(index, e) {
        this.updateRuleProperty(index, "valueSpec", {exactValue: e.target.value.trim()})
    }

    changePercentage(index, value, percentage) {
        const rule = this.state.experiment.rules[index]
        if (rule.valueSpec && rule.valueSpec.type === "SPLIT") {
            rule.valueSpec.split[value] = parseInt(percentage, 10)
            this.setState({status: ""})
        }
    }

    swapPosition(e, index, direction) {
        e.preventDefault()
        const swap = (list, position, otherPosition) => {
            const temp = list[position]
            list[position] = list[otherPosition]
            list[otherPosition] = temp
            return list
        }

        let rules = this.state.experiment.rules
        switch(direction) {
            case "UP":
                if (index > 0 && rules.length > 1) {
                    rules = swap(rules, index, index-1)
                }
                break
            case "DOWN":
                if (index < rules.length - 1) {
                    rules = swap(rules, index, index+1)
                }
                break                
        }

        this.setState({experiment: {...this.state.experiment, rules: rules}})

    }

    updateRuleProperty(index, ruleField, values) {
        const rule = this.state.experiment.rules[index]

        if (rule[ruleField]) {
            for (let key in values) {
                rule[ruleField][key] = values[key]
            }
            this.setState({status: ""})
        }
    }    

    reset() {

        const originalExperiment = this.state.originalExperiment

        const rules = this.copyRules(originalExperiment.rules)

        this.setState({ 
            status: "", 
            experiment: {...originalExperiment, rules: rules} 
        })
    }

    copyRules(rules) {
        const copiedRules = rules.map(rule => { 
            const versionRule = rule.versionRule ? {...rule.versionRule} : undefined
            const propertyRule = rule.type === "PROPERTY" ? {...rule.propertyRule} : undefined
            const targetGroupRule = rule.type === "TARGET_GROUP" ? {...rule.targetGroupRule, targetGroupsIds: [].concat(rule.targetGroupRule.targetGroupsIds)} : undefined
            const toggleDependencyRule = rule.type === "TOGGLE_DEPENDENCY" ? {...rule.toggleDependencyRule} : undefined

            return {
                ...rule,
                versionRule: versionRule,
                propertyRule: propertyRule,
                targetGroupRule: targetGroupRule,
                toggleDependencyRule: toggleDependencyRule,
                valueSpec: {...rule.valueSpec, split: {...rule.valueSpec.split}}
            }
        })
        return copiedRules.sort((a, b) => a.order - b.order)
    }

    updateToggle(e) {
        const toggleId = e.target.value.trim()
        let rules = this.state.experiment.rules

        if (!toggleId) {
            rules = []
        } else if(rules.length === 0) {
            const toggle = this.state.toggles.find(t => t.id === toggleId)
            rules = [{ order: 0, type: "ALL_USERS", valueSpec: { type: "EXACT_VALUE", exactValue: toggle.defaultValue } }]
        }
        this.setState({ "status": "", "experiment": {...this.state.experiment, "toggleId": toggleId, "rules": rules } })

    }    

    save() {
        let self = this
        if (this.state.experiment.toggleId) {

            const validationResults = RuleValidator(this.state.experiment.rules, this.state.properties)

            if (validationResults.findIndex(vr => vr.result !== "VALID") === -1) {

                let action
                const experiment = {...this.state.experiment, appId: this.props.appId, envId: this.props.envId}
                if (this.state.experiment.id) { // Old experiment -> just editing
                    action = httpClient.put({
                        url: `${env["api.url"]}/api/apps/${this.props.appId}/envs/${this.props.envId}/experiments/${this.state.experiment.id}`,
                        data: experiment
                    })
                } else { // New experiment -> just save it 
                    action = httpClient.post({
                        url: `${env["api.url"]}/api/apps/${this.props.appId}/envs/${this.props.envId}/experiments`,
                        data: experiment
                    })
                }
                action.then(function(response) {
                    self.setState({ "status": "DONE", "originalExperiment": response.data })
                }).catch(function(error) {
                    if (error.response && error.response.data && error.response.data.error_code)  {
                        self.setState({ status : error.response.data.error_code, errorMetadata: error.response.data.meta_data })
                    } else {
                      self.setState({ status : "ERROR" })
                    }
                })
            } else {
                this.setState({ "status": "INVALID_RULES", "ruleErrors": validationResults.filter(vr => vr.result !== "VALID")})
            }
        } else {
            this.setState({ "status": "NO_TOGGLE"})
        }
    }


}

class EditExperiment extends Component {

    render() {
        return ( 
            <BasePage key="exp-edit" appId={this.props.appId} envId={this.props.envId} language={this.props.language} logout={this.props.logout} component={ <ExperimentForm key="exp-form" appId={this.props.appId} envId={this.props.envId} experimentId={this.props.experimentId}/> } title={ i18n("experiments.title") + " > "+ i18n("general.edit") }/>
        )
    }
}



export default EditExperiment