import React, { Component } from 'react';
import BasePage from '../base/BasePage';
import i18n from '../../../i18n/Message';
import httpClient from '../../../http/HttpClient';
import env from '../../../util/Env'
import DataTypeSelect from '../../general/DataTypeSelect'
import { Redirect } from 'react-router-dom';
import Error from '../base/Error'
import FormActions from '../../general/FormActions';

class PropertyForm  extends Component {

    state = { 
        appId: "",
        originalProperty: { appId: "", key: "", description: "", type: "" }, 
        property: { appId: "", key: "", description: "", type: "" }, 
        status: "", 
        httpStatus: 0
    };

    componentDidMount() {

        if (this.props.appId && this.props.appId.length > 0 && 
            this.props.propertyId && this.props.propertyId !== "new") {
            let self = this
            httpClient.get(`${env["api.url"]}/api/apps/${this.props.appId}/properties/${this.props.propertyId}`)
            .then(function (response) {
                self.setState({ 
                    originalProperty: response.data, 
                    property : { ...response.data, appId: self.props.appId },
                    appId: self.props.appId
                })
            }).catch(function(error) {
                if (error.response)  {
                    self.setState({ httpStatus: error.response.status })
                }
            })
        } else {
            this.setState({ appId: this.props.appId, property : { ...this.state.property, appId: this.props.appId }})
        }
        
    }

    render() {
        if (this.state.httpStatus === 404) return <Redirect to="/not-found"/>
        if (this.state.status === "DONE") return <Redirect to={`/applications/${this.state.appId}/properties`} />

        return [
            <Error 
                status={this.state.status} 
                conf={ 
                    {
                        "EMPTY_KEY": i18n("properties.error.empty.key"),
                        "INVALID_KEY": i18n("properties.error.invalid.key"),
                        "NO_TYPE": i18n("properties.error.no.type"),
                        "DUPLICATE_KEY": i18n("properties.error.key.already.in.use"),
                        "UID_FORBIDDEN": i18n("properties.erros.uid.reserved")
                    } 
                }
                />,
            <div key="23kdsf" className="row">
                <div className="col-lg-6">
                    <div className="card">
                        <div className="card-header"><strong>{ i18n("properties.header") }</strong></div>
                        <div className="card-body card-block">
                            <div className="form-group">
                                <label htmlFor="key" className=" form-control-label">{ i18n("properties.key.label") }</label>
                                <input size="50" value={this.state.property.key} onChange={this.updateKey.bind(this)} type="text" id="key" placeholder={ i18n("properties.key.placeholder") } className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="type" className=" form-control-label">{ i18n("properties.type.label") }</label>
                                <DataTypeSelect onChange={this.updateType.bind(this)} property={this.state.property}/>
                            </div>                            
                            <div className="form-group">
                                <label htmlFor="description" className=" form-control-label">{ i18n("properties.description.label") }</label>
                                <input value={this.state.property.description} onChange={this.updateDescription.bind(this)} type="text" id="description" placeholder={ i18n("properties.description.placeholder") } className="form-control" />
                            </div>
                        </div>

                    </div>
                </div>

                <div className="col-lg-6">
                    <div className="card">
                        <div className="card-body card-block">
                            <p>{ i18n("properties.edit.description.first") }</p>
                            <p>{ i18n("properties.edit.description.second") }</p>
                        </div>
                    </div>
                </div>
            </div>,
            <FormActions onSave={this.save.bind(this)} onReset={this.reset.bind(this)}/>
        ]
    }

    reset() {
        this.setState({ status: "", property: { ...this.state.originalProperty }})
    }

    updateType(e) {
        this.updateValue("type", e.target.value.trim())
    }

    updateDescription(e) {
        this.updateValue("description", e.target.value)
    }

    updateKey(e) {
        this.updateValue("key", e.target.value.trim())
    }

    updateValue(field, value) {
        let newProperty = this.state.property
        newProperty[field] = value
        this.setState({ "property": newProperty })
        if (this.state.property[field] && this.state.property[field].trim().length > 0) {
            this.setState({ "status": ""})
        }
    }

    save() {
        let self = this
        if (this.state.property.key) {
            if (/^([0-9a-zA-Z\.\-_]*)$/.test(this.state.property.key)) {
                if (this.state.property.type) {
                    let action
                    if (this.state.property.id) { // Old property -> just editing
                        action = httpClient.put({
                            url: `${env["api.url"]}/api/apps/${this.state.appId}/properties/${this.state.property.id}`,
                            data: this.state.property
                        })
                    } else { // New property -> just save it 
                        action = httpClient.post({
                            url: `${env["api.url"]}/api/apps/${this.state.appId}/properties`,
                            data: this.state.property
                        })
                    }
                    action.then(function(response) {
                        self.setState({ "status": "DONE", "originalProperty": response.data })
                    }).catch(function(error) {
                        if (error.response && error.response.data && error.response.data.error_code)  {
                            self.setState({ status : error.response.data.error_code })
                        } else {
                        self.setState({ status : "ERROR" })
                        }
                    })
                } else {
                    this.setState({ "status": "NO_TYPE"})
                }
            } else {
                this.setState({ "status": "INVALID_KEY"})
            }
        } else {
            this.setState({ "status": "EMPTY_KEY"})
        }
    }


}

class EditProperty extends Component {

    render() {
        return ( 
            <BasePage appId={this.props.appId} language={this.props.language} logout={this.props.logout} component={ <PropertyForm propertyId={this.props.propertyId} appId={this.props.appId}/> } title={ i18n("properties.title") + " > "+ i18n("general.edit") }/>
        )
    }
}



export default EditProperty