import React, { Component } from 'react'
import RuleTypeSelect from '../RuleTypeSelect';
import TargetGroupPolicySelect from './TargetGroupPolicySelect';
import TargetGroupsSelect from './TargetGroupsSelect';


const SelectedTargetGroup = (props) => {

    if (props.targetGroups && props.targetGroups.length > 0) {

        const selectedTargetGroups = props.selectedTargetGroups.map(id => props.targetGroups.find(tg => tg.id === id))

        var TargetGroups = selectedTargetGroups.map(targetGroup => <span key={targetGroup.id} className="badge badge-info" style={{margin:'2px'}}><h6>{targetGroup.name}<a href="#" data-target-group-id={targetGroup.id} onClick={props.onRemoval} style={{marginLeft:'5px'}}>x</a></h6></span>)

        
    } else {
        var TargetGroups = []
    }

    return (
        <div className="row" style={{padding: '5px', minWidth:'50%', margin:'2px'}}>
            { TargetGroups }
        </div>
    )
}

class TargetGroupRule extends Component {

    render() {

        return [
            <RuleTypeSelect key={`${this.props.ruleIndex}rts`} selectedRuleType="TARGET_GROUP" onChange={this.props.onChangeRuleType} />,
            <TargetGroupPolicySelect key={`${this.props.ruleIndex}tgps`} selectedPolicy={this.props.rule.policy} onChange={this.props.onChangeTargetGroupPolicy}/>,
            <TargetGroupsSelect key={`${this.props.ruleIndex}tgs`} targetGroups={this.props.targetGroups} selectedTargetGroups={this.props.rule.targetGroupsIds} onSelect={this.props.onSelectTargetGroup} />,
            <SelectedTargetGroup key={`${this.props.ruleIndex}stg`} targetGroups={this.props.targetGroups} selectedTargetGroups={this.props.rule.targetGroupsIds} onRemoval={this.remove.bind(this)}/>
        ]

    }

    remove(e) {
        e.preventDefault()
        const targetGroupId = e.target.attributes["data-target-group-id"].value
        this.props.onRemoval(this.props.ruleIndex, targetGroupId)
    }

}

export default TargetGroupRule