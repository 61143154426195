import React from 'react';
import i18n from '../../../i18n/Message';

const TogglesSelect = (props) => {

    if (props.experiment.id && props.experiment.toggleId && props.experiment.toggleId.length > 0) {

        return (
            <select disabled={true} name="select" id="toggles" className="form-control">
                <option value={props.experiment.toggleId}>{ props.experiment.toggleName }</option>
            </select>
        )
    } else {

        const options = props.toggles.map(toggle => {
            return <option key={toggle.id} value={toggle.id}>{ toggle.name }</option>
        })

        return (
            <select value={props.selectedToggle ? props.selectedProperty : ""} name="select" id="toggles" className="form-control" onChange={props.onChange}>
                <option value="">{ i18n("experiments.toggle.select.placeholder") }</option>
                {  options }
            </select>
        )
    }

}

export default TogglesSelect