import httpClient from "../http/HttpClient";
import env from '../util/Env'
import { Base64 } from 'js-base64'

const APPS_KEY = "_apps"
const FIVE_MINUTES = 3 * 60 * 1000

class Applications {

    constructor() {
        const self = this
        setInterval(() => {
            self.fetchApps()
            
        }, FIVE_MINUTES)
    }

    storeApps(apps) {
        this.clearApps()
        localStorage.setItem(APPS_KEY, Base64.encode(JSON.stringify(apps)))
    }

    fetchApps(block) {
        let self = this
        httpClient.get(`${env["api.url"]}/api/apps`)
        .then(function (response) {
            self.storeApps(response.data)
            if (block) block(response.data)
        })
    }

    getApps(block) {
        const content = localStorage.getItem(APPS_KEY)
        if (content) {
            let apps = JSON.parse(Base64.decode(content))
            block(apps)
        } else if (block) {
            this.fetchApps(block)
        }
    }

    getApp(id, block, notFound) {
        this.getApps(apps => {
            const app = apps.find(a => a.id === id)
            if (app) {
                block(app)
            } else {
                notFound()
            }
        })
    }

    clearApps() {
        localStorage.removeItem(APPS_KEY)
    }
}

export default new Applications()