import React, { Component } from 'react';
import BasePage from '../base/BasePage';
import i18n from '../../../i18n/Message';
import httpClient from '../../../http/HttpClient';
import env from '../../../util/Env'
import RoundButton from '../../ui/RoundButton';
import ConfirmationModal from '../../general/ConfirmationModal';
import InformationModal from '../../general/InformationModal';
import $ from 'jquery'
import loggedInUser from '../../user/UserData';

const INFO_MODAL = "info-modal"

class UsersTable extends Component {

    state = {
        status: "",
        infoTitle: "",
        infoMessage: ""
    }

    componentDidUpdate() {
        if (this.state.infoTitle && this.state.infoMessage && this.state.status === "SHOW_INFO") {
            $(`#${INFO_MODAL}`).modal()
            this.state.status = ""
        }
    }

    render() {

        const Users = this.props.users.map(user => {

            const type = user.admin ? i18n("users.admin") : i18n("users.header")
            const active = user.active ? i18n("general.active") : i18n("general.inactive")

            
            const deletionItems = !loggedInUser.isTheSame(user.id) ? 
                [
                    <RoundButton key={`btn-${user.id}`} data-toggle="modal" data-target={`#modal-${user.id}`} type="anchor" href="#" className="btn btn-danger btn-sm"><i className="fa fa-warning"></i>&nbsp;{ i18n("general.delete") }&nbsp;</RoundButton>,
                    <ConfirmationModal key={`modal-${user.id}`} id={`modal-${user.id}`} title={`${i18n("general.delete")} ${user.name}`} message={ i18n("users.delete.confirm") } label={ i18n("general.delete")}  onConfirm={(e) => this.delete.bind(this)(e, `modal-${user.id}`, user.id)} />
                ] : ""
            return (
                <tr key={user.id}>
                    <th scope="row"><a href={`/users/${user.id}`} className="edit-link">{ user.name }</a></th>
                    <td className="hide-on-small-screen">{user.email}</td>
                    <td className="hide-on-small-screen">{active}</td>
                    <td className="hide-on-small-screen">{type}</td>
                    <td>
                        { deletionItems }
                        {/* <RoundButton data-toggle="modal" data-target={`#modal-${user.id}`} type="anchor" href="#" className="btn btn-danger btn-sm"><i className="fa fa-warning"></i>&nbsp;{ i18n("general.delete") }&nbsp;</RoundButton>
                        <ConfirmationModal id={`modal-${user.id}`} title={`${i18n("general.delete")} ${user.name}`} message={ i18n("users.delete.confirm") } label={ i18n("general.delete")}  onConfirm={(e) => this.delete.bind(this)(e, `modal-${user.id}`, user.id)} /> */}
                    </td>
                    
                </tr>
            )
        })

        return (
            [
                <InformationModal key={INFO_MODAL} id={INFO_MODAL} titleKey={this.state.infoTitle} messageKey={this.state.infoMessage}/>,
                <div key="table" className="row">
                    <div className="col-lg-12 table-responsive">
                        <div className="card">                        
                            <div className="card-body">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">{ i18n("users.name.label") }</th>
                                            <th className="hide-on-small-screen" scope="col">{ i18n("users.email.label") }</th>
                                            <th className="hide-on-small-screen" scope="col">{ i18n("users.status") }</th>
                                            <th className="hide-on-small-screen" scope="col">{ i18n("users.type") }</th>
                                            <th scope="col">{ i18n("general.more") }</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { Users }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>,
                <RoundButton key="new-user" type="anchor" href={`/users/new`} className="btn btn-primary btn-sm"><i className="fa fa-plus"></i>&nbsp;{ i18n("users.new") } </RoundButton>
            ]
        )
    }

    delete(e, modalId, userId) {
        e.preventDefault()
        $(`#${modalId}`).modal("hide")

        let self = this
        httpClient.delete({
            url: `${env["api.url"]}/api/users/${userId}`
        }).then(function (response) {
            self.setState({ 
                status: "SHOW_INFO",
                infoTitle: "general.success",
                infoMessage: "users.delete.success"
            })
            self.props.reload()
        }).catch(function(error) {
            let errorTitle = "general.error"
            let errorMessage = "general.error.message"
            if (error.response && error.response.data && error.response.data.error_code)  {
                switch(error.response.data.error_code) {
                    case "SELF_DELETION_FORBIDDEN":
                        errorMessage = "users.error.self.deletion.forbidden"
                        break
                }
            } 
            self.setState({ 
                status: "SHOW_INFO",
                infoTitle: errorTitle,
                infoMessage: errorMessage,
            })
            
        })
        
    }    

}


class Users extends Component {

    state = { users: [] }

    componentDidMount() {
        let self = this
        httpClient.get(`${env["api.url"]}/api/users`)
        .then(function (response) {
            self.setState({ 
                users : response.data
            })
        })
    }

    render() {
        return ( 
            <BasePage 
                language={this.props.language} 
                logout={this.props.logout} 
                component={ <UsersTable users={this.state.users} reload={this.componentDidMount.bind(this)}/> } 
                title={ i18n("users") }
            />
        )
    }
}



export default Users