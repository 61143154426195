import React, { Component } from 'react'
import BasePage from './base/BasePage'
import i18n from '../../i18n/Message'

class NotFound extends Component {
    render() {

        const content = [

            <div className="col-xl-4 col-lg-6">

            </div>
        ]

        return (
            <BasePage language={this.props.language} logout={this.props.logout} component={ content } title={ i18n("general.page.not.found") }/>
        )


    }
}

export default NotFound