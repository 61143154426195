import React, { Component } from 'react';
import BasePage from '../base/BasePage';
import i18n from '../../../i18n/Message';
import httpClient from '../../../http/HttpClient';
import env from '../../../util/Env'
import { Redirect } from 'react-router-dom';
import Error from '../base/Error'
import FormActions from '../../general/FormActions';
import Switch from 'react-switch'
import user from '../../user/UserData';

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const Apps = (props) => {
    return props.apps.map(app => {
        const hasApp = props.currentApps.includes(app.id)
        return (
            <tr key={app.id}>
                <th scope="row">{app.name}</th>
                <td>
                    <Switch onColor="#287AF6" onChange={() => props.onSelect(app.id)} checked={hasApp} uncheckedIcon={false} checkedIcon={false}/>
                </td>
            </tr>
        )
    })
}

class UserForm  extends Component {

    state = { 
        originalUser: { id: "", name: "", email: "", apps: [], admin: false }, 
        user: { id: "", name: "", email: "", apps: [], admin: false }, 
        apps: [],
        status: "", 
        loaded: false
    };

    componentDidMount() {

        let self = this
        if (this.props.userId && this.props.userId !== "new") {
            httpClient.get(`${env["api.url"]}/api/users/${this.props.userId}`)
            .then(function (response) {
                self.setState({ 
                    originalUser: response.data, 
                    user : { ...response.data, apps: [].concat(response.data.apps) },
                    loaded: true
                })
            })
        } else {
            this.setState({loaded: true })
        }

        httpClient.get(`${env["api.url"]}/api/apps`).then(function (response) { self.setState({ apps: response.data }) })

    }

    appsSection() {
        if (this.state.loaded && !this.state.user.admin) {
            return (
                <div key="apps-section" className="row">
                    <div className="col-lg-6">
                        <div className="card">
                            <div className="card-header"><strong>{ i18n("applications.title") }</strong></div>
                            <div className="card-body">
                                <table className="table table-striped">
                                    <tbody>
                                        <Apps apps={this.state.apps} onSelect={this.selectApp.bind(this)} currentApps={this.state.user.apps}/>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        
                    </div>
                    <div className="col-lg-6">
                        <div className="card">
                            <div className="card-body card-block">
                                <p>{ i18n("users.applications.explanation") }</p>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } 
        return ""
    }

    render() {

        if (this.state.status === "DONE") return <Redirect to={`/users`} />

        return [
            <Error 
                status={this.state.status} 
                conf={ 
                    {
                        "EMPTY_NAME": i18n("users.error.empty.name"),
                        "EMPTY_EMAIL": i18n("users.error.empty.email"),
                        "INVALID_EMAIL": i18n("users.error.invalid.email"),
                        "EMAIL_ALREADY_IN_USE": i18n("users.error.email.already.in.use"),
                        "MAX_USERS_LIMIT_REACHED": i18n("users.error.max.limit.reached")
                    } 
                }
                />,
            <div key="23kdsf" className="row">
                <div className="col-lg-6">
                    <div className="card">
                        <div className="card-header"><strong>{ i18n("users.header") }</strong></div>
                        <div className="card-body card-block">
                            <div className="form-group">
                                <label htmlFor="name" className="form-control-label">{ i18n("users.name.label") }</label>
                                <input size="50" value={this.state.user.name} onChange={this.updateName.bind(this)} type="text" id="name" placeholder={ i18n("users.name.placeholder") } className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email" className="form-control-label">{ i18n("users.email.label") }</label>
                                <input readOnly={this.state.user.id} value={this.state.user.email} onChange={this.updateEmail.bind(this)} type="text" id="email" placeholder={ i18n("users.email.placeholder") } className="form-control" />
                            </div>
                            <div className="form-group">
                                <div className="row" style={{margin:"0px"}}>  
                                    {"Admin"}&nbsp;&nbsp;
                                    <Switch disabled={user.isTheSame(this.state.user.id)} onColor="#287AF6" onChange={this.setAdmin.bind(this)} checked={this.state.user.admin} uncheckedIcon={false} checkedIcon={false}/>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="card">
                        <div className="card-body card-block">
                            <p>{ i18n("users.basic.info") }</p>
                            <p>{ i18n("users.basic.info.2") }</p>
                        </div>
                    </div>
                </div>
            </div>,
            this.appsSection(),
            <FormActions onSave={this.save.bind(this)} onReset={this.reset.bind(this)}/>
        ]
    }

    setAdmin() {
        this.setState({user: {...this.state.user, admin: !this.state.user.admin }})
    }

    selectApp(appId) {

        const index = this.state.user.apps.indexOf(appId)
        const transformation = (appsList) => {
            (index >= 0) ? appsList.splice(index,1) : appsList.push(appId)
        }
        const newApps = [].concat(this.state.user.apps)
        transformation(newApps)
        this.setState({ user: {...this.state.user, apps: newApps } } ) 
    
    }

    reset() {
        this.setState({ status: "", user: { ...this.state.originalUser, apps: [].concat(this.state.originalUser.apps) }})
    }

    updateName(e) {
        this.updateValue("name", e.target.value)
    }

    updateEmail(e) {
        this.updateValue("email", e.target.value.trim())
    }

    updateValue(field, value) {
        let user = this.state.user
        user[field] = value
        this.setState({ "user": user })
        if (this.state.user[field] && this.state.user[field].trim().length > 0) {
            this.setState({ "status": ""})
        }
    }

    save() {
        let self = this
        if (this.state.user.name && this.state.user.name.trim().length > 0) {
            if (this.state.user.email) {
                if (EMAIL_REGEX.test(this.state.user.email)) {
                    let action
                    if (this.state.user.id) { // Old user -> just editing
                        action = httpClient.put({
                            url: `${env["api.url"]}/api/users/${this.state.user.id}`,
                            data: {...this.state.user, name: this.state.user.name.trim() }
                        })
                    } else { // New user -> just save it 
                        action = httpClient.post({
                            url: `${env["api.url"]}/api/users`,
                            data: {...this.state.user, name: this.state.user.name.trim() }
                        })
                    }
                    action.then(function(response) {
                        self.setState({ "status": "DONE", "originalProperty": response.data })
                    }).catch(function(error) {
                        if (error.response && error.response.data && error.response.data.error_code)  {
                            self.setState({ status : error.response.data.error_code })
                        } else {
                        self.setState({ status : "ERROR" })
                        }
                    })
                } else {
                    this.setState({ "status": "INVALID_EMAIL"})
                }
            } else {
                this.setState({ "status": "EMPTY_EMAIL"})
            }
        } else {
            this.setState({ "status": "EMPTY_NAME"})
        }
    }


}

class EditUser extends Component {

    render() {
        return ( 
            <BasePage language={this.props.language} logout={this.props.logout} component={ <UserForm userId={this.props.userId} /> } title={ i18n("users") + " > "+ i18n("general.edit") }/>
        )
    }
}



export default EditUser