import React, { Component } from 'react';
import i18n from '../../../i18n/Message'
import { Link } from 'react-router-dom';
import user from '../../user/UserData'
import { Redirect } from 'react-router-dom';
import httpClient from '../../../http/HttpClient';
import env from '../../../util/Env';
import quicktoggleblack from '../../../assets/images/logomarca-black.svg'
import LanguageSwitch from '../../general/LanguageSwitch';

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

class ForgotPassword extends Component {

    state = { status: "", email: "" };

    render() {

        if (user.isLoggedIn()) return <Redirect to="/dashboard" />
        if (this.state.status === "DONE") return this.done()

        let error;
        switch(this.state.status) {
            case "EMPTY_EMAIL": 
                error = <p className="text-danger">{ i18n("users.error.empty.email") }</p>
                break
            case "INVALID_EMAIL":
                error = <p className="text-danger">{ i18n("users.error.invalid.email") }</p>
                break            
            case "UNKNOWN_USER":
                error = <p className="text-danger">{ i18n("forgot.password.unknown.user") }</p>
                break
            default: 
                error = ""                
        }

        document.title = `Quick Toggle - ${i18n("login.forgot.password")}`
        return (

            <div className="sufee-login d-flex align-content-center flex-wrap h-100">
                <div className="container">
                    <div className="login-content">
                        <div className="login-logo">
                            <a href="https://quicktoggle.com">
                                <img style={{"width":"110px"}} className="align-content" src={quicktoggleblack} alt=""/> 
                            </a>
                        </div>
                        <div className="login-form">
                            <p>{ i18n("forgot.password.text") }</p>
                            <div className="form-group">
                                <input onChange={(e) => this.setState({email: e.target.value}) } value={this.state.email} className="form-control" type="text" id="inputEmail" placeholder={ i18n("login.email") } autoFocus/>
                            </div>
                            <div className="checkbox">
                                <label>
                                    <Link to="/login" >{ i18n("forgot.password.back.to.login") }</Link>
                                </label>
                            </div>                            
                            {error}
                            <button style={{borderRadius:"25px"}} onClick={this.resetPassword.bind(this)} className="btn btn-primary btn-flat m-b-30 m-t-30">{ i18n("general.send") }</button>
                            <br/>&nbsp;
                            <LanguageSwitch setLang={this.props.language}/>
                        </div>
                    </div>
                </div>
            </div>            
        );
    }

    done() {
        return (

            <div className="sufee-login d-flex align-content-center flex-wrap h-100">
                <div className="container">
                    <div className="login-content">
                        <div className="login-logo">
                            <a href="https://quicktoggle.com">
                                <img style={{"width":"110px"}} className="align-content" src={quicktoggleblack} alt=""/> 
                            </a>
                        </div>
                        <div className="login-form">
                            <p>{ i18n("forgot.password.done") }</p>
                            <Link className="btn btn-primary btn-flat m-b-30 m-t-30" to="/login" >{ i18n("forgot.password.back.to.login") }</Link>
                        </div>
                    </div>
                </div>
            </div>                        
        )
    }

    resetPassword(e) {
        let self = this
        if (this.state.email) {
            if (EMAIL_REGEX.test(this.state.email)) {
                httpClient.post({
                        url: `${env["api.url"]}/api/users/reset-password`,
                        data: { email: this.state.email }
                }, false).then(function(response) {
                    self.setState({ "status": "DONE" })
                }).catch(function(error) {
                    if (error.response && error.response.data && error.response.data.error_code)  {
                        self.setState({ status : error.response.data.error_code })
                    } else {
                        self.setState({ status : "ERROR" })
                    }
                })
            } else {
                this.setState({ "status": "INVALID_EMAIL"})
            }
        } else {
            this.setState({ "status": "EMPTY_EMAIL"})
        }
    }

    forgotPassword(e) {
        e.preventDefault()
        this.setState({"error": "UNKNOWN_USER"})
    }
}

export default ForgotPassword;