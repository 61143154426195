import React, { Component } from 'react';
import BasePage from '../base/BasePage';
import i18n from '../../../i18n/Message';
import httpClient from '../../../http/HttpClient';
import env from '../../../util/Env'
import RoundButton from '../../ui/RoundButton';
import ConfirmationModal from '../../general/ConfirmationModal';
import $ from 'jquery'
import InformationModal from '../../general/InformationModal';
import { Redirect } from 'react-router-dom';

const INFO_MODAL = "info-modal"

class ExperimentsTable extends Component {
    state = {
        status: "",
        infoTitle: "",
        infoMessage: ""
    }

    componentDidUpdate() {
        if (this.state.infoTitle && this.state.infoMessage && this.state.status === "SHOW_INFO") {
            $(`#${INFO_MODAL}`).modal()
            this.state.status = ""
        }
    }

    render() {

        const Experiments = this.props.experiments.map(experiment => {

            const Action = experiment.status === "ACTIVE"  ? 
                <RoundButton onClick={(e) => { this.changeStatus(e, experiment.id, "pause") }} type="anchor" href="#" className="btn btn-warning btn-sm"><i className="fa fa-pause"></i>&nbsp;{ i18n("general.pause") }</RoundButton> : 
                <RoundButton onClick={(e) => { this.changeStatus(e, experiment.id, "restart") }} type="anchor" href="#" className="btn btn-success btn-sm"><i className="fa fa-play"></i>&nbsp;{ i18n("general.restart") }</RoundButton>

            return (
                <tr>
                    <th scope="row"><a href={`/applications/${this.props.appId}/environments/${this.props.envId}/experiments/${experiment.id}`} className="edit-link">{ experiment.toggleName }</a></th>
                    <td className="hide-on-small-screen">{ i18n(`experiments.status.${experiment.status}`) }</td>
                    <td className="hide-on-small-screen">{ experiment.rules.length } </td>
                    <td>
                        {/* <RoundButton type="anchor"  className="btn btn-primary btn-sm"><i className="fa fa-edit"></i>&nbsp;{ i18n("general.edit") }</RoundButton>&nbsp; */}
                        { Action }&nbsp;
                        <RoundButton data-toggle="modal" data-target={`#modal-${experiment.id}`} type="anchor" href="#" className="btn btn-danger btn-sm"><i className="fa fa-warning"></i>&nbsp;{ i18n("general.delete") }</RoundButton>&nbsp;
                        <ConfirmationModal id={`modal-${experiment.id}`} title={`${i18n("general.delete")} ${i18n("experiments.header")} ${experiment.toggleName}`} message={ i18n("experiments.delete.confirm") } label={ i18n("general.delete")}  onConfirm={(e) => this.delete.bind(this)(e, `modal-${experiment.id}`, experiment.id)} />
                    </td>
                </tr>
            )
        })

        return (
            [
                <InformationModal key={INFO_MODAL} id={INFO_MODAL} titleKey={this.state.infoTitle} messageKey={this.state.infoMessage}/>,
                <div key="table" className="row">
                    <div className="col-lg-12 table-responsive">
                        <div className="card">                        
                            <div className="card-body">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">{ i18n("experiments.table.toggle.label") }</th>
                                            <th className="hide-on-small-screen" scope="col">{ i18n("experiments.table.status.label") }</th>
                                            <th className="hide-on-small-screen" scope="col">{ i18n("experiments.table.total-rules.label") }</th>
                                            <th scope="col">{ i18n("general.more") }</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { Experiments }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>,
                <RoundButton type="anchor" href={`/applications/${this.props.appId}/environments/${this.props.envId}/experiments/new`} className="btn btn-primary btn-sm"><i className="fa fa-plus"></i>&nbsp;{ i18n("experiments.new") } </RoundButton>
            ]
        )
    }

    delete(e, modalId, experimentId) {
        e.preventDefault()
        $(`#${modalId}`).modal("hide")

        let self = this
        httpClient.delete({
            url: `${env["api.url"]}/api/apps/${this.props.appId}/envs/${this.props.envId}/experiments/${experimentId}`
        }).then(function (response) {
            self.setState({ 
                status: "SHOW_INFO",
                infoTitle: "general.success",
                infoMessage: "experiments.delete.success"
            })
            self.props.reload()
        }).catch(function(error) {
            let errorTitle = "general.error"
            let errorMessage = "general.error.message"
            if (error.response && error.response.data && error.response.data.error_code === "NOT_FOUND")  {
                errorMessage = "experiments.error.not.found"
            } 
            self.setState({ 
                status: "SHOW_INFO",
                infoTitle: errorTitle,
                infoMessage: errorMessage,
            })
            
        })
        
    }

    changeStatus(e, expId, action) {
        e.preventDefault()

        let self = this
        httpClient.post({ url: `${env["api.url"]}/api/apps/${this.props.appId}/envs/${this.props.envId}/experiments/${expId}/${action}` })
        .then(function (response) {
            self.props.experiments.find(exp => exp.id === expId).status = response.data.status
            self.setState({ status : "" })
        })
    }

}


class Experiments extends Component {

    state = { httpStatus: 0, experiments: [] }

    componentDidMount() {
        let self = this
        httpClient.get(`${env["api.url"]}/api/apps/${this.props.appId}/envs/${this.props.envId}/experiments`)
        .then(function (response) {
            self.setState({ 
                experiments : response.data
            })
        }).catch(function(error) {
            if (error.response)  {
                self.setState({ httpStatus: error.response.status })
            }
        })
    }

    render() {
        if (this.state.httpStatus === 404) return <Redirect to="/not-found"/>
        return ( 
            <BasePage 
                appId={this.props.appId}  
                envId={this.props.envId}          
                language={this.props.language} 
                logout={this.props.logout} 
                component={ <ExperimentsTable appId={this.props.appId} envId={this.props.envId} experiments={this.state.experiments} reload={this.componentDidMount.bind(this)}/> } 
                title={ i18n("experiments.title") }
            />
        )
    }
}



export default Experiments